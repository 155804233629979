// This function, findValueInArrayObjectsByName, searches through an array of objects to
// find the first object that has a specific value for a specified key, and then returns
//  the value of another key in that found object.

// eslint-disable-next-line max-len
export const findValueInArrayObjectsByName = <Value, ArrayType, KeyType1 extends string, KeyType2 extends string>(
    targetValue: Value,
    array: ArrayType,
    targetKey: KeyType1,
    resultKey: KeyType2,
) => {
    let resultValue;

    if (Array.isArray(array)) {
        array.forEach((item) => {
            if (item[targetKey] === targetValue) {
                resultValue = item[resultKey];
            }
        });
    }

    return resultValue || null;
};
