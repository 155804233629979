import {
    FC,
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
    Dropdown,
    MenuProps,
} from 'antd';

import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import {
    userActions,
    useLogoutMutation,
} from 'entities/user';
import avatar from 'shared/assets/icons/avatar.svg';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Avatar } from 'shared/ui/avatar/Avatar';

import { getUserData } from '../../model/selectors/user.serlectors';

import cls from './UserHeaderInfo.module.scss';

interface IUserHeaderInfoProps {
    className?: string;
}

export const UserHeaderInfo: FC<IUserHeaderInfoProps> = (props) => {
    const { className } = props;

    const dispatch = useAppDispatch();

    const [logout] = useLogoutMutation();
    const userData = useSelector(getUserData);

    const onLogout = useCallback(async () => {
        await logout();
        dispatch(userActions.setAuth({ isAuth: false }));
    }, []);

    const items: MenuProps['items'] = useMemo(() => [
        {
            key: '1',
            label: (
                <Button type="link" icon={<SettingOutlined />}>
                    Настройки
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button className={cls.logout} onClick={onLogout} type="text" icon={<LogoutOutlined />} danger>
                    Выйти
                </Button>
            ),
        },
    ], [onLogout]);

    return (
        <Dropdown trigger={['click']} menu={{ items }}>
            <div className={classNames(cls.userHeaderInfo, {}, [className])}>
                <Avatar src={userData?.photo} Icon={avatar} alt="avatar" />

                <div className={cls.info}>
                    <span>
                        {`${userData?.firstName} ${userData?.lastName}`}
                    </span>
                    <span>{userData?.userName}</span>
                </div>
            </div>
        </Dropdown>
    );
};
