/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import {
    Fragment,
    ReactNode,
} from 'react';

import { Tooltip } from 'antd';

import { IVariable } from 'entities/channels';
import {
    parseHtmlStringToDomWithReplace,
    traverseDom,
} from 'shared/lib/utils/deserialize/deserialize';
import { IDeserializeSettings } from 'shared/lib/utils/deserialize/deserialize.types';
import { VariableTheme } from 'shared/ui/text/constants/variables';

import { findValueInArrayObjectsByName } from './findValueInArrayObjectsByName';

class RenderElements {
    node: HTMLElement;

    withSettings: IDeserializeSettings = {};

    variables: IVariable[] = [];

    key: string | undefined;

    constructor(
        node: HTMLElement,
        withSettings: IDeserializeSettings,
        variables: IVariable[],
        key?: string,
    ) {
        this.node = node;
        this.variables = variables;
        this.key = key;
        this.withSettings = withSettings;
    }

    public renderLink() {
        const href = this.node.getAttribute('href') || '';
        const content = traverseDom(this.node, { handleElementNode: handleElementNode(this.variables), handleTextNode });

        return (renderWithMarks(
            <a target="_blank" href={href} rel="noreferrer">{content}</a>,
            this.withSettings,
            this.key,
        ));
    }

    public renderBR() {
        return (<br key={`br-${this.key}`} />);
    }

    public renderXVariable() {
        const text = this.node.getAttribute('data-text');

        const title = findValueInArrayObjectsByName(text, this.variables, 'name', 'value');

        return (
            <Tooltip
                key={this.key}
                color={title ? VariableTheme.PRIMARY : VariableTheme.ERROR}
                title={title ?? 'Переменная не существует'}
            >
                <span style={{ color: title ? VariableTheme.PRIMARY : VariableTheme.ERROR }}>
                    {renderWithMarks(`[${text}]`, this.withSettings)}
                </span>
            </Tooltip>
        );
    }
}

// Function to render content with specific HTML tags based on provided settings
function renderWithMarks(
    content: ReactNode,
    withSettings: IDeserializeSettings = {},
    key?: string,
) {
    let newContent = content;

    // If the settings require, wrap the content with specific HTML tags
    if (withSettings.bold) newContent = <b>{newContent}</b>;
    if (withSettings.italic) newContent = <em>{newContent}</em>;
    if (withSettings.del) newContent = <del>{newContent}</del>;
    if (withSettings.u) newContent = <u>{newContent}</u>;
    if (withSettings.code) newContent = <code>{newContent}</code>;
    if (withSettings.pre) newContent = <pre>{newContent}</pre>;

    // Return the newContent wrapped in a Fragment with a key
    return <Fragment key={key ?? withSettings.key}>{newContent}</Fragment>;
}

// Function to handle text nodes during traversal
function handleTextNode(node: ChildNode, withSettings: IDeserializeSettings, key?: string) {
    return renderWithMarks(node.textContent, withSettings, key);
}

// Function to handle element nodes during traversal, replacing non-existent tags with proper components
function handleElementNode(variables: IVariable[]) {
    return (
        node: HTMLElement,
        withSettings: IDeserializeSettings,
        key?: string,
    ) => {
        const { tagName } = node;

        const renderEntities = new RenderElements(node, withSettings, variables, key);

        if (tagName === 'BR') {
            return renderEntities.renderBR();
        }

        if (tagName === 'A') {
            return renderEntities.renderLink();
        }

        const type = node.getAttribute('data-type');

        if (type === 'x-variable') {
            return renderEntities.renderXVariable();
        }

        return null;
    };
}

// - Function to traverse DOM tree, handling element and text nodes
// The function which traverses by all DOM-tree( based on our html string) and returns a new array of DOM nodes with
// replacement some tags to another (for example, <x-variable />(non-existent tag in the HTML) to <Tooltip /> component from antd)
export const deserializeHtmlDOM = (element: Node, variables: IVariable[]) => traverseDom(
    element,
    {
        handleElementNode: handleElementNode(variables),
        handleTextNode,
    },
    {},
);

// - Function to replace all newline characters (\n) with <br> tags, except those within <pre> tags
// Replace all \n to <br> (but code inside of tag pre don`t be replaced)
function replaceNewlines(html: string) {
    // Split HTML to fragments, using tag <pre> by delimiter
    const parts = html.split(/(<pre>[\s\S]*?<\/pre>)/g);

    // Replace \n to <br> in the all fragments, which don`t start with <pre>
    for (let i = 0; i < parts.length; i++) {
        if (!parts[i].startsWith('<pre>')) {
            parts[i] = parts[i].replace(/\n/g, '<br>');
        }
    }

    return parts.join('');
}

export const renderHTMLString = (html: string, variables: IVariable[]) => {
    const parsed = parseHtmlStringToDomWithReplace(replaceNewlines(html));
    const node = deserializeHtmlDOM(parsed.body, variables);
    return node;
};
