/* eslint-disable jsx-a11y/media-has-caption */
import {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import { messagesActions } from 'entities/message';
import { IArgsContentViewer } from 'features/content-viewer';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useBrowserInfo from 'shared/lib/hooks/useBrowserInfo/useBrowserInfo';
import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import useOpenVideoIPhone from 'shared/lib/hooks/useOpenVideoIPhone/useOpenVideoIPhone';
import { useThrottle } from 'shared/lib/hooks/useThrottle/useThrottle';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { consoleDev } from 'shared/lib/utils/consoleDev/consoleDev';
import { requestFullScreenAndroid } from 'shared/lib/utils/fullScreenVideo/fullScreenVideo';
import { ImageWithLoader } from 'shared/ui/image-loader/ImageWithLoader';
import { OverlayFileLoading } from 'shared/ui/overlay-file-loading/OverlayFileLoading';
import { VideoWithLoader } from 'shared/ui/video-loader/VideoWithLoader';

import {
    CustomVideoElementRef,
    IFile,
} from '../../../model/types/message.types';

import cls from './VideoMessage.module.scss';

interface IVideoMessageProps {
    file: IFile;
    isBigSizeVideo?: boolean;
    webPreviewClass?: string;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
    messageId: string;
}

const THROTTLE_DELAY = 750;

export const VideoMessage: FC<IVideoMessageProps> = (props) => {
    const {
        file,
        isBigSizeVideo,
        messageId,
        openPreviewVideoOrPhoto,
        webPreviewClass,
    } = props;
    const dispatch = useAppDispatch();

    const videoRef = useRef<CustomVideoElementRef>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const { isIPhone } = useDeviceDetect();

    const [isLoading, setIsLoading] = useState(true);
    const [isPlayPromisePending, setPlayPromisePending] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const { handleOpenVideoIPhone } = useOpenVideoIPhone({ setIsFullscreen, videoRef });
    const { isAndroid, isIOS, isMacintosh } = useDeviceDetect();
    const { isSafari } = useBrowserInfo();

    const hashFile = file.id && messageId ? `${messageId}/${file.id}` : null;

    const openVideo = useThrottle(
        useCallback(
            (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                e.stopPropagation();

                dispatch(messagesActions.updatePlayingFileHashId(hashFile));

                if (isLoading && !file?.thumbnail) {
                    return;
                }

                if (isIPhone && !file?.thumbnail) {
                    handleOpenVideoIPhone();
                    setIsFullscreen(true);
                    return;
                }
                // если у нас существует ref video или thumbnail
                if (videoRef.current || imageRef.current) {
                    // если это видео, то открываем фуллскрин на телефоне анроида
                    if (videoRef.current && requestFullScreenAndroid(videoRef.current, isAndroid)) {
                        return;
                    }

                    // получаем размеры video или thumbnail
                    const rect = videoRef.current ? videoRef.current.getBoundingClientRect()
                        : imageRef.current!.getBoundingClientRect();

                    openPreviewVideoOrPhoto({
                        // если файл video существует, то открываем как видео, иначе - как photo thumbnail
                        contentType: file.file ? 'video' : 'photo',
                        coordinates: {
                            height: rect.height,
                            width: rect.width,
                            x: rect.left,
                            y: rect.top,
                        },
                        fileId: file.id,
                        fileSrc: file.file || file.thumbnail,
                        isOnLoad: !file?.file,
                    });
                }
            },
            [file, isLoading, isIPhone, isAndroid],
        ),
        THROTTLE_DELAY,
    );

    const initVideoSettings = useCallback(async () => {
        const videoPlayer = videoRef.current;
        setIsLoading(false);

        if (videoPlayer) {
            try {
                setPlayPromisePending(true);
                await videoPlayer?.play();
            } catch (error) {
                setPlayPromisePending(false);
                consoleDev(error as Error);
            }
        }
    }, []);

    useEffect(() => {
        const videoPlayer = videoRef.current;

        if (isLoading && !file?.file && file.thumbnail) {
            setIsLoading(false);
        }

        if (!isLoading && isPlayPromisePending && videoPlayer) {
            videoPlayer.pause();
            videoPlayer.currentTime = 0;
            setPlayPromisePending(false);
        }
    }, [isLoading, isPlayPromisePending]);

    useEffect(() => {
        const videoPlayer = videoRef.current;
        const isVideoLoad = videoPlayer && (isIOS || (isMacintosh && isSafari));

        if (isVideoLoad) {
            videoPlayer.addEventListener('canplaythrough', initVideoSettings, false);
            videoPlayer.addEventListener('loadedmetadata', initVideoSettings, false);
        }

        return () => {
            if (isVideoLoad) {
                videoPlayer.removeEventListener('canplaythrough', initVideoSettings, false);
                videoPlayer.removeEventListener('loadedmetadata', initVideoSettings, false);
            }
        };
    }, [isIOS, isSafari, isMacintosh]);

    useEffect(() => {
        const videoPlayer = videoRef.current;

        if (!isFullscreen && videoPlayer) {
            videoPlayer.pause();
        }
    }, [isFullscreen]);

    useEffect(() => {
        const changeFullscreen = () => {
            const videoPlayer = videoRef.current;
            setIsFullscreen(document.fullscreenElement !== null);

            if (videoPlayer && document.fullscreenElement === null && isAndroid) {
                videoPlayer.pause();
                videoPlayer.currentTime = 0;
            }
        };

        document.addEventListener('fullscreenchange', changeFullscreen);

        return () => {
            document.removeEventListener('fullscreenchange', changeFullscreen);
        };
    }, [isAndroid]);

    return (
        <div
            className={classNames(
                cls.video,
                {
                    [cls.singleVideo]: isBigSizeVideo,
                    [cls.withWebPreview]: webPreviewClass,
                },
                [],
            )}
        >
            {file?.file && !file?.thumbnail ? (
                <VideoWithLoader
                    ref={videoRef}
                    className={classNames('', {}, [webPreviewClass])}
                    autoPlay={false}
                    preload="auto"
                    data-id={file.id}
                    src={file?.file}
                    onClick={openVideo}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isFullscreen={isFullscreen}
                />
            )
                : (
                    <ImageWithLoader
                        onClick={openVideo}
                        data-id={file.id}
                        className={classNames(cls.imageWrapper, {}, [webPreviewClass])}
                        classNameImage={cls.imageItem}
                        onContextMenu={(e) => e.preventDefault()}
                        classNameSkeleton={cls.skeletonImage}
                        src={file?.thumbnail || ''}
                        alt={file?.name || 'image'}
                        ref={imageRef}
                    />
                )}
            {file?.file ? (
                <button type="button" className={cls.overlay} onClick={openVideo}>
                    <CaretRightOutlined />
                </button>
            )
                : (
                    <OverlayFileLoading isAlwaysShow onClick={openVideo} onlyIcon />
                )}
        </div>
    );
};
