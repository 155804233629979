export enum EMimeType {
    IMAGE = 'image/',
    VIDEO = 'video/',
    GIF = 'image/gif',
    APPLICATION = 'application/',
    VOICE = 'audio/ogg',
    AUDIO = 'audio/',
}

export const checkIsMimeType = (checkingType: string, typeFromMimeType: EMimeType) => {
    if (checkingType.startsWith(typeFromMimeType)) {
        return true;
    }

    return false;
};
