import { IRole } from 'entities/role';

export interface IUserSchema {
    isAuth: boolean;
    init: boolean;
    userData?: IUserInfo;
    authError: boolean;
    isRoleAssignment: boolean;
    isRoleEdition: boolean;
    isEditPost: boolean;
    isChannelEdition: boolean;
}

export interface IUserFromServer {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    photo: string | null;
    pk: string;
}

export interface IUserSettings {
    disableSyncAlert?: boolean;
}

export interface IUserSettingsFromServer {
    disable_sync_alert?: boolean;
}

export interface IUserInfoFromServer {
    channel_count: number;
    disable_sync_alert: boolean;
    first_name: string;
    has_role: boolean;
    id: string;
    last_name: string;
    photo: string;
    username: string;
    role: IRole;
    settings: IUserSettingsFromServer;
}

export interface IUserInfo {
    channelCount: number;
    disableSyncAlert: boolean;
    firstName: string;
    hasRole: boolean;
    id: string;
    lastName: string;
    photo: string;
    role: IRole;
    userName: string;
    settings: IUserSettings;
}

export interface IRightsUser {
    isRoleAssignment: boolean;
    isRoleEdition: boolean;
    isEditPost: boolean;
    isChannelEdition: boolean;
}

export enum ERightsUser {
    IS_ROLE_ASSIGNMENT = 'isRoleAssignment',
    IS_ROLE_EDITION = 'isRoleEdition',
    IS_EDIT_POST = 'isEditPost',
    IS_CHANNEL_EDITION = 'isChannelEdition',
}

export interface IUpdateUserState {
    data: IUserInfo;
    isRoleAssignment: boolean;
    isRoleEdition: boolean;
    isEditPost: boolean;
    isChannelEdition: boolean;
}

export interface IUpdateAuthState {
    data?: IUserInfo;
    isRoleAssignment?: boolean;
    isRoleEdition?: boolean;
    isEditPost?: boolean;
    isChannelEdition?: boolean;
    isAuth: boolean;
}

export interface IUpdateUserSettings {
    settings?: IUserSettings;
    firstName?: string;
    lastName?: string;
    userName?: string;
}
