import {
    FC,
    useMemo,
    Dispatch,
    SetStateAction,
    memo,
    useCallback,
    useState,
} from 'react';

import {
    Button,
    Checkbox,
    Modal,
} from 'antd';

import { EModalWidth } from 'shared/constants/modal-sizes';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

import cls from './SyncMessagesModal.module.scss';
import './SyncMessages.scss';

interface ISyncMessagesModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onOk: (isShowSyncModal?: boolean) => void;
}

export const SyncMessagesModal: FC<ISyncMessagesModalProps> = memo((props) => {
    const {
        isOpen, onOk, setIsOpen,
    } = props;

    const [checkboxValue, setCheckboxValue] = useState(false);

    const handleOk = useCallback(() => {
        setIsOpen(false);
        onOk(checkboxValue);
    }, [onOk, checkboxValue]);

    const handleCancel = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleCheckboxValue = () => {
        setCheckboxValue((prev) => !prev);
    };

    const footer = useMemo(() => (
        <Flex className={cls.footer} max gaps="8">
            <Button key="back" block onClick={handleCancel}>
                Назад
            </Button>
            <Button
                type="primary"
                key="apply"
                block
                onClick={handleOk}
            >
                Продолжить
            </Button>
        </Flex>

    ), [handleOk, handleCancel]);

    return (
        <Modal
            open={isOpen}
            closeIcon={false}
            width={EModalWidth.width400}
            rootClassName="syncMessageModal"
            footer={footer}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Flex gaps="12" direction="column" align="start">
                <Text
                    isTitle
                    size="lg"
                    align="center"
                    theme="black"
                    weight="600"
                    text="Синхронизация"
                    max
                />
                <Text
                    text={(
                        <>
                            После сохранения изменений данное сообщение перестанет синхронизироваться с донор-каналом.
                        </>
                    )}
                />
                <Checkbox onChange={handleCheckboxValue}>Не показывать больше это сообщение</Checkbox>
            </Flex>
        </Modal>
    );
});
