// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m1snI3MC{margin:var(--p-2) 0}.dTHswDCI{color:var(--blue-primary)}`, "",{"version":3,"sources":["webpack://./src/widgets/edit-message/ui/variants/upload-media/modal/WebPreviewWarningModal.module.scss"],"names":[],"mappings":"AAAA,UACI,mBAAA,CAGJ,UACI,yBAAA","sourcesContent":[".webPreviewText {\n    margin: var(--p-2) 0;\n}\n\n.webPreviewLink {\n    color: var(--blue-primary)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"webPreviewText": `m1snI3MC`,
	"webPreviewLink": `dTHswDCI`
};
export default ___CSS_LOADER_EXPORT___;
