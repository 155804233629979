// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Le06ftBa{padding-top:30vh;width:100%}.w87JU7DA{margin-top:var(--p-3)}`, "",{"version":3,"sources":["webpack://./src/pages/error-page/ui/ErrorPage.module.scss"],"names":[],"mappings":"AAAA,UACI,gBAAA,CACA,UAAA,CAGJ,UACI,qBAAA","sourcesContent":[".content {\n    padding-top: 30vh;\n    width: 100%;\n}\n\n.reloadPage {\n    margin-top: var(--p-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Le06ftBa`,
	"reloadPage": `w87JU7DA`
};
export default ___CSS_LOADER_EXPORT___;
