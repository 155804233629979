import {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getIsAnimationScrollMessage } from 'entities/message';

interface IUsePinMessageAnimationProps {
    holderRef: RefObject<HTMLImageElement>;
    isShowFoundMessage: string | null;
    clearShowFoundMessage: () => void;
    updateScrollMessageId: boolean;
    setUpdateScrollMessageId: Dispatch<SetStateAction<boolean>>;
}

const ANIMATION_TIME = 1500;

export const usePinMessageAnimation = ({
    clearShowFoundMessage,
    holderRef,
    isShowFoundMessage,
    setUpdateScrollMessageId,
    updateScrollMessageId,
}: IUsePinMessageAnimationProps): boolean => {
    const [isAnimation, seIsAnimation] = useState(false);
    const isAnimationScrollMessage = useSelector(getIsAnimationScrollMessage);
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (isAnimationScrollMessage) {
                    clearShowFoundMessage();
                    seIsAnimation(true);
                    timeout.current = setTimeout(() => {
                        seIsAnimation(false);
                    }, ANIMATION_TIME);
                }
            }
        });
    });

    useEffect(() => {
        if (isShowFoundMessage && holderRef.current) {
            seIsAnimation(false);
            setUpdateScrollMessageId(false);
            observer.observe(holderRef.current);
        }
        return () => {
            if (holderRef.current) observer.unobserve(holderRef.current);
            if (isAnimation && timeout) {
                clearTimeout(timeout.current);
                seIsAnimation(false);
            }
        };
    }, [isShowFoundMessage, updateScrollMessageId]);

    return isAnimation;
};
