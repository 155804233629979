import { isJSON } from '../json/json';

interface LocalStoreInterface {
    set: <T>(key: string, data: T) => void;
    get: <T>(key: string) => T;
    remove: (key: string | string[]) => void;
    clear: () => void;
}

export const LocalStore: LocalStoreInterface = {
    clear: () => {
        localStorage.clear();
    },
    get: (key) => {
        const value = localStorage.getItem(key);
        return isJSON(value) ? JSON.parse(localStorage.getItem(key)!) : value;
    },
    remove: (removedKey) => {
        const keys = Array.isArray(removedKey) ? removedKey : [removedKey];
        keys.forEach((key) => localStorage.removeItem(key));
    },
    set: (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
    },
};
