// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HUyhzZJl{width:100%;margin-bottom:0}.HUyhzZJl label{font-size:var(--font-size-md) !important}`, "",{"version":3,"sources":["webpack://./src/widgets/channel-editor/ui/common/form-item/FormItem.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,eAAA,CAEA,gBACI,wCAAA","sourcesContent":[".formItem {\n    width: 100%;\n    margin-bottom: 0;\n\n    label {\n        font-size: var(--font-size-md) !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `HUyhzZJl`
};
export default ___CSS_LOADER_EXPORT___;
