import {
    FC,
    memo,
    useCallback,
} from 'react';

import {
    Button,
    Modal,
} from 'antd';

import { EImportedFromMessage } from 'shared/constants/message';
import { EModalWidth } from 'shared/constants/modal-sizes';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

import cls from './DeleteMessageModal.module.scss';

interface IDeleteModalProps {
    className?: string;
    isOpen: boolean;
    onClose: () => void;
    onDeleteMessage: (messageId: string) => void;
    messageId: string;
    importedFrom: string | null;
}

export const DeleteMessageModal: FC<IDeleteModalProps> = memo((props) => {
    const {
        className, importedFrom, isOpen, messageId, onClose, onDeleteMessage,
    } = props;

    const text = importedFrom !== EImportedFromMessage.WEB_INTERFACE
        ? 'Удаление публикации не затронет пост в донор канале. Также публикация больше не будет связана с ним.'
        : 'Вы действительно хотите удалить публикацию? Восстановить публикацию будет невозможно.';

    const handleDeleteMessage = useCallback(() => {
        onDeleteMessage(messageId);
        onClose();
    }, [onDeleteMessage, onClose, messageId]);

    return (
        <Modal
            open={isOpen}
            centered
            onCancel={onClose}
            footer={null}
            width={EModalWidth.width400}
            className={classNames(cls.modalDelete, {}, [className])}
        >
            <Flex gaps="12" direction="column" align="center">
                <Text isTitle size="lg" theme="black" weight="600" text="Удалить публикацию?" />

                <Text
                    align="center"
                    theme="black"
                    text={text}
                />

                <Flex direction="row" gaps="12" max className={cls.buttonGroup}>
                    <Button onClick={onClose} type="default" size="large" block>
                        Отменить
                    </Button>
                    <Button onClick={handleDeleteMessage} block type="primary" danger size="large">
                        Удалить
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
});
