import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';

import {
    ERightsUser,
    getStateUser,
    getUserAuth,
} from 'entities/user';
import {
    AUTH_URL,
    HOME_URL,
} from 'shared/constants/router';

interface IRequireAuthProps {
    children: JSX.Element;
    accessRight?: ERightsUser;
}

export const RequireAuth: FC<IRequireAuthProps> = (props) => {
    const { accessRight, children } = props;

    const auth = useSelector(getUserAuth);
    const stateUser = useSelector(getStateUser);

    const access = accessRight ? stateUser?.[accessRight] : true;

    const location = useLocation();

    if (!access) {
        return <Navigate to={HOME_URL} state={{ from: location }} replace />;
    }

    if (!auth) {
        return <Navigate to={AUTH_URL} state={{ from: location }} replace />;
    }

    return children;
};
