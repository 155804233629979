import {
    FC,
    ReactNode,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './MiniHeader.module.scss';

interface IMiniHeaderProps {
    className?: string;
    children: ReactNode;
}

export const MiniHeader: FC<IMiniHeaderProps> = (props) => {
    const { children, className } = props;

    return (
        <section className={classNames(cls.miniHeader, {}, [className])}>
            {children}
        </section>
    );
};
