// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cLvIb1Ap{width:100%;height:100%;position:relative}.a1wl3O6i{background:initial}`, "",{"version":3,"sources":["webpack://./src/pages/telegram-auth/ui/TelegramAuthPage.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,UACI,kBAAA","sourcesContent":[".container {\n    width: 100%;\n    height: 100%; \n    position: relative;\n}\n\n.loader {\n    background: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cLvIb1Ap`,
	"loader": `a1wl3O6i`
};
export default ___CSS_LOADER_EXPORT___;
