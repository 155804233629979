import {
    FC,
    ChangeEvent,
} from 'react';

import {
    Button,
    Input,
    Modal,
} from 'antd';

import { EModalWidth } from 'shared/constants/modal-sizes';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

import { FormItem } from '../../../../common/form-item/FormItem';

import cls from './CreateVariableModal.module.scss';

interface ICreateVariableModalProps {
    open: boolean;
    onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
    updateVariable: () => void;
    createVariable: () => void;
    onClose: () => void;
    variableName: string;
    variableValue: string;
    isNewVar?: boolean;
    errorName?: string;
}

export const CreateVariableModal: FC<ICreateVariableModalProps> = (props) => {
    const {
        createVariable, errorName, isNewVar, onChangeName, onChangeValue,
        onClose, open, updateVariable, variableName,
        variableValue,
    } = props;

    const onOk = () => {
        isNewVar ? createVariable() : updateVariable();
    };

    const buttonName = isNewVar ? 'Создать' : 'Сохранить';
    const title = isNewVar ? 'Создание переменной' : 'Изменение переменной';

    return (
        <Modal
            footer={null}
            open={open}
            onCancel={onClose}
            closeIcon={null}
            rootClassName={cls.createVariableModal}
            width={EModalWidth.width348}
            centered
        >
            <Flex direction="column">
                <Text
                    className={cls.title}
                    max
                    align="center"
                    isTitle
                    size="lg"
                    theme="black"
                    weight="600"
                    text={title}
                />

                <FormItem
                    validateStatus={errorName ? 'error' : undefined}
                    help={errorName || null}
                    className={cls.titleInput}
                >
                    <Input size="large" value={variableName} onChange={onChangeName} placeholder="Название" />
                </FormItem>
                <Input
                    size="large"
                    className={cls.valueInput}
                    value={variableValue}
                    placeholder="Значение"
                    onChange={onChangeValue}
                />

                <Flex gaps="12" className={cls.btnControls} max>
                    <Button size="large" onClick={onClose} block>Отменить</Button>
                    <Button
                        size="large"
                        disabled={!variableName || !variableValue}
                        block
                        type="primary"
                        onClick={onOk}
                    >
                        {buttonName}
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};
