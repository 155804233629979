import {
    FC,
    memo,
    useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';

import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import ExportOutlined from '@ant-design/icons/ExportOutlined';
import { IChannel } from 'entities/channels';
import { EDIT_CHANNEL_URL } from 'shared/constants/router';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import cls from './NotificationItem.module.scss';

interface INotificationItemProps {
    date: string;
    description: string | null;
    title: string | null;
    page: string | null;
    postId?: string;
    handleShowMessage: (id: string, page?: string | null) => void;
    index: number;
    channelId: IChannel['id'];
    isSettingRedirect: boolean;
}

export const NotificationItem: FC<INotificationItemProps> = memo((props) => {
    const {
        channelId,
        date,
        description = '',
        handleShowMessage,
        index,
        isSettingRedirect,
        page,
        postId,
        title = '',
    } = props;

    const navigate = useNavigate();

    const isShowScrollIcon = (postId && page) || isSettingRedirect;
    const isFirstItem = index === 0;

    const handleClick = useCallback(() => {
        if (isSettingRedirect) {
            navigate(`${EDIT_CHANNEL_URL}/${channelId}`);
            return;
        }

        if (postId && page) {
            handleShowMessage(postId, page);
        }
    }, [isSettingRedirect, postId, page, channelId]);

    return (
        <>
            {index !== 0 && <div className={cls.dividingline} />}
            <button
                type="button"
                className={classNames(cls.notificationItem, { [cls.firstItem]: isFirstItem })}
                onClick={handleClick}
            >
                <div>
                    <ExclamationCircleFilled className={cls.iconWarning} />
                </div>
                <div className={cls.notificationInfo}>
                    <div className={cls.notificationHeader}>
                        <Text text={title} className={cls.title} size="md" weight="600" theme="black" />
                        <Text text={date} size="xs" className={cls.date} />
                    </div>
                    <div className={cls.content}>
                        <Text text={description} className={cls.text} />
                        {isShowScrollIcon && <ExportOutlined className={cls.iconScroll} />}
                    </div>
                </div>
            </button>
        </>
    );
});
