import React, {
    Dispatch,
    FC,
    MouseEvent,
    SetStateAction,
    useCallback,
} from 'react';

import {
    Calendar as AntdCalendar,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { FooterCalendar } from '../footer-calendar/FooterCalendar';
import { HeaderCalendar } from '../header-calendar/HeaderCalendar';

import cls from './Calendar.module.scss';

interface ICalendarProps {
    onChange: (value?: string) => void;
    setVisible: Dispatch<SetStateAction<boolean>>;
    currentDate?: Dayjs;
    setCurrentDate: Dispatch<SetStateAction<Dayjs | undefined>>;
}

export const Calendar: FC<ICalendarProps> = (props) => {
    const {
        currentDate,
        onChange,
        setCurrentDate,
        setVisible,
    } = props;

    const onCloseCalendar = useCallback(() => {
        setVisible(false);
    }, []);

    const onToday = useCallback(() => {
        setCurrentDate(dayjs());
    }, []);

    const onMonthChange = useCallback((offset: number) => {
        const dateValue = currentDate || dayjs();
        setCurrentDate(dateValue.add(offset, 'month'));
    }, [currentDate]);

    const onYearChange = useCallback((offset: number) => {
        const dateValue = currentDate || dayjs();
        setCurrentDate(dateValue.add(offset, 'year'));
    }, [currentDate]);

    const onChangeDate = useCallback((value: Dayjs) => {
        onChange(value.format('YYYY-MM-DD'));
        onCloseCalendar();
    }, [onChange]);

    const onClickHolderCalendar = useCallback((e: MouseEvent<HTMLButtonElement>) => e.stopPropagation(), []);

    const headerRender = ({
        value,
    }: { value: Dayjs }) => (
        <HeaderCalendar
            onYearChange={onYearChange}
            onMonthChange={onMonthChange}
            value={value}
        />
    );

    return (
        <button
            type="button"
            className={cls.holderCalendar}
            onClick={onClickHolderCalendar}
        >
            <AntdCalendar
                value={currentDate || undefined}
                headerRender={headerRender}
                fullscreen={false}
                onSelect={onChangeDate}
                rootClassName={cls.calendar}
            />
            <FooterCalendar onToday={onToday} />
        </button>
    );
};
