import {
    FC,
    memo,
} from 'react';

import { AvatarPlaceholder } from 'shared/assets/components/AvatarPlaceholder';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getUserColorByChannelId } from 'shared/lib/utils/user/user';

import { IChannel } from '../../model/types/channels.types';

import cls from './ChannelAvatar.module.scss';

interface IChannelAvatarProps {
    className?: string;
    channel: IChannel;
}

export const ChannelAvatar: FC<IChannelAvatarProps> = memo((props) => {
    const { channel, className } = props;

    const color = getUserColorByChannelId(channel.id);

    return (
        <div className={classNames(cls.imgWrapper, {}, [className])}>
            {channel.channelPhoto ? (
                <img src={channel.channelPhoto.file} alt={channel.name} />
            ) : (
                <AvatarPlaceholder id={channel.id} startColor={color[0]} stopColor={color[1]} />
            )}
        </div>
    );
});
