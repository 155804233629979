import { FC } from 'react';

import { Button } from 'antd';

import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';
import { HeaderWrapper } from 'widgets/header';

import cls from './ErrorPage.module.scss';

export const ErrorPage: FC = () => {
    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <>
            <HeaderWrapper isTransparent />
            <Flex align="center" direction="column" className={cls.content}>
                <Text text="Произошла ошибка!" as="h1" size="xl" isTitle />
                <Button
                    onClick={reloadPage}
                    className={cls.reloadPage}
                    type="default"
                >
                    Обновить страницу
                </Button>
            </Flex>
        </>
    );
};
