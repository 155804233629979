import { api } from 'shared/config/rtk-query-api/api.services';

import { transformBodyAuthTelegramApi } from '../../adapters/serverData';
import { ITokenAuth } from '../types/telegramAuth.types';

const authTelegramApi = api.injectEndpoints({
    endpoints: (builder) => ({
        checkTokenTelegram: builder.mutation<{ token: string }, ITokenAuth>({
            invalidatesTags: ['UserData'],
            query: (data) => ({ body: transformBodyAuthTelegramApi(data), method: 'POST', url: 'api/v1/auth/login/' }),
        }),
    }),
});

export const {
    useCheckTokenTelegramMutation,
} = authTelegramApi;
