import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getIsRoleEdition } from 'entities/user';
import {
    EMPLOYEE_MANAGEMENT_URL,
    ROLES_MANAGEMENT_URL,
} from 'shared/constants/router';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';

import cls from './EmployeeRoleTabs.module.scss';

type TVariant = 'role' | 'employee';

interface IEmployeeRoleTabsProps {
    className?: string;
    type: TVariant;
}

export const EmployeeRoleTabs: FC<IEmployeeRoleTabsProps> = (props) => {
    const { className, type } = props;

    const isRoleEdition = useSelector(getIsRoleEdition);

    return (
        <Flex align="start" className={className} gaps="24">
            <NavLink
                className={({ isActive }) => classNames(cls.tab, {
                    [cls.active]: isActive,
                    [cls.mobileHide]: type === 'role',
                })}
                to={EMPLOYEE_MANAGEMENT_URL}
            >
                Сотрудники
            </NavLink>
            {isRoleEdition && (
                <NavLink
                    className={({ isActive }) => classNames(cls.tab, {
                        [cls.active]: isActive,
                        [cls.mobileHide]: type === 'employee',
                    })}
                    to={ROLES_MANAGEMENT_URL}
                >
                    Роли
                </NavLink>
            )}
        </Flex>
    );
};
