import { api } from 'shared/config/rtk-query-api/api.services';

import { transformGetTelegramConfig } from '../../adapters/serverData';
import {
    IResponseTelegramConfig,
} from '../types/auth.types';

const authTelegramApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTelegramConfig: builder.query<IResponseTelegramConfig, void>({
            query: () => ({ method: 'GET', url: '/api/v1/auth/login-telegram/config' }),
            transformResponse: transformGetTelegramConfig,
        }),
    }),
});

export const {
    useGetTelegramConfigQuery,
} = authTelegramApi;
