import { transformApiListResponse } from 'shared/lib/utils/api/api';
import { removeUndefinedKeys } from 'shared/lib/utils/object/object';
import { IBackendListResponse } from 'shared/types/types';

import {
    IParamsExcludeInclude,
    IParamsExcludeIncludeFromServer,
    IUsers,
    IUsersFromServer,
} from '../model/types/create-role.types';

export function mapUserRole(rawResult: IUsersFromServer): IUsers {
    return {
        disableSyncAlert: rawResult.disable_sync_alert,
        firstName: rawResult.first_name,
        id: rawResult.id,
        lastName: rawResult.last_name,
        photo: rawResult.photo,
        username: rawResult.username,
    };
}

export const transformGetUsersRoleList = async (rawResult:
IBackendListResponse<IUsersFromServer>) => transformApiListResponse<IUsersFromServer, IUsers>(rawResult, mapUserRole);

export const transformBodyRequestParams = (body: IParamsExcludeInclude):
IParamsExcludeIncludeFromServer => (removeUndefinedKeys({
    exclude_ids: body.excludeIds,
    include_ids: body.includeIds,
}));
