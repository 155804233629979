import {
    FC,
    memo,
    useCallback,
    useState,
} from 'react';

import {
    Button,
    FormInstance,
} from 'antd';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import {
    IChannel,
    IVariable,
} from 'entities/channels';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';

import { CHANNEL_FORM_FIELDS } from '../../../constants/form-fields.constant';

import { DisplayItem } from './items/display/DisplayItem';
import { VariableEditor } from './items/edit';

import cls from './VariablesBlock.module.scss';

interface IVariablesBlockProps {
    form: FormInstance<IChannel>;
    variable?: IVariable;
    isNewVar?: boolean;
    triggerUpdateVariables: () => void;
}

export const VariablesBlock: FC<IVariablesBlockProps> = memo((props) => {
    const {
        form, isNewVar,
        triggerUpdateVariables, variable,
    } = props;

    const [isChanging, setIsChanging] = useState(false);

    const { isTablet } = useScreenType();

    const getAllVariables = useCallback(() => form.getFieldValue(CHANNEL_FORM_FIELDS.VARIABLES)
     || [], [form]);

    const openModalToChangeVariable = () => {
        setIsChanging(true);
    };

    const isShouldShowDisplayWithoutEdit = variable?.name && !isChanging && !isTablet;

    if (isShouldShowDisplayWithoutEdit) {
        return (
            <DisplayItem
                form={form}
                getAllVariables={getAllVariables}
                triggerUpdateVariables={triggerUpdateVariables}
                setIsChanging={setIsChanging}
                variable={variable}
                isTablet={isTablet}
            />
        );
    }

    return (
        <>
            {(isTablet && variable?.name) && (
                <DisplayItem
                    form={form}
                    getAllVariables={getAllVariables}
                    triggerUpdateVariables={triggerUpdateVariables}
                    setIsChanging={setIsChanging}
                    variable={variable}
                    isTablet={isTablet}
                />
            )}

            { (isTablet && isNewVar)
            && (
                <Button
                    className={cls.addVariableBtn}
                    size="large"
                    onClick={openModalToChangeVariable}
                    block
                    icon={<PlusOutlined />}
                >
                    Добавить переменную
                </Button>
            ) }

            <VariableEditor
                form={form}
                getAllVariables={getAllVariables}
                triggerUpdateVariables={triggerUpdateVariables}
                setIsChanging={setIsChanging}
                variable={variable}
                isNewVar={isNewVar}
                isChanging={isChanging}
                isTablet={isTablet}
            />
        </>
    );
});
