// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.beRo24I0{width:54px;height:54px;min-width:54px}.beRo24I0 img{width:100%;height:100%;border-radius:var(--radius-5);display:block}`, "",{"version":3,"sources":["webpack://./src/entities/channels/ui/avatar/ChannelAvatar.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,WAAA,CACA,cAAA,CAEA,cACI,UAAA,CACA,WAAA,CACA,6BAAA,CACA,aAAA","sourcesContent":[".imgWrapper {\n    width: 54px;\n    height: 54px;\n    min-width: 54px;\n\n    img {\n        width: 100%;\n        height: 100%;\n        border-radius: var(--radius-5);\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrapper": `beRo24I0`
};
export default ___CSS_LOADER_EXPORT___;
