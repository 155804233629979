import { Dayjs } from 'dayjs';

import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IVariable } from 'entities/channels';
import {
    IMessageButton,
    IPostPollAnswers,
} from 'entities/message';
import {
    ETextMaxSymbolsLength,
    Node,
    deserialize,
} from 'features/rich-text-editor';

import {
    CustomUploadFile,
    ICreateMessageSchema,
    TMediaFile,
} from '../types/createMessage.types';

const initialState: ICreateMessageSchema = {
    answers: [],
    audio: null,
    buttons: [],
    date: null,
    dateValidType: '',
    documents: null,
    isDirty: false,
    isNewAnswer: false,
    isNotifyPin: false,
    isPinIndefinitely: false,
    isWebPreview: false,
    maxSymbolsLength: ETextMaxSymbolsLength.LONG_LENGTH,
    media: null,
    multipleChoice: false,
    pinDuration: null,
    question: '',
    quiz: false,
    roundVideo: null,
    solutionIndex: null,
    solutionMessage: '',
    text: deserialize(''),
    time: null,
    timezone: '',
    variables: [],
    voice: null,
};

export const createMessageSlice = createSlice({
    initialState,
    name: 'createMessage',
    reducers: {
        addButton(state, { payload: button }: PayloadAction<IMessageButton>) {
            state.buttons.push(button);
            state.isDirty = true;
        },
        changeButton(state, { payload: button }: PayloadAction<IMessageButton>) {
            state.buttons = state.buttons.map((btn) => {
                if (btn.id === button.id) {
                    return button;
                }
                return btn;
            });
            state.isDirty = true;
        },
        clearData(state) {
            state.date = null;
            state.documents = null;
            state.media = null;
            state.text = deserialize('');
            state.time = null;
            state.maxSymbolsLength = ETextMaxSymbolsLength.LONG_LENGTH;
            state.isDirty = false;
            state.buttons = [];
            state.roundVideo = null;
            state.voice = null;
            state.isNotifyPin = false;
            state.isPinIndefinitely = false;
            state.isWebPreview = false;
            state.pinDuration = null;
            state.audio = null;
            state.answers = [];
            state.multipleChoice = false;
            state.question = '';
            state.quiz = false;
            state.solutionIndex = null;
            state.solutionMessage = '';
        },
        clearText(state) {
            state.text = deserialize('');
        },
        deleteButton(state, { payload: id }: PayloadAction<string>) {
            state.buttons = state.buttons.filter((button) => button.id !== id);
            state.isDirty = true;
        },
        setAnswersPoll(state, { payload: value }: PayloadAction<IPostPollAnswers[]>) {
            state.answers = value;
        },
        setAudio(state, { payload: audio }: PayloadAction<CustomUploadFile | null>) {
            state.audio = audio;
            state.isDirty = true;
        },
        setData(state, { payload: data }: PayloadAction<ICreateMessageSchema>) {
            state.date = data.date;
            state.documents = data.documents;
            state.media = data.media;
            state.text = data.text;
            state.time = data.time;
            state.timezone = data.timezone;
            state.variables = data.variables;
            state.buttons = data.buttons;
            state.maxSymbolsLength = data.maxSymbolsLength;
            state.pinDuration = data.pinDuration;
            state.isNotifyPin = data.isNotifyPin;
            state.isPinIndefinitely = data.isPinIndefinitely;
            state.isDirty = true;
            state.answers = data.answers;
            state.multipleChoice = data.multipleChoice;
            state.question = data.question;
            state.quiz = data.quiz;
            state.solutionIndex = data.solutionIndex;
        },
        setDate(state, { payload: date }: PayloadAction<Dayjs>) {
            state.date = date;
            state.isDirty = true;
        },
        setDocuments(state, { payload: documents }: PayloadAction<TMediaFile>) {
            state.documents = documents;
            state.isDirty = true;
        },
        setDurationPin(state, { payload: pinDuration }: PayloadAction<ICreateMessageSchema['pinDuration']>) {
            state.pinDuration = pinDuration;
            state.isPinIndefinitely = false;
            state.isDirty = true;
        },
        setIsNewAnswer(state, { payload: value }: PayloadAction<boolean>) {
            state.isNewAnswer = value;
        },
        setIsNotifyPin(state, { payload: isNotify }: PayloadAction<boolean>) {
            state.isNotifyPin = isNotify;
            state.isDirty = true;
        },
        setIsPinIndefinitely(state, { payload: isPinIndefinitely }: PayloadAction<boolean>) {
            state.pinDuration = null;
            state.isPinIndefinitely = isPinIndefinitely;
            state.isDirty = true;
        },
        setMaxSymbolsLength(state, { payload: maxLength }: PayloadAction<ICreateMessageSchema['maxSymbolsLength']>) {
            state.maxSymbolsLength = maxLength;
        },
        setMedia(state, { payload: media }: PayloadAction<TMediaFile>) {
            state.media = media;
            state.isDirty = true;
        },
        setMultipleChoicePoll(state, { payload: value }: PayloadAction<boolean>) {
            state.multipleChoice = value;
        },
        setQuestionPoll(state, { payload: value }: PayloadAction<string>) {
            state.question = value;
        },
        setQuizPoll(state, { payload: value }: PayloadAction<boolean>) {
            state.quiz = value;
        },
        setRoundVideo(state, { payload: roundVideo }: PayloadAction<CustomUploadFile | null>) {
            state.roundVideo = roundVideo;
            state.isDirty = true;
        },
        setSolutionIndexPoll(state, { payload: value }: PayloadAction<number | null>) {
            state.solutionIndex = value;
        },
        setSolutionMessage(state, { payload: value }: PayloadAction<string>) {
            state.solutionMessage = value;
        },
        setText(state, { payload: text }: PayloadAction<Node[]>) {
            state.text = text;
            state.isDirty = true;
        },
        setTime(state, { payload: time }: PayloadAction<Dayjs>) {
            state.time = time;
            state.isDirty = true;
        },
        setTimezone(state, { payload: timezone }: PayloadAction<string>) {
            state.timezone = timezone;
        },
        setValidDateType(state, { payload: dateValidType }: PayloadAction<ICreateMessageSchema['dateValidType']>) {
            state.dateValidType = dateValidType;
        },
        setVariables(state, { payload: variables }: PayloadAction<IVariable[]>) {
            state.variables = variables;
        },
        setVoice(state, { payload: voice }: PayloadAction<CustomUploadFile | null>) {
            state.voice = voice;
            state.isDirty = true;
        },
        setWebPreview(state, { payload: value }: PayloadAction<boolean>) {
            state.isWebPreview = value;
            state.maxSymbolsLength = value ? ETextMaxSymbolsLength.LONG_LENGTH : ETextMaxSymbolsLength.SHORT_LENGTH;
        },
        unpinMessage(state) {
            state.pinDuration = null;
            state.isPinIndefinitely = false;
        },
    },
});

export const { reducer: createMessageReducer } = createMessageSlice;
export const { actions: createMessageActions } = createMessageSlice;
