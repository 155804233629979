// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xrwK0CwU{display:flex;align-items:center;cursor:pointer;min-width:100px}.WxPVqIzk{display:flex;flex-direction:column;margin-left:var(--p-1)}.WxPVqIzk span:first-child{color:var(--white-primary);font:var(--font-sm)}.WxPVqIzk span:last-child{color:var(--gray-4);font:var(--font-xs)}.L_Ck1Jzm:hover{background-color:rgba(0,0,0,0) !important}`, "",{"version":3,"sources":["webpack://./src/entities/user/ui/UserHeaderInfo/UserHeaderInfo.module.scss"],"names":[],"mappings":"AAAA,UACI,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CAGJ,UACI,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,2BACI,0BAAA,CACA,mBAAA,CAGJ,0BACI,mBAAA,CACA,mBAAA,CAIR,gBACI,yCAAA","sourcesContent":[".userHeaderInfo {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    min-width: 100px;\n}\n\n.info {\n    display: flex;\n    flex-direction: column;\n    margin-left: var(--p-1);\n\n    & span:first-child {\n        color: var(--white-primary);\n        font: var(--font-sm);\n    }\n\n    & span:last-child {\n        color: var(--gray-4);\n        font: var(--font-xs);\n    }\n}\n\n.logout:hover {\n    background-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userHeaderInfo": `xrwK0CwU`,
	"info": `WxPVqIzk`,
	"logout": `L_Ck1Jzm`
};
export default ___CSS_LOADER_EXPORT___;
