// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a0RxCrhi{padding:var(--p-1) var(--p-1-5);max-width:480px;width:100%}.cmmUXnmf{margin-bottom:var(--p-1)}.udXIvpaL{margin-bottom:var(--p-2)}.H3D3xs4y{width:20px;height:20px;min-width:20px;min-height:20px;border-radius:var(--radius-5);border:1px solid var(--black-percent-15);margin-right:var(--p-1-5);margin-top:1px}.q2n1U_Q1{border-radius:var(--radius-0-5)}.iQn0kOPv{display:flex;align-items:flex-start}.citE85KQ{display:flex;flex-direction:column;row-gap:var(--p-2)}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/variants/poll-message/PollMessage.module.scss"],"names":[],"mappings":"AAEA,UACI,+BAAA,CACA,eAAA,CACA,UAAA,CAGJ,UACI,wBAAA,CAGJ,UACI,wBAAA,CAGJ,UACI,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,6BAAA,CACA,wCAAA,CACA,yBAAA,CACA,cAAA,CAGJ,UACI,+BAAA,CAGJ,UACI,YAAA,CACA,sBAAA,CAGJ,UACI,YAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":["@use 'app/styles/mixins/mixins';\n\n.container {\n    padding: var(--p-1) var(--p-1-5);\n    max-width: 480px;\n    width: 100%;\n}\n\n.question {\n    margin-bottom: var(--p-1);\n}\n\n.typePoll {\n    margin-bottom: var(--p-2);\n}\n\n.typePollBlock {\n    width: 20px;\n    height: 20px;\n    min-width: 20px;\n    min-height: 20px;\n    border-radius: var(--radius-5);\n    border: 1px solid var(--black-percent-15);\n    margin-right: var(--p-1-5);\n    margin-top: 1px;\n}\n\n.typePollBlockMultiple {\n    border-radius: var(--radius-0-5);\n} \n\n.holderAnswer {\n    display: flex;\n    align-items: flex-start;\n}\n\n.holderAnswerList {\n    display: flex;\n    flex-direction: column;\n    row-gap: var(--p-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `a0RxCrhi`,
	"question": `cmmUXnmf`,
	"typePoll": `udXIvpaL`,
	"typePollBlock": `H3D3xs4y`,
	"typePollBlockMultiple": `q2n1U_Q1`,
	"holderAnswer": `iQn0kOPv`,
	"holderAnswerList": `citE85KQ`
};
export default ___CSS_LOADER_EXPORT___;
