export const cloneObject = (obj: object): object => JSON.parse(JSON.stringify(obj));

// Оборачивает данные объекта в массив
// Например, если приходит { a: "one", b: "two" } , то результатом выполнения будет
// { a: ["one"], b: ["two"] }
export const wrapStringValuesInArray = (obj: Record<string, string>): Record<string, string[]> => {
    const result: Record<string, string[]> = {};

    Object.keys(obj).forEach((key) => {
        result[key] = [obj[key]];
    });

    return result;
};

export const removeUndefinedKeys = <T extends Record<string, any>>(obj: T): Partial<T> => {
    const result: Partial<T> = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key as keyof T] !== undefined) {
            result[key as keyof T] = obj[key as keyof T];
        }
    });
    return result;
};
