import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
} from 'react';

import { PAGINATION_LIMIT } from 'shared/constants/pagination';

import useBrowserInfo from '../useBrowserInfo/useBrowserInfo';
import useDeviceDetect from '../useDeviceDetect/useDeviceDetect';

interface IDefaultListDataArgs {
    id: number | string;
}

interface IUseScrollProps<T extends IDefaultListDataArgs, U> {
    holderListRef: MutableRefObject<HTMLElement | null>;
    listData: T[];
    handleLoadNewNextMessages: () => void;
    handleLoadPrevNextMessages: () => void;
    displayListData: U[];
    indexStartMes: number;
    setIsScrollEndList?: Dispatch<SetStateAction<boolean>>;
    setIndexStartMes: (index: number) => void;
    isDisabledScrollState: boolean;
    onAdjustScroll?: () => void;
}

const MAX_DISPLAY_POSTS = PAGINATION_LIMIT * 2;
const HEIGHT_DOWN_ARROW_DISPLAY = 300;

export const useScroll = <T extends IDefaultListDataArgs, U>({
    displayListData,
    handleLoadNewNextMessages,
    handleLoadPrevNextMessages,
    holderListRef,
    indexStartMes,
    isDisabledScrollState,
    listData,
    onAdjustScroll,
    setIndexStartMes,
    setIsScrollEndList,
}: IUseScrollProps<T, U>) => {
    const scrollHeight = useRef<number>(0);
    const scrollTop = useRef<number>(0);
    const scrollBottom = useRef<number>(0);
    const isDisabledScroll = useRef(false);

    const { isSafari } = useBrowserInfo();
    const { isIOS } = useDeviceDetect();

    const handleScrollBottom = useCallback(() => {
        if (holderListRef.current) {
            holderListRef.current.scrollTop = holderListRef.current.scrollHeight;
        }
    }, []);

    const scrollBottomForSafariAndIos = useCallback((
        index: number,
        additionalIndentation: number,
        isEndList: boolean = true,
    ) => {
        if (isIOS || isSafari) {
            const averageDisplayedMessageId = listData[index]?.id?.toString();
            const averageDisplayedElement = document.getElementById(averageDisplayedMessageId);

            if (holderListRef?.current && averageDisplayedElement) {
                const height = averageDisplayedElement.offsetHeight;

                scrollBottom.current = holderListRef.current.scrollTop
                - (averageDisplayedElement?.offsetTop || 0)
                - (isEndList ? 0 : height)
                + additionalIndentation;

                isDisabledScroll.current = true;
            }
        }
    }, [isIOS, isSafari, listData]);

    const getIndexStartMessageScrollBottom = useCallback(
        (index: number) => {
            const validIndexForEndList = Math.min(indexStartMes + PAGINATION_LIMIT, index - MAX_DISPLAY_POSTS);
            return Math.max(validIndexForEndList, 0);
        },
        [indexStartMes],
    );

    /**
     * Данная функция высчитывает индекс, который отображает начало отображаемых элементов
     * После того как индекс будет высчитан, что бы не было скачка на странице, функция
     * высчитывает положение, на котором должен остаться скролл
     */
    const adjustScrollForNewNextMessages = useCallback(() => {
        if (!holderListRef.current) {
            return;
        }

        const averageDisplayedMessageIndex = indexStartMes >= PAGINATION_LIMIT
            ? indexStartMes + PAGINATION_LIMIT
            : MAX_DISPLAY_POSTS;
        const averageDisplayedMessageId = listData[averageDisplayedMessageIndex]?.id?.toString();
        const averageDisplayedElement = document.getElementById(averageDisplayedMessageId);

        scrollHeight.current = averageDisplayedElement?.offsetTop || holderListRef.current.scrollHeight;
        scrollTop.current = holderListRef?.current.scrollTop;
        setIndexStartMes(Math.max(indexStartMes - PAGINATION_LIMIT, 0));
    }, [indexStartMes, listData]);

    /**
     * Данная функция отрабатывает только на IOS и сафари, так как при прокрутке вниз и добавления новых элементов,
     * именно на данных платформах скролл прыгает в самый низ
     * Она высчитывает положения последнего элемента, на котором остановился скролл и после проскроливается к нему,
     * что бы не было скачка
     */
    const adjustScrollForNewPrevMessagesIosOrSafari = useCallback(() => {
        if (isIOS || isSafari) {
            const isEndList = listData.length - (indexStartMes + MAX_DISPLAY_POSTS) <= PAGINATION_LIMIT;
            const averageDisplayedMessageIndex = isEndList
                ? listData.length - MAX_DISPLAY_POSTS
                : PAGINATION_LIMIT - 1 + indexStartMes;
            const additionalIndentation = isEndList ? 40 : 50;
            // Данная переменная нужна, так как мы еще должны считать отступы выше стоящих элементов

            scrollBottomForSafariAndIos(averageDisplayedMessageIndex, additionalIndentation, isEndList);
        }
    }, [indexStartMes, listData, isIOS, isSafari, scrollBottomForSafariAndIos]);

    const addNewNextMessages = useCallback(() => {
        if (indexStartMes !== 0) {
            onAdjustScroll?.();
            adjustScrollForNewNextMessages();
            return;
        }

        handleLoadNewNextMessages();
    }, [
        indexStartMes,
        adjustScrollForNewNextMessages,
        handleLoadNewNextMessages,
        onAdjustScroll,
    ]);

    const addNewPrevMessage = useCallback(() => {
        const messageLastIndex = listData.length;
        const isChangeIndexStartMes = indexStartMes < messageLastIndex - MAX_DISPLAY_POSTS;
        if (isChangeIndexStartMes) {
            onAdjustScroll?.();
            adjustScrollForNewPrevMessagesIosOrSafari();

            const index = getIndexStartMessageScrollBottom(messageLastIndex);
            setIndexStartMes(index);
            return;
        }

        handleLoadPrevNextMessages();
    }, [
        indexStartMes,
        adjustScrollForNewPrevMessagesIosOrSafari,
        handleLoadPrevNextMessages,
        onAdjustScroll,
    ]);

    const handleScroll = useCallback(() => {
        if (!holderListRef.current || isDisabledScroll.current || isDisabledScrollState) {
            return;
        }

        const isScrollTopEnd = holderListRef.current.scrollTop === 0;
        const isScrollBottomEnd = holderListRef.current.scrollHeight - holderListRef.current.scrollTop
        === holderListRef.current.clientHeight;
        const isShowButtonScrollEnd = holderListRef.current.clientHeight + holderListRef.current.scrollTop
        < holderListRef.current.scrollHeight - HEIGHT_DOWN_ARROW_DISPLAY;

        if (isScrollTopEnd) {
            addNewNextMessages();
        }

        if (isScrollBottomEnd) {
            addNewPrevMessage();
        }

        setIsScrollEndList?.(isShowButtonScrollEnd);
    }, [addNewNextMessages, addNewPrevMessage, isDisabledScrollState]);

    const changeScrollTop = useCallback((lengthNewPosts: number) => {
        const averageDisplayedMessageIndex = lengthNewPosts === PAGINATION_LIMIT
            ? PAGINATION_LIMIT
            : MAX_DISPLAY_POSTS - lengthNewPosts;
        const averageDisplayedMessageId = listData[averageDisplayedMessageIndex]?.id?.toString();
        const averageDisplayedElement = document.getElementById(averageDisplayedMessageId);
        if (holderListRef?.current) {
            scrollHeight.current = averageDisplayedElement?.offsetTop || holderListRef?.current.scrollHeight;
            scrollTop.current = holderListRef?.current.scrollTop;
        }
    }, [listData]);

    const changeScrollBottom = useCallback((lengthNewPosts: number) => {
        scrollHeight.current = 0;
        scrollTop.current = 0;

        if (isIOS || isSafari) {
            const scrollBottomIndex = listData.length
                - (lengthNewPosts === PAGINATION_LIMIT ? PAGINATION_LIMIT : MAX_DISPLAY_POSTS - lengthNewPosts);
            scrollBottomForSafariAndIos(scrollBottomIndex, 48, true);
        }
    }, [isIOS, isSafari, listData, scrollBottomForSafariAndIos]);

    useEffect(() => {
        const container = holderListRef.current;
        if (container) {
            // Добавляем слушателя на скролл
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll, listData]);

    useEffect(() => {
        if (!isDisabledScrollState) {
            handleScroll();
        }
    }, [isDisabledScrollState]);

    useEffect(() => {
        // При добавлении новых элементов мы обновляем положение нашего скролла, что бы не было скачка

        if (scrollHeight.current && holderListRef.current) { // Обновляем положение при скролле вверх
            holderListRef.current.scrollTop = (holderListRef.current.scrollHeight - scrollHeight.current) + scrollTop.current;
            scrollHeight.current = 0;
            scrollTop.current = 0;
            scrollBottom.current = 0;
        }

        if (scrollBottom.current && holderListRef.current) { // Обновляем положение при скролле вниз
            holderListRef.current.scrollTop = scrollBottom.current;
            scrollBottom.current = 0;
            isDisabledScroll.current = false;
        }
    }, [displayListData]);

    return {
        changeScrollBottom,
        changeScrollTop,
        getIndexStartMessageScrollBottom,
        handleScrollBottom,
        isDisabledScroll,
    };
};
