// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z4ocS0ZO{width:220px;object-fit:contain}.z4ocS0ZO video{width:100%}.bWDrGucJ{width:220px;height:180px}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/variants/video-sticker/VideoSticker.module.scss"],"names":[],"mappings":"AAAA,UACI,WAAA,CACA,kBAAA,CAEA,gBACI,UAAA,CAIR,UACI,WAAA,CACA,YAAA","sourcesContent":[".videoSticker {\n    width: 220px;\n    object-fit: contain;\n\n    video {\n        width: 100%;\n    }\n}\n\n.skeleton {\n    width: 220px;\n    height: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoSticker": `z4ocS0ZO`,
	"skeleton": `bWDrGucJ`
};
export default ___CSS_LOADER_EXPORT___;
