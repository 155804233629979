import {
    FC,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { IChannel } from 'entities/channels';
import { getStateIsChannelEdition } from 'entities/user';
import { CREATE_CHANNEL_URL } from 'shared/constants/router';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './AddChannelButton.module.scss';

interface IAddChannelButtonProps {
    className?: string;
    isLoadingChannels: boolean;
    channels?: IChannel[];
    searchValue?: string;
}

export const AddChannelButton: FC<IAddChannelButtonProps> = (props) => {
    const {
        channels = [], className, isLoadingChannels, searchValue,
    } = props;

    const isChannelEditionRight = useSelector(getStateIsChannelEdition);

    const typeBtn = useMemo(() => {
        if (searchValue) return 'default';

        return !isLoadingChannels && channels.length === 0 ? 'primary' : 'default';
    }, [isLoadingChannels, channels, searchValue]);

    if (!isChannelEditionRight) {
        return null;
    }

    return (
        <div className={classNames(cls.addChannelButton, {}, [className])}>
            <Link to={CREATE_CHANNEL_URL}>
                <Button
                    block
                    type={typeBtn}
                    size="large"
                    icon={<PlusOutlined />}
                >
                    Добавить канал
                </Button>
            </Link>
        </div>
    );
};
