import { CreateVariable } from './CreateVariable';
import { EditVariable } from './EditVariable';

interface IVariableActionProps {
    isNewVar?: boolean;
    createVariable: () => void;
    updateVariable: () => void;
    revertChanges: () => void;
    newVariableName: string;
    newVariableValue : string;
    errorName: string;
}

export const VariableAction: React.FC<IVariableActionProps> = (props) => {
    const {
        createVariable, errorName, isNewVar, newVariableName, newVariableValue, revertChanges,
        updateVariable,
    } = props;

    return isNewVar
        ? (
            <CreateVariable
                newVariableName={newVariableName}
                createVariable={createVariable}
                newVariableValue={newVariableValue}
                errorName={errorName}
            />
        )
        : (
            <EditVariable
                newVariableValue={newVariableValue}
                revertChanges={revertChanges}
                updateVariable={updateVariable}
                newVariableName={newVariableName}
            />
        );
};
