import { FC } from 'react';

import { DocumentFile } from 'shared/ui/document/DocumentFile';

import { IFile } from '../../../model/types/message.types';

interface IDocumentMessageProps {
    file: IFile;
}

export const DocumentMessage: FC<IDocumentMessageProps> = (props) => {
    const { file } = props;

    const isNotLoaded = !!file?.file;

    const handleClick = () => window.open(file?.file, '_blank');

    return (
        <DocumentFile
            isLoaded={isNotLoaded}
            fileName={file.name}
            size={file.fileSize}
            onClick={handleClick}
        />
    );
};
