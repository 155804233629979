// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bcx1Bwdr{cursor:pointer;padding:var(--p-1);margin-left:calc(var(--p-1)*-1);outline:none;border:0;background:none}.bcx1Bwdr.jJQXq_oZ .hXLVzERN{-webkit-transform:rotate(-45deg) translate(-4.1px, 4px);transform:rotate(-45deg) translate(-4.1px, 4px);height:2px}.bcx1Bwdr.jJQXq_oZ .BMZX9niD{opacity:0}.bcx1Bwdr.jJQXq_oZ .tn03qi5I{-webkit-transform:rotate(45deg) translate(-4.5px, -4.3px);transform:rotate(45deg) translate(-4.5px, -4.3px);width:15px;height:2px}.hXLVzERN,.BMZX9niD,.tn03qi5I{width:15px;height:1.7px;background-color:var(--black-2);margin:var(--p-0-5) 0;transition:.4s}.tn03qi5I{width:11px}`, "",{"version":3,"sources":["webpack://./src/features/header-menu-tablet/ui/elements/burger-menu/BurgerMenu.module.scss"],"names":[],"mappings":"AAEA,UACI,cAAA,CACA,kBAAA,CACA,+BAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA,CAEA,6BACI,uDAAA,CACA,+CAAA,CACA,UAAA,CAGJ,6BACI,SAAA,CAGJ,6BACI,yDAAA,CACA,iDAAA,CACA,UAAA,CACA,UAAA,CAIR,8BAGI,UAAA,CACA,YAAA,CACA,+BAAA,CACA,qBAAA,CACA,cAAA,CAGJ,UACI,UAAA","sourcesContent":["@use 'app/styles/mixins/mixins';\n\n.menuButton {\n    cursor: pointer;\n    padding: var(--p-1);\n    margin-left: calc(var(--p-1) * -1);\n    outline: none;\n    border: 0;\n    background: none;\n\n    &.open .bar1 {\n        -webkit-transform: rotate(-45deg) translate(-4.1px, 4px);\n        transform: rotate(-45deg) translate(-4.1px, 4px);\n        height: 2px;\n    }\n\n    &.open .bar2 {\n        opacity: 0;\n    }\n\n    &.open .bar3 {\n        -webkit-transform: rotate(45deg) translate(-4.5px, -4.3px);\n        transform: rotate(45deg) translate(-4.5px, -4.3px);\n        width: 15px;\n        height: 2px;\n    }\n}\n\n.bar1,\n.bar2,\n.bar3 {\n    width: 15px;\n    height: 1.7px;\n    background-color: var(--black-2);\n    margin: var(--p-0-5) 0;\n    transition: 0.4s;\n}\n\n.bar3 {\n    width: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `bcx1Bwdr`,
	"open": `jJQXq_oZ`,
	"bar1": `hXLVzERN`,
	"bar2": `BMZX9niD`,
	"bar3": `tn03qi5I`
};
export default ___CSS_LOADER_EXPORT___;
