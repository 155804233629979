/* eslint-disable max-len */
export const FAKE_TITLE_TEXT = 'Lorem ipsum dolor sit amet consec tetur. Sit lorem ac ornare dui sed nibh orci. Enim feugiat ut';

export const HINT_DATE_FIRST_PUBLICATION = 'Установите дату выхода первого поста на канале';

export const HINT_WHO_WILL_POST_CHANNEL = 'Введите токен бота. Его можно получить в @BotFather';

export const HINT_DONOR_CHANNEL = 'Введите пригласительную ссылку канала для импорта постов';

export const HINT_ID_CHANNEL = 'Введите ID канала. Его можно получить, переслав сообщение со своего канала в @getmyid_bot';

export const HINT_VARIABLES = 'Переменные можно использовать для подстановки в текст поста/кнопку часто используемых значений, например [ссылканатовар]';
