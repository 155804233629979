import {
    FC,
    memo,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Link,
    useNavigate,
} from 'react-router-dom';

import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import {
    UserHeaderInfo,
    getUserAuth,
} from 'entities/user';
import { LogoIcon } from 'shared/assets/components/LogoIcon';
import {
    AUTH_URL,
    DOCUMENTATION_URL,
    EMPLOYEE_MANAGEMENT_URL,
    HOME_URL,
} from 'shared/constants/router';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './Header.module.scss';

interface IHeaderProps {
    className?: string;
    isTransparent: boolean;
}

export const Header: FC<IHeaderProps> = memo((props) => {
    const { className = '', isTransparent } = props;
    const userAuth = useSelector(getUserAuth);

    const navigate = useNavigate();

    const openEmployeePage = () => {
        navigate(EMPLOYEE_MANAGEMENT_URL);
    };
    const openMessagesPage = () => {
        navigate(HOME_URL);
    };
    const openDocumentationPage = () => {
        navigate(DOCUMENTATION_URL);
    };

    if (isTransparent) {
        return (
            <header className={cls.headerTransparent}>
                <Link to={userAuth ? HOME_URL : AUTH_URL}>
                    <LogoIcon className={cls.logoDark} />
                </Link>
            </header>
        );
    }

    return (
        <header className={classNames(cls.header, {}, [className])}>
            <Link to={HOME_URL}>
                <LogoIcon className={cls.logo} />
            </Link>

            <div className={cls.rightBlock}>
                <button type="button" className={cls.holderIcon} onClick={openEmployeePage}>
                    <TeamOutlined />
                </button>
                <button type="button" className={cls.holderIcon} onClick={openMessagesPage}>
                    <MessageOutlined />
                </button>
                <button type="button" className={cls.holderIcon} onClick={openDocumentationPage}>
                    <FileTextOutlined />
                </button>

                <div className={cls.line} />

                <UserHeaderInfo />
            </div>
        </header>
    );
});
