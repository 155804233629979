import {
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './BurgerMenu.module.scss';

interface IBurgerMenuProps {
    handleToggleSidebar?: (value: boolean) => void;
    isSidebarOpen: boolean;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export const BurgerMenu: FC<IBurgerMenuProps> = (props) => {
    const { handleToggleSidebar, isSidebarOpen, setSidebarOpen } = props;

    const toggleSidebar = () => {
        setSidebarOpen((prev) => {
            handleToggleSidebar?.(!prev);
            return !prev;
        });
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isSidebarOpen]);

    return (
        <button
            type="button"
            className={classNames(cls.menuButton, { [cls.open]: isSidebarOpen })}
            onClick={toggleSidebar}
        >
            <div className={cls.bar1} />
            <div className={cls.bar2} />
            <div className={cls.bar3} />
        </button>
    );
};
