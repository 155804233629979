import {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
} from 'react';

import useBrowserInfo from '../useBrowserInfo/useBrowserInfo';
import useDeviceDetect from '../useDeviceDetect/useDeviceDetect';

interface IUseInitialVideoForIOSProps {
    videoRef: RefObject<HTMLVideoElement>;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    handleInitVideo?: () => void;
}

const TIME_INITIAL_VIDEO = 200;

export const useInitialVideoForAppleDevice = (props: IUseInitialVideoForIOSProps) => {
    const { setIsLoading, videoRef } = props;

    const { isIOS, isMacintosh } = useDeviceDetect();
    const { isSafari } = useBrowserInfo();

    const initVideoSettings = () => {
        const videoPlayer = videoRef.current;

        if (videoPlayer) {
            videoPlayer?.play();
            setTimeout(() => {
                videoPlayer?.pause();
                videoPlayer.currentTime = 0.1;
                setIsLoading(false);
            }, TIME_INITIAL_VIDEO);
        }
    };

    useEffect(() => {
        const videoPlayer = videoRef.current;
        const isLoadVideo = videoPlayer && (isIOS || (isSafari && isMacintosh));

        if (isLoadVideo) {
            videoPlayer.addEventListener('loadedmetadata', initVideoSettings, false);
            videoPlayer.addEventListener('canplaythrough', initVideoSettings, false);
        }

        return () => {
            if (isLoadVideo) {
                videoPlayer.removeEventListener('loadedmetadata', initVideoSettings, false);
                videoPlayer.removeEventListener('canplaythrough', initVideoSettings, false);
            }
        };
    }, [isIOS, isMacintosh, isSafari, videoRef]);
};
