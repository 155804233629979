export enum EImportingStatuses {
    REFRESHED = 'REFRESHED',
    PENDING = 'IN_PROGRESS',
    FAILURE = 'FAILURE',
    FINISHED = 'FINISHED',
}

export enum EHintUpdatePost {
    UPDATED = 'updated',
    ERROR = 'error',
}

export interface IResponseImportingStatus {
    status: EImportingStatuses;
}
