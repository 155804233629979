import { FC } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './DocumentFile.module.scss';

interface IDocumentFileProps {
    className?: string;
    fileName: string;
    size?: number | string;
    onClick?: () => void;
    onDelete?: () => void;
    isLoaded?: boolean;
}

export const DocumentFile: FC<IDocumentFileProps> = (props) => {
    const {
        className, fileName, isLoaded = true, onClick, onDelete, size,
    } = props;
    const fileSize = `${((Number(size) || 0) / 1024).toFixed(1)}KB`;

    const handleClickDoc = () => {
        if (isLoaded) {
            onClick?.();
        }
    };

    return (
        <button
            onClick={handleClickDoc}
            type="button"
            className={classNames(cls.document, { [cls.withoutCursor]: !isLoaded }, [className])}
        >
            <FileTextOutlined className={cls.docIcon} />
            <div className={cls.docInfo}>
                <p className={cls.docTitle}>{fileName}</p>
                <p className={cls.docSize}>
                    {size ? fileSize : 'Загрузка...'}
                </p>
            </div>
            {onDelete ? <DeleteOutlined onClick={onDelete} className={cls.deleteIcon} /> : null}
        </button>
    );
};
