/* eslint-disable max-len */
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    EScrollTypes,
} from 'entities/message';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { getQueryVariable } from 'shared/lib/utils/validUrl/validUrl';
import { IBackendListResponse } from 'shared/types/types';

import {
    getNextUrlNotification,
    getPreviousUrlNotification,
} from '../model/selectors/notifications.selectors';
import {
    getNotificationsFromSlice,
    notificationsActions,
} from '../model/slice/notifications.slice';
import {
    INotification,
    INotificationSchema,
} from '../model/types/notification.types';

interface IUseNotificationHandlerProps {
    channelId: string;
    isLoading: boolean;
    isFetching: boolean;
    notificationFromServer?: IBackendListResponse<INotification>;
    setQueryCursor: Dispatch<SetStateAction<string>>;
    queryCursor: string;
    setEmptyNotifications: Dispatch<SetStateAction<boolean>>;
}
interface IUseNotificationHandlerReturn {
    addNewNextNotifications: (atTop: boolean) => void;
    addNewPrevNotifications: (alBottom: boolean) => void;
    notifications: INotification[];
}

export const useNotificationHandler = (props: IUseNotificationHandlerProps): IUseNotificationHandlerReturn => {
    const {
        channelId, isFetching, isLoading, notificationFromServer, queryCursor, setEmptyNotifications, setQueryCursor,
    } = props;

    const dispatch = useAppDispatch();
    const notifications = useSelector(getNotificationsFromSlice.selectAll);
    const nextUrlNotification = useSelector(getNextUrlNotification);
    const previousUrlNotification = useSelector(getPreviousUrlNotification);

    const [typeScroll, setTypeScroll] = useState<EScrollTypes>(EScrollTypes.TOP);

    const changeNextOrPrevUrl = useCallback(
        ({
            next,
            previous,
        }: {
            next?: INotificationSchema['nextUrlNotification'];
            previous?: INotificationSchema['previousUrlNotification'];
        }) => {
            if (next !== undefined) {
                dispatch(notificationsActions.updateNextUrlNotification(next));
            }
            if (previous !== undefined) {
                dispatch(notificationsActions.updatePreviousUrlNotification(previous));
            }
        },
        [],
    );

    const addNewNextNotifications = useCallback(
        (atTop: boolean) => {
            const isShouldAddNextMessages = nextUrlNotification && !isLoading && atTop;

            if (isShouldAddNextMessages) {
                const nextPage = nextUrlNotification.replace(/.*\?/gi, '');
                const cursor = getQueryVariable(nextPage).cursor || '';
                setTypeScroll(EScrollTypes.BOTTOM);
                setQueryCursor(cursor);
            }
        },
        [nextUrlNotification, isLoading],
    );

    const addNewPrevNotifications = useCallback(
        (alBottom: boolean) => {
            const isShouldAddPrevNotifications = previousUrlNotification && !isLoading && alBottom;

            if (isShouldAddPrevNotifications) {
                const prevPage = previousUrlNotification.replace(/.*\?/gi, '');
                const cursor = getQueryVariable(prevPage).cursor || '';
                setTypeScroll(EScrollTypes.TOP);
                setQueryCursor(cursor);
            }
        },
        [previousUrlNotification, isLoading],
    );

    useEffect(() => {
        const isAddNotifications = channelId
            && notificationFromServer
            && notificationFromServer?.results?.length > 0
            && !isFetching
            && !isLoading
            && queryCursor;

        if (isAddNotifications) {
            dispatch(notificationsActions.addNotifications(notificationFromServer.results));

            changeNextOrPrevUrl({
                next: typeScroll === EScrollTypes.BOTTOM ? notificationFromServer.next : undefined,
                previous: typeScroll === EScrollTypes.TOP ? notificationFromServer.previous : undefined,
            });
        }
    }, [notificationFromServer, isFetching, isLoading, queryCursor]);

    useEffect(() => {
        const isSetNotifications = channelId
            && notificationFromServer
            && !isFetching
            && !isLoading
            && !queryCursor;

        if (isSetNotifications) {
            dispatch(notificationsActions.setNotifications(notificationFromServer.results));
            dispatch(notificationsActions.updateNextUrlNotification(notificationFromServer.next));
            dispatch(notificationsActions.updatePreviousUrlNotification(notificationFromServer.previous));

            setEmptyNotifications(notificationFromServer.results.length === 0);
        }
    }, [channelId, notificationFromServer, isFetching, isLoading, queryCursor]);

    useEffect(() => {
        if (channelId) {
            setQueryCursor('');
        }
    }, [channelId]);

    return {
        addNewNextNotifications,
        addNewPrevNotifications,
        notifications,
    };
};
