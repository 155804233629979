import {
    FC,
    memo,
} from 'react';

import { Modal } from 'antd';

import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { IVariable } from 'entities/channels';
import { EModalWidth } from 'shared/constants/modal-sizes';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Flex } from 'shared/ui/flex/Flex';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { Text } from 'shared/ui/text/Text';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './VariablesModal.module.scss';

interface IVariablesModalProps {
    variables?: IVariable[];
    onCancel: () => void;
    isVisible: boolean;
    onClickVariable: (variable: IVariable) => void;
}

export const VariablesModal: FC<IVariablesModalProps> = memo((props) => {
    const {
        isVisible, onCancel, onClickVariable,
        variables,
    } = props;

    const { isMobile } = useScreenType();

    const handleVariableClick = (variable: IVariable) => () => {
        onClickVariable(variable);
    };

    if (isMobile && isVisible) {
        return (
            <FullscreenContainer className={cls.fullscreen}>
                <DynamicHeader
                    className={cls.header}
                    leftBlock={(
                        <WrappedIcon size="20" onClick={onCancel} Icon={LeftOutlined} className={cls.backIcon} />
                    )}
                    title="Выбор переменной"
                />
                <Flex className={cls.variablesWrapper} direction="column" align="start" max>
                    {variables?.map((variable) => (
                        <Flex
                            key={variable.name}
                            onClick={handleVariableClick(variable)}
                            className={cls.variableRow}
                            direction="column"
                            align="start"
                            max
                        >
                            <p className={cls.variableValue}>{variable.value}</p>
                            <p className={cls.variableName}>{`[${variable.name}]`}</p>
                        </Flex>
                    ))}
                    {variables && variables.length === 0 && (
                        <Text
                            className={cls.noVariables}
                            theme="gray"
                            align="center"
                            text="Нет созданных переменных"
                            max
                        />
                    )}
                </Flex>
            </FullscreenContainer>
        );
    }

    return (
        <Modal
            footer={[]}
            title="Выбор переменной "
            open={isVisible}
            onCancel={onCancel}
            width={EModalWidth.width400}
            centered
        >
            {variables && (
                <div className={cls.variables}>
                    <Flex className={cls.headerTable}>
                        <span>Переменная</span>
                        <span>Значение</span>
                    </Flex>
                    <div className={cls.variablesWrapper}>
                        {variables.map((variableItem) => (
                            <Flex
                                onClick={handleVariableClick(variableItem)}
                                key={variableItem.name}
                                gaps="4"
                                className={cls.variableRow}
                            >
                                <span className={cls.variableName}>{`[${variableItem.name}]`}</span>
                                <span className={cls.variableValue}>{variableItem.value}</span>
                            </Flex>
                        ))}
                        {variables.length === 0 && (
                            <Text
                                className={cls.noVariables}
                                theme="gray"
                                align="center"
                                text="Нет созданных переменных"
                            />
                        )}
                    </div>
                </div>
            ) }
        </Modal>
    );
});
