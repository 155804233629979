import { FC } from 'react';

import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './SkeletonChannel.module.scss';

export const SkeletonChannel: FC = () => (
    <div className={cls.skeletonLoader}>
        <Skeleton className={cls.skeletonLoaderContent} width="54px" height="53px" border="50%" />
        <div className={cls.blockTextSkeleton}>
            <Skeleton className={cls.titleSkeleton} width="50%" height="16px" />
            <Skeleton width="100%" height="14px" />
        </div>
    </div>
);
