import {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    IGetMessagesSearchArgs,
    ISearchMessage,
    messagesActions,
    useLazyGetMessagesSearchQuery,
} from 'entities/message';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useDebounce } from 'shared/lib/hooks/useDebounce/useDebounce';
import { consoleDev } from 'shared/lib/utils/consoleDev/consoleDev';
import { getQueryVariable } from 'shared/lib/utils/validUrl/validUrl';

interface IUseSearchMessageProps {
    setShow: (value: boolean) => void;
    channelId: string;
    handleShowMessage: (id: string, page?: string, behaviorScroll?: EBehaviorScroll) => void;
    show: boolean;
}

interface IUseSearchMessageReturn {
    dateSearch?: string;
    handleChangeDate: (value?: string) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    handleClearSearch: () => void;
    handleCloseSearchContent: () => void;
    handleDownSeachMessage: () => void;
    handleUpSeachMessage: () => void;
    search: string;
    disabledDownArrow: boolean;
    disabledUpArrow: boolean;
    isLoading: boolean;
}

const DEBOUNCE_DELAY = 500;

export const useSearchMessage = (props: IUseSearchMessageProps): IUseSearchMessageReturn => {
    const {
        channelId, handleShowMessage, setShow, show,
    } = props;
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [dateSearch, setDateSearch] = useState<string>();
    const [countIndexSearchPosts, setCountIndexSearchPosts] = useState<number | null>(null);
    const [countAllSearchPosts, setCountAllSearchPosts] = useState<number | null>(null);
    const [cursorSearchArray, setCursorSearchArray] = useState<ISearchMessage[]>([]);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [cursorNextSearch, setCursorNextSearch] = useState('');
    const [totalCountSearch, setTotalCountSearch] = useState(0);

    const [searchMessage, { isFetching, isLoading }] = useLazyGetMessagesSearchQuery();

    const disabledUpArrow = useMemo(
        () => isLoading
            || isFetching
            || !search
            || typeof countIndexSearchPosts !== 'number'
            || countIndexSearchPosts >= totalCountSearch - 1
            || isLoadingSearch,
        [
            totalCountSearch,
            countIndexSearchPosts,
            search,
            isLoading,
            isFetching,
            isLoadingSearch,
        ],
    );

    const disabledDownArrow = useMemo(
        () => isLoading
            || isFetching
            || !search
            || typeof countIndexSearchPosts !== 'number'
            || countIndexSearchPosts === 0
            || isLoadingSearch,
        [
            search,
            countIndexSearchPosts,
            isLoading,
            isFetching,
            isLoadingSearch,
        ],
    );

    const handleLoadSearchMessage = useCallback(
        async (params: IGetMessagesSearchArgs) => {
            try {
                const { data } = await searchMessage({ ...params });
                const results = data?.results as ISearchMessage[];
                const cursorNext = data?.next ? getQueryVariable(data.next.replace(/.*\?/gi, '')).cursor : '';
                const totalCount = data?.totalCount || 0;

                setCursorSearchArray((prev) => {
                    const updateArray = [...prev, ...results];
                    const lastIndex = (updateArray.length || 1) - 1;
                    setCountAllSearchPosts(lastIndex); // обновляем кол-во всех найденых элементов
                    setCountIndexSearchPosts(results.length > 0 ? prev.length : null);

                    return [...prev, ...results];
                });

                setCursorNextSearch(cursorNext);
                setTotalCountSearch(totalCount);

                const firstSearchMessage = results?.[0]; // находим первое сообщение

                if (firstSearchMessage.id && firstSearchMessage.cursorLink) {
                    handleShowMessage(firstSearchMessage.id, firstSearchMessage.cursorLink, EBehaviorScroll.AUTO);
                    // переходим к первому найденому сообщению
                }
            } catch (error) {
                consoleDev(error as Error);
            } finally {
                setIsLoadingSearch(false);
            }
        },
        [handleShowMessage],
    );

    const handleLoadDateMessage = useCallback(
        async (params: IGetMessagesSearchArgs) => {
            try {
                const { data } = await searchMessage({ ...params });
                const results = data?.results as ISearchMessage[];
                const lastIndex = (results?.length || 1) - 1;

                const lastSearchMessage = results?.[lastIndex]; // находим последее сообщение

                if (lastSearchMessage.id && lastSearchMessage.cursorLink) {
                    handleShowMessage(results[lastIndex].id, results[lastIndex].cursorLink, EBehaviorScroll.AUTO);
                    // переходим к последнему найденому сообщению
                }
            } catch (error) {
                consoleDev(error as Error);
            }
        },
        [handleShowMessage],
    );

    const handleChangeSearchState = useDebounce(
        useCallback(
            (value: string) => {
                if (value) {
                    // так как обновляем поиск, обнуляем все стейты и сейтим новое значение поиска
                    setCountIndexSearchPosts(null);
                    setCountAllSearchPosts(null);
                    setCursorSearchArray([]);
                    setCursorNextSearch('');
                    setTotalCountSearch(0);
                    handleLoadSearchMessage({ channelId, search: value });
                }
            },
            [channelId, handleLoadSearchMessage],
        ),
        DEBOUNCE_DELAY,
    );

    const handleChangeSearch = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            if (value.trim()) {
                setIsLoadingSearch(true);
            }

            setSearch(value);
            handleChangeSearchState(value.trim());
        },
        [channelId],
    );

    const handleChangeDate = useCallback(
        (value?: string) => {
            setDateSearch(value);

            if (value) {
                dispatch(messagesActions.setScrollMessageId(null));
                handleLoadDateMessage({ channelId, date: value });
            }
        },
        [channelId, handleLoadDateMessage],
    );

    const handleClearSearch = useCallback(() => {
        setSearch('');
        setCountIndexSearchPosts(null);
        setCountAllSearchPosts(null);
        setCursorSearchArray([]);
        setCursorNextSearch('');
        setTotalCountSearch(0);
    }, []);

    const handleUpSeachMessage = useCallback(() => {
        // так как используем клик на иконке, проверяем, можем ли мы нажать на кнопку
        if (disabledUpArrow || typeof countIndexSearchPosts !== 'number') {
            return;
        }

        const isLoadSearchPost = countAllSearchPosts === countIndexSearchPosts
        && countIndexSearchPosts !== totalCountSearch
        && cursorNextSearch;

        if (isLoadSearchPost) {
            setIsLoadingSearch(true);
            handleLoadSearchMessage({ channelId, cursor: cursorNextSearch, search });
            return;
        }

        dispatch(messagesActions.setIsDisabledScroll(true));
        dispatch(messagesActions.setScrollMessageId(null));

        const newCountIndex = countIndexSearchPosts + 1; // находим индекс следующего сообщение по списку
        const showSearchMessage = cursorSearchArray[newCountIndex]; // находим следующее сообщение по списку

        setCountIndexSearchPosts((prev) => {
            if (showSearchMessage?.id) {
                return newCountIndex; // менямем индекс на новый
            }

            return prev;
        });

        handleShowMessage(showSearchMessage.id, showSearchMessage.cursorLink, EBehaviorScroll.AUTO);
        // переходим к следующему сообщению
    }, [
        countIndexSearchPosts,
        countAllSearchPosts,
        disabledUpArrow,
        handleShowMessage,
        cursorNextSearch,
        search,
        channelId,
        totalCountSearch,
        cursorSearchArray,
    ]);

    const handleDownSeachMessage = useCallback(() => {
        // так как используем клик на иконке, проверяем, можем ли мы нажать на кнопку
        if (disabledDownArrow || typeof countIndexSearchPosts !== 'number') {
            return;
        }

        dispatch(messagesActions.setIsDisabledScroll(true));
        dispatch(messagesActions.setScrollMessageId(null));

        const newCountIndex = countIndexSearchPosts - 1; // находим индекс предыдущего сообщение по списку
        const showSearchMessage = cursorSearchArray[newCountIndex]; // находим предыдущее сообщение по списку

        setCountIndexSearchPosts((prev) => {
            if (showSearchMessage?.id) {
                // переходим к предыдущему сообщению
                return newCountIndex; // менямем индекс на новый
            }

            return prev;
        });

        handleShowMessage(showSearchMessage.id, showSearchMessage.cursorLink, EBehaviorScroll.AUTO);
    }, [
        search,
        countIndexSearchPosts,
        handleShowMessage,
        disabledDownArrow,
        cursorSearchArray,
    ]);

    const handleCloseSearchContent = useCallback(() => {
        // при закрытии должны сбросить все состояния
        setShow(false);
        handleChangeDate(undefined);
        handleClearSearch();
    }, [handleChangeDate]);

    useEffect(() => {
        if (!show) {
            handleCloseSearchContent();
        }
    }, [show]);

    return {
        dateSearch,
        disabledDownArrow,
        disabledUpArrow,
        handleChangeDate,
        handleChangeSearch,
        handleClearSearch,
        handleCloseSearchContent,
        handleDownSeachMessage,
        handleUpSeachMessage,
        isLoading: isLoading || isFetching || isLoadingSearch,
        search,
    };
};
