import React, {
    FC,
} from 'react';

import { Dayjs } from 'dayjs';

import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import { Text } from 'shared/ui/text/Text';

import cls from './HeaderCalendar.module.scss';

interface ICalendarProps {
    value: Dayjs;
    onYearChange: (value: number) => void;
    onMonthChange: (value: number) => void;
}

export const HeaderCalendar: FC<ICalendarProps> = (props) => {
    const {
        onMonthChange,
        onYearChange,
        value,
    } = props;

    return (
        <div className={cls.header}>
            <div className={cls.headerLeftBlock}>
                <DoubleLeftOutlined onClick={() => onYearChange(-1)} className={cls.headerControlIcon} />
                <LeftOutlined onClick={() => onMonthChange(-1)} className={cls.headerControlIcon} />
            </div>
            <Text text={value.format('MMM YYYY')} weight="600" />
            <div className={cls.headerRightBlock}>
                <RightOutlined onClick={() => onMonthChange(1)} className={cls.headerControlIcon} />
                <DoubleRightOutlined onClick={() => onYearChange(1)} className={cls.headerControlIcon} />
            </div>
        </div>
    );
};
