import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { ERightsUser } from 'entities/user';
import UsersEditIcon from 'shared/assets/icons/usersEdit.svg';
import {
    DOCUMENTATION_URL,
    EMPLOYEE_MANAGEMENT_URL,
    HOME_URL,
    ROLES_MANAGEMENT_URL,
} from 'shared/constants/router';

import { INavbarInfo } from '../types/header-menu';

export const NAVBAR_ARRAY: INavbarInfo[] = [
    {
        icon: <MessageOutlined />,
        title: 'Каналы',
        url: HOME_URL,
    },
    {
        icon: <TeamOutlined />,
        title: 'Сотрудники',
        url: EMPLOYEE_MANAGEMENT_URL,
    },
    {
        accessRight: ERightsUser.IS_ROLE_EDITION,
        icon: <UsersEditIcon />,
        title: 'Роли',
        url: ROLES_MANAGEMENT_URL,
    },
    {
        icon: <FileTextOutlined />,
        title: 'Документация',
        url: DOCUMENTATION_URL,
    },
];
