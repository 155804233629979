import {
    Dispatch,
    FC,
    memo,
    SetStateAction,
    useCallback,
    useRef,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import 'dayjs/locale/ru';

import { Button } from 'antd';

import DownOutlined from '@ant-design/icons/DownOutlined';
import {
    IVariable,
    IChannel,
} from 'entities/channels';
import {
    TMessage,
    getMessagesShouldUpdate,
} from 'entities/message';
import { DeleteMessageModal } from 'features/delete-message';
import { getAudioFileData } from 'features/global-audio-channel';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IBackendListResponse } from 'shared/types/types';
import { Text } from 'shared/ui/text/Text';

import { useMessageHandler } from '../hooks/useMessageHandler';

import { GroupedMessageList } from './grouped/GroupedMessageList';

import cls from './MessageList.module.scss';

interface IMessageListProps {
    className?: string;
    channelId: string;
    channel?: IChannel;
    onEditMessage: (mes: TMessage) => void;
    messagesFromServer?: IBackendListResponse<TMessage>;
    isLoadingMessages: boolean;
    isFetchingMessages: boolean;
    setQueryParams: Dispatch<SetStateAction<string>>;
    setPrevChannelId: Dispatch<SetStateAction<string>>;
    reloadMessages: Function;
    reloadChannels: Function;
    prevChannelId: string;
    onRemoveMessage: (mesId: string) => void;
    isPinnedMessage: boolean;
    shouldAddMessages: boolean;
    setShouldAddMessages: Dispatch<SetStateAction<boolean>>;
    variables: IVariable[];
    handleClearLastPublishedLoad: (value: boolean) => void;
    isShowFooterMobile: boolean;
}

export const MessageList: FC<IMessageListProps> = memo((props) => {
    const {
        channel,
        channelId,
        className,
        handleClearLastPublishedLoad,
        isFetchingMessages,
        isLoadingMessages,
        isPinnedMessage = false,
        isShowFooterMobile,
        messagesFromServer,
        onEditMessage,
        onRemoveMessage,
        prevChannelId,
        reloadChannels,
        reloadMessages,
        setPrevChannelId,
        setQueryParams,
        setShouldAddMessages,
        shouldAddMessages,
        variables,
    } = props;

    const { isSmallMobile } = useScreenType();

    const fileGlobalAudio = useSelector(getAudioFileData);
    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);
    const holderListRef = useRef<HTMLElement>(null);

    const {
        deleteModalSettings,
        disabledButtonScrollDown,
        groupedArray,
        handleCloseModalOnDelete,
        handleOpenModalOnDelete,
        handleScrollDown,
        isShowButtonScrollDown,
        messages,
        openPreviewVideoOrPhoto,
    } = useMessageHandler({
        channel,
        channelId,
        handleClearLastPublishedLoad,
        holderListRef,
        isFetchingMessages,
        isLoadingMessages,
        messageUpdatingStatus,
        messagesFromServer,
        prevChannelId,
        reloadMessages,
        setPrevChannelId,
        setQueryParams,
        setShouldAddMessages,
        shouldAddMessages,
    });

    const classes = useMemo(() => ({
        [cls.containerWithPinned]: isPinnedMessage,
        [cls.containerWithFooterMobile]: isShowFooterMobile,
        [cls.containerWithPinnedAndFooterMobile]: isPinnedMessage && isShowFooterMobile,
    }), [isPinnedMessage, isShowFooterMobile, isPinnedMessage, isShowFooterMobile]);

    const handleRemoveMessage = useCallback((mesId: string) => {
        onRemoveMessage(mesId);
    }, [onRemoveMessage]);

    const isEmptyMessage = useMemo(
        () => messagesFromServer
            && messagesFromServer.results.length === 0
            && !isLoadingMessages
            && groupedArray.length === 0,
        [messagesFromServer, isLoadingMessages, groupedArray],
    );

    if (isEmptyMessage) {
        return (
            <div className={cls.noMessagesContainer}>
                <Text
                    className={cls.noMessagesTitle}
                    text={(
                        <>
                            На выбранном канале
                            {isSmallMobile ? <br /> : ' '}
                            нет планируемых публикаций
                        </>
                    )}
                    size="md"
                />
            </div>
        );
    }

    return (
        <>
            <section
                className={classNames(
                    cls.container,
                    classes,
                    [className],
                )}
                ref={holderListRef}
            >
                {fileGlobalAudio && <div className={cls.emptyBlockAudio} />}
                <GroupedMessageList
                    channel={channel}
                    onEditMessage={onEditMessage}
                    variables={variables}
                    messages={messages}
                    handleOpenModalOnDelete={handleOpenModalOnDelete}
                    openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                    groupedArray={groupedArray}
                    reloadMessages={reloadMessages}
                    reloadChannels={reloadChannels}
                />
                <DeleteMessageModal
                    isOpen={deleteModalSettings.open}
                    onClose={handleCloseModalOnDelete}
                    messageId={deleteModalSettings.messageId}
                    onDeleteMessage={handleRemoveMessage}
                    importedFrom={deleteModalSettings.importedFrom}
                />

            </section>

            {isShowButtonScrollDown && (
                <Button
                    icon={<DownOutlined className={cls.downIcon} />}
                    rootClassName={classNames(
                        cls.buttonScrollDown,
                        { [cls.buttonScrollDownWithFooterMobile]: isShowFooterMobile },
                    )}
                    size="large"
                    onClick={() => handleScrollDown()}
                    disabled={disabledButtonScrollDown}
                />
            )}
        </>
    );
});
