import { IStateSchema } from 'app/providers/store';

export const getAudioFileData = (state: IStateSchema) => state.audioFile.file;
export const getAudioFileChangedTime = (state: IStateSchema) => state.audioFile.changedTime;
export const getAudioFileIsPlaying = (state: IStateSchema) => state.audioFile.isPlaying;
export const getAudioFileVolume = (state: IStateSchema) => state.audioFile.volume;
export const getAudioMessageId = (state: IStateSchema) => state.audioFile.messageId;
export const getAudioIsEnded = (state: IStateSchema) => state.audioFile.isEnded;
export const getAudioTotalTime = (state: IStateSchema) => state.audioFile.totalTime;
export const getAudioTimeRemaining = (state: IStateSchema) => state.audioFile.timeRemaining;
export const getAudioWidthWave = (state: IStateSchema) => state.audioFile.widthWave;
export const getAudioСhangedTime = (state: IStateSchema) => state.audioFile.changedTime;
export const getAudioIsChangingWidthWave = (state: IStateSchema) => state.audioFile.isChangingWidthWave;
export const getAudioName = (state: IStateSchema) => state.audioFile.name;
export const getAudioDate = (state: IStateSchema) => state.audioFile.date;
export const getIsClearIntervalWidth = (state: IStateSchema) => state.audioFile.isClearIntervalWidth;
