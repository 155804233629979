import { UploadFile } from 'antd';

import {
    EPostTypeMessage,
    ICreateMessageFile,
    IFile,
    TMessage,
} from 'entities/message';
import { VOICE_FILE_TYPE } from 'shared/constants/docs';
import { CustomUploadFile } from 'widgets/create-message';

import {
    EMimeType,
    checkIsMimeType,
} from '../mime-type-checker/checkIsMimeType';

export const isFileLess10MB = (size?: number) => Number(size || 100_000) / 1024 / 1024 < 10;

export const checkIsVoiceFileSafari = (file: UploadFile) => {
    const fileName = file?.name || '';
    const fileNameArray = fileName.split('.');
    const fileType = fileNameArray[fileNameArray.length - 1];
    return fileType === VOICE_FILE_TYPE && file?.type === '';
};

export const checkIsMediaFile = (file: CustomUploadFile) => {
    const isMediaFile = checkIsMimeType(file?.type ?? '', EMimeType.VIDEO)
        || checkIsMimeType(file?.type ?? '', EMimeType.IMAGE)
        || file?.type === EMimeType.IMAGE
        || file?.type === EMimeType.VIDEO;

    const isGif = checkIsMimeType(file?.type ?? '', EMimeType.GIF);

    return isMediaFile && !isGif;
};

export const checkFileType = (file: UploadFile): EMimeType | null => {
    if (checkIsMediaFile(file)) {
        return EMimeType.IMAGE;
    }

    const isGif = checkIsMimeType(file?.type ?? '', EMimeType.GIF);

    if (isGif) {
        return EMimeType.GIF;
    }

    const isFile = checkIsMimeType(file?.type ?? '', EMimeType.APPLICATION);

    if (isFile) {
        return EMimeType.APPLICATION;
    }

    const isVoiceType = checkIsMimeType(file?.type ?? '', EMimeType.VOICE) || checkIsVoiceFileSafari(file);

    if (isVoiceType) {
        return EMimeType.VOICE;
    }

    const isAudio = checkIsMimeType(file?.type ?? '', EMimeType.AUDIO);

    if (isAudio) {
        return EMimeType.AUDIO;
    }

    return null;
};

export const checkSameTypeFiles = (
    firstFile: UploadFile,
    secondFile: UploadFile,
) => checkFileType(firstFile) === checkFileType(secondFile);

export const handleVideoUpload = (file: File) => new Promise((resolve) => {
    // Данная функция подгружает видео файл
    // и проверяте, может ли видео быть видео кружочком
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const width = video.videoWidth;
        const height = video.videoHeight;
        const { duration } = video;

        const isPossibleRoundVideoType = width === height && duration < 60;

        if (video.parentNode) {
            video.parentNode.removeChild(video);
        }

        resolve(isPossibleRoundVideoType);
    };
});

export const checkRoundVideo = async (filesData: UploadFile[]) => {
    const file = filesData[0].originFileObj as File;
    const check = await handleVideoUpload(file);
    return check;
};

export const getValidFilesForSave = (loadedData: (IFile | CustomUploadFile)[], typePost?: EPostTypeMessage, message?: TMessage)
: ICreateMessageFile | { media: ICreateMessageFile[] } => {
    if (typePost === EPostTypeMessage.ALBUM) {
        return {
            media: loadedData.map((el) => ({ fileId: el.id, type: el?.type })),
        };
    }
    if (
        typePost === EPostTypeMessage.ROUND_VIDEO
        || typePost === EPostTypeMessage.VOICE_MESSAGE
        || typePost === EPostTypeMessage.AUDIO_MESSAGE
    ) {
        return { fileId: loadedData[0].id };
    }
    if ((typePost === EPostTypeMessage.ANIMATED_STICKER
        || typePost === EPostTypeMessage.STATIC_STICKER
        || typePost === EPostTypeMessage.VIDEO_STICKER
        || typePost === EPostTypeMessage.CUSTOM_STICKER) && message) {
        return { fileId: message?.post?.file?.id };
    }

    return {};
};
