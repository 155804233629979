import { FC } from 'react';

import avatar from 'shared/assets/icons/avatar.svg';
import { Avatar } from 'shared/ui/avatar/Avatar';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

interface IEmployeeNameProps {
    src?: string;
    name: string;
}

export const EmployeeName: FC<IEmployeeNameProps> = (props) => {
    const { name, src = '' } = props;

    return (
        <Flex gaps="8">
            <Avatar src={src} alt="avatar" Icon={avatar} />
            <Text text={name} withEllipsis />
        </Flex>
    );
};
