import {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { Input } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import {
    employeesActions,
    useGetEmployeesQuery,
} from 'entities/employee';
import { EmployeeRoleTabs } from 'features/employee-role-tabs';
import { HeaderMenuTablet } from 'features/header-menu-tablet';
import { InviteEmployee } from 'features/invite-employee';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useDebounce } from 'shared/lib/hooks/useDebounce/useDebounce';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Flex } from 'shared/ui/flex/Flex';
import { EmployeeManagementContent } from 'widgets/employee-management-content';
import { EmployeeManagementContentMobile } from 'widgets/employee-management-content-mobile';

import cls from './EmployeeManagementPage.module.scss';

interface IManagementPageProps {}

export const LIMIT_PAGINATION = 8;
const DEBOUNCE_DELAY = 500;

const EmployeeManagementPage: FC<IManagementPageProps> = () => {
    const dispatch = useAppDispatch();
    const { isMobile, isTablet } = useScreenType();
    const [search, setSearch] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [isLoadingInput, setIsLoadingInput] = useState(false);

    const holderRef = useRef<HTMLDivElement>(null);

    const { data: employeeData, isFetching, isLoading } = useGetEmployeesQuery({ limit: LIMIT_PAGINATION, offset, search });

    const handleChangeSearch = useDebounce(
        useCallback((value: string) => {
            setSearch(value);
        }, []),
        DEBOUNCE_DELAY,
    );

    const handleClearInput = useCallback(() => {
        setInputValue('');
        handleChangeSearch('');
    }, []);

    const scrollToInput = useCallback(() => {
        if (isMobile && holderRef.current) {
            holderRef.current.scrollTo({
                behavior: 'smooth',
                top: 60,
            });
        }
    }, [holderRef, isMobile]);

    const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setInputValue(value);
        setIsLoadingInput(true);
        scrollToInput();

        handleChangeSearch(value);
    }, [scrollToInput]);

    const onFocusInput = useCallback(() => {
        scrollToInput();
    }, [scrollToInput]);

    const Content = isMobile ? (
        <EmployeeManagementContentMobile
            count={employeeData?.count ?? 0}
            setOffset={setOffset}
            isLoading={isLoading || isFetching}
            search={search}
        />
    ) : (
        <EmployeeManagementContent
            setOffset={setOffset}
            count={employeeData?.count ?? 0}
            isLoading={isLoading || isFetching}
            search={search}
        />
    );

    const suffix = useMemo(() => {
        if ((isLoading || isLoadingInput || isFetching) && inputValue) {
            return <LoadingOutlined spin className={cls.loader} />;
        }

        if (inputValue) {
            return <CloseCircleFilled className={cls.closeIcon} onClick={handleClearInput} />;
        }

        return <span />;
    }, [isLoading, inputValue, isLoadingInput, isFetching]);

    const input = (
        <Input
            placeholder="Поиск"
            value={inputValue}
            onChange={handleChangeInput}
            suffix={suffix}
            className={cls.search}
            onFocus={onFocusInput}
        />
    );

    useEffect(() => {
        if (employeeData?.results) {
            dispatch(employeesActions.setEmployees(employeeData?.results));
        }
    }, [employeeData]);

    useEffect(() => {
        if (employeeData) {
            setIsLoadingInput(false);
        }
    }, [employeeData]);

    return (
        <>
            {isTablet && <DynamicHeader leftBlock={<HeaderMenuTablet />} />}
            <div className={cls.contentWrapper} ref={holderRef}>
                <Flex direction="column" gaps="8" className={cls.topBlock}>
                    <Flex justify="space-between" max>
                        <EmployeeRoleTabs type="employee" />
                        <Flex gaps="8">
                            {!isMobile && input}
                            <InviteEmployee />
                        </Flex>
                    </Flex>
                    {isMobile && input}
                </Flex>

                {Content}
            </div>
        </>
    );
};

export default EmployeeManagementPage;
