import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Link,
} from 'react-router-dom';

import {
    Dropdown,
    MenuProps,
} from 'antd';

import FormOutlined from '@ant-design/icons/FormOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {
    ChannelAvatar,
    IChannel,
    channelActions,
} from 'entities/channels';
import {
    messagesActions,
    getMessagesShouldUpdate,
} from 'entities/message';
import {
    getStateIsChannelEdition,
    getStateIsEditPost,
} from 'entities/user';
import { ImportMessages } from 'features/import-messages';
import { SearchMessages } from 'features/search-messages';
import {
    EDIT_CHANNEL_URL,
    HOME_URL,
} from 'shared/constants/router';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Text } from 'shared/ui/text/Text';
import { createMessageActions } from 'widgets/create-message';

import cls from './ChannelPageMobileHeader.module.scss';

interface IChannelPageMobileHeaderProps {
    channel?: IChannel;
    channelId: string;
    isLoading: boolean;
    channelName: string;
    handleOpenNotification: () => void;
    isShowSearchContent: boolean;
    setIsShowSearchContent: Dispatch<SetStateAction<boolean>>;
    handleShowMessage: (id: string, page?: string, behaviorScroll?: EBehaviorScroll) => void;
}

export const ChannelPageMobileHeader: FC<IChannelPageMobileHeaderProps> = memo((props) => {
    const {
        channel,
        channelId,
        channelName,
        handleOpenNotification,
        handleShowMessage,
        isLoading,
        isShowSearchContent,
        setIsShowSearchContent,
    } = props;

    const messageUpdatingStatus = useSelector(getMessagesShouldUpdate);

    const dispatch = useAppDispatch();

    const isChannelEditionRight = useSelector(getStateIsChannelEdition);
    const isEditPostRight = useSelector(getStateIsEditPost);

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const messageUpdatingStatusPending = messageUpdatingStatus === 'pending';

    const handleToggleSearchContent = useCallback((value: boolean) => {
        setIsShowSearchContent(value);
    }, []);

    const handleOpenSearchContent = useCallback(() => {
        handleToggleSearchContent(true);
    }, []);

    const openEditChannelPage = useCallback(() => {
        dispatch(messagesActions.updateOpenFromChannelList(false));
    }, [dispatch]);

    const handleOpenCreateModal = useCallback(() => {
        dispatch(createMessageActions.clearText());
        dispatch(channelActions.setOpenCreateMessageModal(true));
    }, []);

    const items: MenuProps['items'] = useMemo(
        () => [
            ...(isEditPostRight && channel?.donorInviteUrl ? [{
                key: 'Update',
                label: <ImportMessages channel={channel} isDisabled={isLoading} />,
            }] : []),
            {
                key: 'createPost',
                label: (
                    <button
                        type="button"
                        className={cls.holderDropdownItem}
                        onClick={handleOpenCreateModal}
                        disabled={messageUpdatingStatusPending}
                    >
                        <div className={cls.dropdownItem}>
                            <Text text="Создать пост" theme="black" />
                            <PlusCircleOutlined className={cls.itemIcon} />
                        </div>
                    </button>
                ),
            },
            ...(isChannelEditionRight ? [{
                key: 'edit',
                label: (
                    <Link to={`${EDIT_CHANNEL_URL}/${channelId}`} onClick={openEditChannelPage}>
                        <button type="button" className={cls.holderDropdownItem}>
                            <div className={cls.dropdownItem}>
                                <Text text="Редактировать канал" theme="black" />
                                <FormOutlined className={cls.itemIcon} />
                            </div>
                        </button>
                    </Link>
                ),
            }] : []),
            {
                key: 'search',
                label: (
                    <button type="button" className={cls.holderDropdownItem} onClick={handleOpenSearchContent}>
                        <div className={cls.dropdownItem}>
                            <Text text="Поиск по сообщениям" theme="black" />
                            <SearchOutlined className={cls.itemIcon} />
                        </div>
                    </button>
                ),
            },
            {
                key: 'notifications',
                label: (
                    <button type="button" className={cls.holderDropdownItem} onClick={handleOpenNotification}>
                        <div className={cls.dropdownItem}>
                            <Text text="Центр уведомлений" theme="black" />
                            <InfoCircleOutlined className={cls.itemIcon} />
                        </div>
                    </button>
                ),
            },
        ],
        [channel, channelId, isLoading, isChannelEditionRight, isEditPostRight, messageUpdatingStatus],
    );

    const Avatar = useMemo(() => {
        if (!channel || isLoading) {
            return null;
        }

        return (
            <>
                <Dropdown
                    menu={{ items }}
                    placement="bottomRight"
                    overlayClassName={cls.holderDropdown}
                    onOpenChange={(value) => setIsOpenDropdown(value)}
                    disabled={messageUpdatingStatusPending}
                >
                    <div>
                        <ChannelAvatar
                            channel={channel}
                            className={classNames(
                                cls.channelAvatar,
                                { [cls.disabledAvatar]: messageUpdatingStatusPending },
                            )}
                        />
                    </div>
                </Dropdown>
                {/* we display this block in a hidden position,
                since the dropdown does not render the item
                when it is closed, and we have logic in the
                component that should work */}
                {!isOpenDropdown && <ImportMessages channel={channel} isDisabled={isLoading} className={cls.hideButtonImport} />}
                <SearchMessages
                    show={isShowSearchContent}
                    setShow={handleToggleSearchContent}
                    channelId={channelId}
                    handleShowMessage={handleShowMessage}
                />
            </>
        );
    }, [channel, items, isOpenDropdown, isLoading, isShowSearchContent]);

    return (
        <DynamicHeader
            leftBlock={(
                <Link to={HOME_URL}>
                    <LeftOutlined className={cls.iconBack} />
                </Link>
            )}
            title={channelName}
            rightBlock={Avatar}
            isHideCenterBlock={!channelName || isLoading}
            className={cls.dynamicHeader}
        />
    );
});
