import Cookies from 'js-cookie';

import {
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:8000',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        prepareHeaders(headers) {
            headers.set('X-CSRFToken', Cookies.get('csrftoken') || '');
            return headers;
        },
    }),
    endpoints: () => ({}),
    reducerPath: 'api',
    tagTypes: ['Messages', 'Channels', 'Channel', 'User', 'Notifications', 'UserData', 'Employees', 'Roles', 'Role'],
});

export const {
    middleware: apiMiddleware,
    reducer: apiReducer,
} = api;
