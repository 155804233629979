// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vXZgoILe{position:relative;margin:0 auto;width:50px}.vXZgoILe::before{content:"";display:block;padding-top:100%}.INCjjdXi{animation:eC2RfWP4 2s linear infinite;height:100%;transform-origin:center center;width:100%;position:absolute;inset:0;margin:auto}.xawXN8UL{stroke-dasharray:1,200;stroke-dashoffset:0;animation:L3LrKU0o 1.5s ease-in-out infinite;stroke-linecap:round;stroke:var(--blue-primary)}@keyframes eC2RfWP4{100%{transform:rotate(360deg)}}@keyframes L3LrKU0o{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:89,200;stroke-dashoffset:-35px}100%{stroke-dasharray:89,200;stroke-dashoffset:-124px}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/loader/google/GoogleLoader.module.scss"],"names":[],"mappings":"AAAA,UACE,iBAAA,CACA,aAAA,CACA,UAAA,CAEA,kBACE,UAAA,CACA,aAAA,CACA,gBAAA,CAIJ,UACE,qCAAA,CACA,WAAA,CACA,8BAAA,CACA,UAAA,CACA,iBAAA,CACA,OAAA,CACA,WAAA,CAGF,UACE,sBAAA,CACA,mBAAA,CACA,4CAAA,CACA,oBAAA,CACA,0BAAA,CAGF,oBACE,KACE,wBAAA,CAAA,CAIJ,oBACE,GACE,sBAAA,CACA,mBAAA,CAGF,IACE,uBAAA,CACA,uBAAA,CAGF,KACE,uBAAA,CACA,wBAAA,CAAA","sourcesContent":[".loader {\n  position: relative;\n  margin: 0 auto;\n  width: 50px;\n\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n}\n\n.circular {\n  animation: rotate 2s linear infinite;\n  height: 100%;\n  transform-origin: center center;\n  width: 100%;\n  position: absolute;\n  inset: 0;\n  margin: auto;\n}\n\n.path {\n  stroke-dasharray: 1, 200;\n  stroke-dashoffset: 0;\n  animation: dash 1.5s ease-in-out infinite;\n  stroke-linecap: round;\n  stroke: var(--blue-primary);\n}\n\n@keyframes rotate {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes dash {\n  0% {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n  }\n\n  50% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -35px;\n  }\n\n  100% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -124px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `vXZgoILe`,
	"circular": `INCjjdXi`,
	"rotate": `eC2RfWP4`,
	"path": `xawXN8UL`,
	"dash": `L3LrKU0o`
};
export default ___CSS_LOADER_EXPORT___;
