// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JkmNUwUq{overflow-y:auto;padding:var(--p-1-5) 0}.io8HFuEX{color:var(--black-percent-45);padding:0 var(--p-4-5);text-align:center}.Qb0gw9NO{width:100%;height:100%;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/entities/notification/ui/list/NotificationList.module.scss"],"names":[],"mappings":"AAAA,UACI,eAAA,CACA,sBAAA,CAGJ,UACI,6BAAA,CACA,sBAAA,CACA,iBAAA,CAGJ,UACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".holderList {\n    overflow-y: auto;\n    padding: var(--p-1-5) 0;\n}\n\n.emptyText {\n    color: var(--black-percent-45);\n    padding: 0 var(--p-4-5);\n    text-align: center;\n}\n\n.holderEmpty {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderList": `JkmNUwUq`,
	"emptyText": `io8HFuEX`,
	"holderEmpty": `Qb0gw9NO`
};
export default ___CSS_LOADER_EXPORT___;
