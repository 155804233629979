import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import {
    IRightsUser,
    IUpdateAuthState,
    IUserSchema,
} from '../types/user.types';

const initialState: IUserSchema = {
    authError: false,
    init: false,
    isAuth: false,
    isChannelEdition: false,
    isEditPost: false,
    isRoleAssignment: false,
    isRoleEdition: false,
};

export const userSlice = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setAuth(state, { payload }: PayloadAction<IUpdateAuthState>) {
            if (!state.init) {
                state.init = true;
            }
            state.isRoleAssignment = payload.isRoleAssignment || false;
            state.isRoleEdition = payload.isRoleEdition || false;
            state.isEditPost = payload.isEditPost || false;
            state.isChannelEdition = payload.isChannelEdition || false;
            state.isAuth = payload.isAuth;
            state.userData = payload.data;
        },
        setAuthError(state, { payload: value }: PayloadAction<boolean>) {
            state.authError = value;
        },
        setRights(state, { payload: value }: PayloadAction<IRightsUser>) {
            state.isRoleAssignment = value.isRoleAssignment;
            state.isRoleEdition = value.isRoleEdition;
            state.isEditPost = value.isEditPost;
            state.isChannelEdition = value.isChannelEdition;
        },
    },
});

export const { reducer: userReducer } = userSlice;
export const { actions: userActions } = userSlice;
