// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RYHD1gHc{margin-bottom:var(--p-1-5)}.FfiyVCcV{margin-top:var(--p-2-5)}.IE3f7lgo{margin-top:var(--p-0-75)}.GHgGIY1K div[class=ant-modal-content]{padding:var(--p-2)}`, "",{"version":3,"sources":["webpack://./src/widgets/channel-editor/ui/blocks/variables/items/modal/CreateVariableModal.module.scss"],"names":[],"mappings":"AAAA,UACI,0BAAA,CAGJ,UACI,uBAAA,CAGJ,UACI,wBAAA,CAIA,uCACI,kBAAA","sourcesContent":[".title {\n    margin-bottom: var(--p-1-5);\n}\n\n.btnControls {\n    margin-top: var(--p-2-5);\n}\n\n.valueInput {\n    margin-top: var(--p-0-75);\n}\n\n.createVariableModal {\n    & div[class=\"ant-modal-content\"] {\n        padding: var(--p-2);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `RYHD1gHc`,
	"btnControls": `FfiyVCcV`,
	"valueInput": `IE3f7lgo`,
	"createVariableModal": `GHgGIY1K`
};
export default ___CSS_LOADER_EXPORT___;
