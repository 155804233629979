// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gvQ8EnWA{margin-left:var(--p-0-5);width:16px;height:16px;padding-top:var(--p-0-25)}.gvQ8EnWA svg{width:16px;height:16px}.lGVwRFrW div{color:var(--black-percent-88) !important}`, "",{"version":3,"sources":["webpack://./src/widgets/channel-editor/ui/common/tooltip-info/TooltipInfo.module.scss"],"names":[],"mappings":"AAAA,UACI,wBAAA,CACA,UAAA,CACA,WAAA,CACA,yBAAA,CAEA,cACI,UAAA,CACA,WAAA,CAKJ,cACI,wCAAA","sourcesContent":[".tooltipWrapper {\n    margin-left: var(--p-0-5);\n    width: 16px;\n    height: 16px;\n    padding-top: var(--p-0-25);\n\n    svg {\n        width: 16px;\n        height: 16px;\n    }\n}\n\n.overlayTooltip {\n    div {\n        color: var(--black-percent-88) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipWrapper": `gvQ8EnWA`,
	"overlayTooltip": `lGVwRFrW`
};
export default ___CSS_LOADER_EXPORT___;
