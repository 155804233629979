import {
    FC,
    Dispatch,
    SetStateAction,
    memo,
} from 'react';

import {
    Button,
    Modal,
} from 'antd';

import { EModalWidth } from 'shared/constants/modal-sizes';
import { declensionOfNum } from 'shared/lib/utils/string/string';
import { Text } from 'shared/ui/text/Text';

interface IMaxLengthSymbolsErrorModalProps {
    isErrorOpenModal: boolean;
    setIsErrorOpenModal: Dispatch<SetStateAction<boolean>>;
    errorMaxLength: number;
}

export const MaxLengthSymbolsErrorModal: FC<IMaxLengthSymbolsErrorModalProps> = memo((props) => {
    const { errorMaxLength, isErrorOpenModal, setIsErrorOpenModal } = props;

    const closeModal = () => {
        setIsErrorOpenModal(false);
    };

    const footer = [
        <Button key="ok-btn" onClick={closeModal} type="primary">ОК</Button>,
    ];

    const signText = declensionOfNum(errorMaxLength, ['знак', 'знака', 'знаков']);

    return (
        <Modal
            width={EModalWidth.width400}
            closeIcon={false}
            title="Максимальное количество символов"
            open={isErrorOpenModal}
            footer={footer}
            centered
            onCancel={closeModal}
            onOk={closeModal}
        >
            <Text text="Слишком длинный текст!" />
            <Text text={`Пожалуйста, укоротите его на ${errorMaxLength} ${signText}!`} />
        </Modal>
    );
});
