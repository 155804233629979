// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ffqCU0Gj{width:100%;flex:1}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/content-types-renderer/ContentTypeRenderer.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,MAAA","sourcesContent":[".wrapperAudio {\n    width: 100%;\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperAudio": `ffqCU0Gj`
};
export default ___CSS_LOADER_EXPORT___;
