import {
    FC,
    useCallback,
    useState,
} from 'react';

import { Button } from 'antd';

import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import {
    useLogoutMutation,
} from 'entities/user';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import { BurgerMenu } from './elements/burger-menu/BurgerMenu';
import { NavigationList } from './elements/navigation-list/NavigationList';
import { UserInfo } from './elements/user-info/UserInfo';

import cls from './HeaderMenuTablet.module.scss';

interface IHeaderMenuTabletProps {
    handleToggleSidebar?: (value: boolean) => void;
}

export const HeaderMenuTablet: FC<IHeaderMenuTabletProps> = (props) => {
    const { handleToggleSidebar } = props;

    const [logout] = useLogoutMutation();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const handleLogout = useCallback(() => {
        logout();
    }, []);

    return (
        <div>
            <BurgerMenu
                handleToggleSidebar={handleToggleSidebar}
                isSidebarOpen={isSidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className={classNames(cls.sidebar, { [cls.sidebarOpen]: isSidebarOpen })}>
                <div className={cls.wrapperUserInfo}>
                    <UserInfo />
                    <NavigationList setSidebarOpen={setSidebarOpen} />
                </div>

                <div className={cls.holderLogout}>
                    <Button
                        className={cls.logout}
                        onClick={handleLogout}
                        type="text"
                        icon={<LogoutOutlined className={cls.logoutIcon} />}
                        danger
                    >
                        Выйти
                    </Button>
                </div>
            </div>
        </div>
    );
};
