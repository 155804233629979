import {
    IAccessRights,
    IAccessRightsAdminModes,
    IAccessRightsAdminModesFromServer,
    IAccessRightsChannelManagement,
    IAccessRightsChannelManagementFromServer,
    IAccessRightsFromServer,
} from 'entities/role';
import { removeUndefinedKeys } from 'shared/lib/utils/object/object';

import {
    IRequestEditRoleData,
    IRequestEditRoleDataFromServer,
    IRolesChannel,
    IRolesChannelFromServer,
    IRolesFromServer,
    IRolesUser,
    IRolesUserFromServer,
} from '../model/types/edit-role.types';

export const transformRoleAccessRightsAdminModes = (
    rawResult: IAccessRightsAdminModesFromServer,
): IAccessRightsAdminModes => ({
    channelSpectation: rawResult.channel_spectation,
    invitationAccess: rawResult.invitation_access,
    roleAssignment: rawResult.role_assignment,
    roleEdition: rawResult.role_edition,
});

export const transformRoleAccessRightsChannelManagement = (
    rawResult: IAccessRightsChannelManagementFromServer,
): IAccessRightsChannelManagement => ({
    channelSettingsEdition: rawResult.channel_settings_edition,
    postEdition: rawResult.post_edition,
});

export const transformRoleAccessRights = (rawResult: IAccessRightsFromServer): IAccessRights => ({
    adminModes: transformRoleAccessRightsAdminModes(rawResult.admin_modes),
    channelManagement: transformRoleAccessRightsChannelManagement(rawResult.channel_management),
});

export const transformRoleUser = (user:
IRolesUserFromServer): IRolesUser => ({
    firstName: user.first_name,
    id: user.id,
    lastName: user.last_name,
    photo: user.photo,
    telegramId: user.telegram_id,
});

export const transformRoleChannel = (channel:
IRolesChannelFromServer): IRolesChannel => ({
    channelPhoto: channel.channel_photo,
    id: channel.id,
    name: channel.name,
});

export const transformRole = async (rawResult:
IRolesFromServer) => {
    const users = rawResult.users.map(transformRoleUser);
    const channels = rawResult.channels.map(transformRoleChannel);

    return {
        accessRights: transformRoleAccessRights(rawResult.access_rights),
        channels,
        defaultRole: rawResult.default_role,
        id: rawResult.id,
        name: rawResult.name,
        noRightsRole: rawResult.no_rights_role,
        users,
    };
};

export const transformDataChannelManagementFromServer = (data: IAccessRightsChannelManagement):
IAccessRightsChannelManagementFromServer => ({
    channel_settings_edition: data.channelSettingsEdition,
    post_edition: data.postEdition,
});

export const transformDataAdminModesFromServer = (data: IAccessRightsAdminModes): IAccessRightsAdminModesFromServer => ({
    channel_spectation: data.channelSpectation,
    invitation_access: data.invitationAccess,
    role_assignment: data.roleAssignment,
    role_edition: data.roleEdition,
});

export const transformDataAccessRightsFromServer = (data: IAccessRights): IAccessRightsFromServer => ({
    admin_modes: transformDataAdminModesFromServer(data.adminModes),
    channel_management: transformDataChannelManagementFromServer(data.channelManagement),
});

export const transformBodyEditRoleFromServer = (data:
IRequestEditRoleData): IRequestEditRoleDataFromServer => (removeUndefinedKeys({
    access_rights: data.accessRights ? transformDataAccessRightsFromServer(data.accessRights) : undefined,
    channels: data.channels,
    name: data.name,
    users: data.users,
}));
