// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R2IrdmrS{position:absolute;top:-7px;right:0;bottom:7px;left:0;outline:none;border:0;background:none;cursor:default}.HesL5X6S{background-color:var(--black-percent-04)}.E5wOfrx2{z-index:0;position:fixed}.E5wOfrx2>div>div{padding:0 !important}.dNJy6hTz{background:var(--white-primary);border-radius:var(--radius-1-5);box-shadow:0px 5px 12px 4px var(--black-percent-09),0px 3px 6px 0px var(--black-percent-12),0px 1px 2px -2px var(--black-percent-16)}.RwOmXJuE{position:fixed;left:0;top:0;height:100%;width:100%;z-index:2}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/holder-content/HolderContentMessage.module.scss"],"names":[],"mappings":"AAAA,UACI,iBAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,MAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA,CACA,cAAA,CAGJ,UACI,wCAAA,CAGJ,UACI,SAAA,CACA,cAAA,CAEA,kBACI,oBAAA,CAIR,UACI,+BAAA,CACA,+BAAA,CACA,oIAAA,CAGJ,UACI,cAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".emptyBlock {\n    position: absolute;\n    top: -7px;\n    right: 0;\n    bottom: 7px;\n    left: 0;\n    outline: none;\n    border: 0;\n    background: none;\n    cursor: default;\n}\n\n.selectMessage {\n    background-color: var(--black-percent-04);\n}\n\n.popover {\n    z-index: 0;\n    position: fixed;\n\n    & > div > div {\n        padding: 0 !important;\n    }\n}\n\n.mobileControls {\n    background: var(--white-primary);\n    border-radius: var(--radius-1-5);\n    box-shadow: 0px 5px 12px 4px var(--black-percent-09), 0px 3px 6px 0px var(--black-percent-12), 0px 1px 2px -2px var(--black-percent-16);\n}\n\n.overlay {\n    position: fixed;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n    z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyBlock": `R2IrdmrS`,
	"selectMessage": `HesL5X6S`,
	"popover": `E5wOfrx2`,
	"mobileControls": `dNJy6hTz`,
	"overlay": `RwOmXJuE`
};
export default ___CSS_LOADER_EXPORT___;
