// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ipx35L6O{min-height:180px;position:relative}.yrfNQGo0{position:absolute;bottom:0}.QMptwJP4{height:100%}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/variants/image-message/ImageMessage.module.scss"],"names":[],"mappings":"AAAA,UACI,gBAAA,CACA,iBAAA,CAGJ,UACI,iBAAA,CACA,QAAA,CAGJ,UACI,WAAA","sourcesContent":[".imageWrapper {\n    min-height: 180px;\n    position: relative;\n}\n\n.imageItem {\n    position: absolute;\n    bottom: 0;\n}\n\n.skeletonImage {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrapper": `Ipx35L6O`,
	"imageItem": `yrfNQGo0`,
	"skeletonImage": `QMptwJP4`
};
export default ___CSS_LOADER_EXPORT___;
