import { Rule } from 'antd/es/form';
import { RuleObject } from 'rc-field-form/lib/interface';

const regChannelPublicationId = /^(-100\d+|\d+)(?:\/\d+)?$/;

const regChannelPublication = /^https?:\/\/t\.me\/c\/\d+(?:\/\d+)?$/gi;

const validateDonorChannel = (
    rule: RuleObject,
    value: string,
) => {
    if (!value) {
        return Promise.resolve();
    }

    const error = 'Введите корректную ссылку!';
    if (/^https?:\/\/t\.me\/joinchat\/.+/.test(value) || /^https?:\/\/t\.me\/\+\w+/.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(error);
};

export const checkPublishBotChannel = (value: string) => /^\d+:[\w-]{35}$/.test(value);

export const checkChannelPublication = (value: string) => regChannelPublication.test(value);

export const checkChannelPublicationId = (value: string) => regChannelPublicationId.test(value);

const validatePublishBotChannel = (isEditType: boolean) => (
    rule: RuleObject,
    value: string,
) => {
    const error = 'Введите корректный токен!';
    if (checkPublishBotChannel(value)) {
        return Promise.resolve();
    }
    if (isEditType && /^\d+:\*{8}.{4}$/.test(value)) {
        return Promise.resolve();
    }
    return Promise.reject(error);
};

const requiredRule = { message: 'Поле является обязательным', required: true };

export const rulesValidationDonorChannel: Rule[] = [
    {
        validator: validateDonorChannel,
    },
];

export const rulesValidationNameChannel: Rule[] = [
    requiredRule,
    { max: 256, message: 'Максимальное количество символов - 256' },
];

export const rulesValidationTimezone: Rule[] = [
    requiredRule,
];

export const rulesValidationDatePublication: Rule[] = [
    requiredRule,
];

export const rulesValidationChannelPublication: Rule[] = [
    requiredRule,
    { message: 'Введите правильную ссылку канала', pattern: regChannelPublication },
];

export const rulesValidationChannelPublicationId: Rule[] = [
    requiredRule,
    { message: 'Введите правильный ID канала', pattern: regChannelPublicationId },
];

export const rulesValidationBotToken: (isEditType: boolean) => Rule[] = (isEditType) => [
    requiredRule,
    {
        validator: validatePublishBotChannel(isEditType),
    },
];
