import {
    CSSProperties,
    FC,
    useMemo,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './Skeleton.module.scss';

interface ISkeletonProps {
    className?: string;
    height?: string | number;
    width?: string | number;
    border?: string;
    withAnimation?: boolean;
    id?: string;
    styles?: CSSProperties;
}

export const Skeleton: FC<ISkeletonProps> = (props) => {
    const {
        border, className, height,
        id, styles: propsStyles = {}, width,
        withAnimation = true,
    } = props;

    const styles = useMemo<CSSProperties>(() => ({
        borderRadius: border,
        height,
        width,
        ...propsStyles,
    }), [width, height, border, propsStyles]);

    return (
        <div
            id={id}
            className={classNames(cls.skeleton, {
                [cls.animated]: withAnimation,
            }, [className])}
            style={styles}
        />
    );
};
