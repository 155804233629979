import 'antd/dist/reset.css';
import './styles/index.scss';

import {
    FC,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    getUserInit,
    useGetUserSettingsQuery,
    userActions,
    IUpdateUserState,
    IUserInfo,
} from 'entities/user';
import {
    AUTH_URL,
    HOME_URL,
} from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { isAuthURL } from 'shared/lib/utils/validUrl/validUrl';

import { AppRouter } from './providers/router';

const validUserData = (data: IUserInfo) : IUpdateUserState => {
    const adminModesRights = data?.role?.accessRights?.adminModes;
    const channelManagementRights = data?.role?.accessRights?.channelManagement;

    return {
        data: {
            channelCount: data.channelCount,
            disableSyncAlert: data.disableSyncAlert,
            firstName: data.firstName,
            hasRole: data.hasRole,
            id: data.id,
            lastName: data.lastName,
            photo: data.photo,
            role: data.role,
            settings: { disableSyncAlert: data.disableSyncAlert },
            userName: data.userName,
        },
        isChannelEdition: channelManagementRights?.channelSettingsEdition || false,
        isEditPost: channelManagementRights?.postEdition || false,
        isRoleAssignment: adminModesRights?.roleAssignment || false,
        isRoleEdition: adminModesRights?.roleEdition || false,
    };
};

const App: FC = () => {
    const { data: userData, error, isLoading } = useGetUserSettingsQuery();

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userInit = useSelector(getUserInit);

    useEffect(() => {
        if (isLoading) return;
        if ((!userData && !isAuthURL(location.pathname)) || error) {
            dispatch(userActions.setAuth({ isAuth: false }));

            if (!isAuthURL(location.pathname)) {
                navigate(AUTH_URL);
            }
            return;
        }
        if (userData) {
            dispatch(userActions.setAuth({ ...validUserData(userData), isAuth: true }));

            if (isAuthURL(location.pathname)) {
                navigate(HOME_URL);
            }
        }
    }, [userData, isLoading, error]);

    useEffect(() => {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            const doc = document.documentElement;
            doc.style.setProperty('--vh', `${window.innerHeight}px`);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVh();
        window.addEventListener('load', setVh);
        window.addEventListener('resize', setVh);
        window.addEventListener('orientationchange', setVh);

        return () => {
            window.removeEventListener('load', setVh);
            window.removeEventListener('resize', setVh);
            window.removeEventListener('orientationchange', setVh);
        };
    }, []);

    if (!userInit) {
        return null;
    }

    return (
        <div className="app app_light_theme">
            <AppRouter />
        </div>
    );
};

export default App;
