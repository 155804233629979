import {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useCallback,
    useState,
} from 'react';

import {
    Form,
    FormInstance,
    Input,
} from 'antd';

import {
    IChannel,
    IVariable,
} from 'entities/channels';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

import { CHANNEL_FORM_FIELDS } from '../../../../../constants/form-fields.constant';
import cls from '../../VariablesBlock.module.scss';
import { CreateVariableModal } from '../modal/CreateVariableModal';

import { VariableAction } from './VariableAction';

interface IVariableEditorProps {
    setIsChanging:Dispatch<SetStateAction<boolean>>;
    getAllVariables: () => IVariable[];
    triggerUpdateVariables: () => void;
    form: FormInstance<IChannel>;
    isNewVar?: boolean;
    isTablet: boolean;
    isChanging: boolean;
    variable?: IVariable;
}

const MAX_NAME_LENGTH = 32;
const MAX_VALUE_LENGTH = 150;
const VARIABLE_EXISTS_ERROR = 'Переменная с таким названием уже существует';

export const VariableEditor: FC<IVariableEditorProps> = memo((props) => {
    const {
        form, getAllVariables, isChanging,
        isNewVar, isTablet, setIsChanging,
        triggerUpdateVariables, variable,
    } = props;

    const [newVariableName, setVarName] = useState(variable?.name ?? '');
    const [newVariableValue, setVarValue] = useState(variable?.value ?? '');

    const [errorName, setErrorName] = useState<string>('');

    const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < MAX_NAME_LENGTH) {
            setVarName(e.target.value);
            setErrorName('');
        }
    }, []);
    const handleChangeValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < MAX_VALUE_LENGTH) {
            setVarValue(e.target.value);
        }
    }, []);

    const revertChanges = useCallback(() => {
        setVarName(variable?.name ?? '');
        setVarValue(variable?.value ?? '');
        setErrorName('');
        setIsChanging(false);
    }, [variable]);

    const createVariable = useCallback(() => {
        const existingVariables = getAllVariables();
        const isVariableExists = existingVariables.some((el) => el.name === newVariableName);
        if (isVariableExists) {
            setErrorName(VARIABLE_EXISTS_ERROR);
            return;
        }

        const newVariable = { name: newVariableName, value: newVariableValue };
        form.setFieldValue(CHANNEL_FORM_FIELDS.VARIABLES, [...existingVariables, newVariable]);

        revertChanges();

        triggerUpdateVariables();
    }, [triggerUpdateVariables, form, newVariableName, newVariableValue, revertChanges]);

    const updateVariable = useCallback(() => {
        const allVariables = getAllVariables();

        const modifiedVariables = allVariables.map((variableItem: IVariable) => {
            if (variableItem.name === variable?.name) {
                return ({ ...variableItem, name: newVariableName, value: newVariableValue });
            }
            return variableItem;
        });

        form.setFieldValue(CHANNEL_FORM_FIELDS.VARIABLES, modifiedVariables);

        setIsChanging(false);
        triggerUpdateVariables();
    }, [variable, triggerUpdateVariables, newVariableName, newVariableValue, setIsChanging, form]);

    const handleClose = useCallback(() => {
        setIsChanging(false);
        revertChanges();
    }, [variable, revertChanges]);

    if (isTablet) {
        return (
            <CreateVariableModal
                onClose={handleClose}
                open={isChanging}
                onChangeName={handleChangeName}
                onChangeValue={handleChangeValue}
                createVariable={createVariable}
                updateVariable={updateVariable}
                variableName={newVariableName}
                variableValue={newVariableValue}
                isNewVar={isNewVar}
                errorName={errorName}
            />
        );
    }

    return (
        <Flex align="start" direction="column" max>
            <Flex align={variable?.name ? 'center' : 'start'} gaps="8" max>
                {
                    variable?.name
                        ? <Text text={newVariableName} className={`${cls.variable} ${cls.left}`} />
                        : (
                            <Form.Item
                                className={cls.left}
                                validateStatus={errorName ? 'error' : undefined}
                                help={errorName}
                            >
                                <Input
                                    value={newVariableName}
                                    onChange={handleChangeName}
                                    placeholder="Ключ"
                                    size="large"
                                />
                            </Form.Item>
                        )
                }

                <Flex gaps="12" className={cls.right} max>
                    <Input
                        size="large"
                        value={newVariableValue}
                        onChange={handleChangeValue}
                        placeholder="Значение"
                    />
                    <Flex gaps="12">
                        <VariableAction
                            revertChanges={revertChanges}
                            errorName={errorName}
                            isNewVar={isNewVar}
                            newVariableName={newVariableName}
                            newVariableValue={newVariableValue}
                            updateVariable={updateVariable}
                            createVariable={createVariable}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
});
