/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    Text,
    Element,
    BaseText,
} from 'slate';

import { HTML_TYPES } from '../constants/constants';
import { Node } from '../types/editor';

import {
    sanitizeAndFormatText,
    formatTextWithNodeProperties,
} from './helpers';

// This function handles a text node by sanitizing and formatting its text and then formatting it with its node properties.
// It takes a node of type BaseText as an argument and returns a string.
const handleTextNode = (node: BaseText): string => {
    const text = formatTextWithNodeProperties(sanitizeAndFormatText(node.text), node);
    return text;
};

// This function handles an element node. It creates a custom type handler for different HTML_TYPES.
// If the node has a type that matches a defined HTML_TYPE, it handles it accordingly.
// If it doesn't, it calls handleSerialize function for its children.
const handleElementNode = (node: Node): string => {
    const myNode = node as Node;
    const children = myNode.children?.length ? myNode.children[0] : { text: '' };

    const customTypeHandlers = {
        // Handler for VARIABLE type
        [HTML_TYPES.VARIABLE]: () => formatTextWithNodeProperties(
            `<${HTML_TYPES.VARIABLE} name="${children.text}" />`,
            children,
        ),
        // Handler for SPOILER type
        [HTML_TYPES.SPOILER]: () => formatTextWithNodeProperties(
            `<${HTML_TYPES.SPOILER} name="${children.text}" />`,
            children,
        ),
        // Handler for ANIEMOJI type
        [HTML_TYPES.ANIEMOJI]: () => formatTextWithNodeProperties(
            `<${HTML_TYPES.ANIEMOJI} name="${children.text}" />`,
            children,
        ),
        // Handler for LINK type
        [HTML_TYPES.LINK]: () => `<${HTML_TYPES.LINK} href="${myNode.url}">${myNode?.children?.map(handleSerialize).join('')}</${HTML_TYPES.LINK}>`,
    };

    return customTypeHandlers[myNode.type || '']?.() || `${node?.children?.map(handleSerialize).join('')}\n`;
};

// This function checks the type of the node (Text or Element) and calls the corresponding handler function.
// If the node is neither of type Text nor Element, it returns an empty string.
function handleSerialize(node: Node): string {
    if (Text.isText(node)) {
        return handleTextNode(node);
    } if (Element.isElement(node)) {
        return handleElementNode(node);
    }

    return '';
}

// The main function that serializes an array of nodes. It maps over the nodes and applies the handleSerialize function to each.
// It joins the results into a single string that it then returns.
export const serialize = (nodes: Node[]) => nodes.map(handleSerialize).join('');
