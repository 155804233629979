import {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { IEmployee } from 'entities/employee';
import {
    IRole,
} from 'entities/role';
import { getIsRoleAssignment } from 'entities/user';
import { ChangeEmployeeRole } from 'features/change-employee-role';

interface IEmployeeRoleProps {
    role?: IRole;
    employee: IEmployee;
}

export const EmployeeRole: FC<IEmployeeRoleProps> = (props) => {
    const { employee, role } = props;

    const isRoleAssignment = useSelector(getIsRoleAssignment);

    const [roleName, setRoleName] = useState(role?.name ?? '');
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    useEffect(() => {
        setRoleName(role?.name ?? '');
    }, [employee]);

    if (!isRoleAssignment) {
        return <span>{roleName}</span>;
    }

    return (
        <ChangeEmployeeRole
            isOpen={isOpen}
            employee={employee}
            roleName={roleName}
            setRoleName={setRoleName}
            toggleOpen={toggleOpen}
        />
    );
};
