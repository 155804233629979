/* eslint-disable max-len */
interface IAvatarPlaceholder {
    startColor: string;
    stopColor: string;
    id: string;
}

export const AvatarPlaceholder = ({ id, startColor, stopColor }: IAvatarPlaceholder) => (
    <svg width="54" height="54" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#clip0_${id})`}>
            <rect width="40" height="40" fill={`url(#paint0_linear_${id})`} />
            <path opacity="0.8" d="M8.08433 16.3142L27.8694 12.0025C27.9481 11.9854 28.017 12.0584 27.9963 12.1371L22.7986 31.9198C22.7815 31.9848 22.7092 32.0181 22.6488 31.99C18.7807 30.1893 18.1727 27.7575 19.301 21.6874C19.3194 21.5881 19.4504 21.5631 19.5001 21.6507L20.6879 23.7432C20.7361 23.8282 20.862 23.8108 20.8858 23.7158L22.41 17.6515C22.4492 17.4956 22.3137 17.3519 22.1579 17.3841L16.0961 18.6359C16.0012 18.6555 15.978 18.7819 16.0596 18.8346L18.07 20.1339C18.1541 20.1883 18.1232 20.3194 18.0243 20.3333C11.9755 21.185 9.60269 20.4552 8.0079 16.4622C7.98299 16.3998 8.01931 16.3283 8.08433 16.3142Z" fill="white" />
        </g>
        <defs>
            <linearGradient id={`paint0_linear_${id}`} x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                <stop stopColor={startColor} />
                <stop offset="1" stopColor={stopColor} />
            </linearGradient>
            <clipPath id={`clip0_${id}`}>
                <rect width="40" height="40" rx="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
