import {
    FC,
    memo,
} from 'react';

import {
    DatePicker,
    Input,
    Select,
} from 'antd';

import { DefaultOptionType } from 'antd/es/select';
import {
    rulesValidationDatePublication,
    rulesValidationNameChannel,
    rulesValidationTimezone,
} from 'shared/lib/utils/channels-validations/validate';
import { timezones } from 'shared/lib/utils/timezones/timezones';
import { Flex } from 'shared/ui/flex/Flex';

import { CHANNEL_FORM_FIELDS } from '../../../constants/form-fields.constant';
import { HINT_DATE_FIRST_PUBLICATION } from '../../../constants/text.constant';
import { FormItem } from '../../common/form-item/FormItem';
import { TooltipInfo } from '../../common/tooltip-info/TooltipInfo';

import { renderTimezones } from './renderTimezones';

import cls from './MainSettingsBlock.module.scss';

interface IMainSettingsBlockProps {
    handleDirtyForm: () => void;
    isDisabledFirstPublicationAt?: boolean;
}

const filterOptionFunction = (input: string, option?: DefaultOptionType) => {
    const optionKey = option?.key ?? '';
    const lowerInput = input.toLowerCase();
    const lowerOptionKey = optionKey.toLowerCase();

    return lowerOptionKey.includes(lowerInput);
};

export const MainSettingsBlock: FC<IMainSettingsBlockProps> = memo((props) => {
    const {
        handleDirtyForm,
        isDisabledFirstPublicationAt = false,
    } = props;

    return (
        <Flex
            gaps="24"
            justify="space-between"
            align="end"
            direction="column"
            max
            className={cls.mainBlockInfoWrapper}
        >
            <Flex className={cls.infoBlock} max gaps="24" justify="space-between">
                <FormItem
                    rules={rulesValidationNameChannel}
                    name={CHANNEL_FORM_FIELDS.CHANNEL_NAME}
                    label="Название канала"
                >
                    <Input size="large" maxLength={256} placeholder="Название" onChange={handleDirtyForm} />
                </FormItem>

                {/* Временно выпиливаем данную кнопку (до лучших времен) */}
                {/* <FormItem
                    rules={rulesValidationTimezone}
                    label="Ответственный"
                >
                    <ResponsibleBlock />
                </FormItem> */}

            </Flex>

            <Flex className={cls.infoBlock} gaps="24" max justify="space-between">
                <FormItem
                    rules={rulesValidationDatePublication}
                    name="firstPublicationAt"
                    label={(
                        <>
                            Дата первой публикации
                            <TooltipInfo text={HINT_DATE_FIRST_PUBLICATION} />
                        </>
                    )}
                    className={cls.blockWrapperForm}
                >
                    <DatePicker
                        className={cls.datePicker}
                        placeholder="дд-мм-гггг"
                        format="DD-MM-YYYY"
                        onChange={handleDirtyForm}
                        size="large"
                        disabled={isDisabledFirstPublicationAt}
                    />
                </FormItem>

                <FormItem
                    rules={rulesValidationTimezone}
                    name={CHANNEL_FORM_FIELDS.TIMEZONE}
                    label="Часовой пояс публикации постов"
                    className={cls.blockWrapperForm}
                >
                    <Select
                        showSearch
                        filterOption={filterOptionFunction}
                        optionLabelProp="label"
                        placeholder="Часовой пояс"
                        size="large"
                        onChange={handleDirtyForm}
                    >
                        {timezones.map(renderTimezones)}
                    </Select>
                </FormItem>
            </Flex>

        </Flex>
    );
});
