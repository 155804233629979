import {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
    Drawer,
    Modal,
} from 'antd';

import LinkOutlined from '@ant-design/icons/LinkOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { useGenerateEmployeeLinkMutation } from 'entities/employee';
import { useGetRolesQuery } from 'entities/role';
import { getIsRoleAssignment } from 'entities/user';
import { EModalWidth } from 'shared/constants/modal-sizes';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { unsecuredCopyToClipboard } from 'shared/lib/utils/clipboard/copyToClipboard';
import { Heading } from 'shared/ui/heading/Heading';
import { List } from 'shared/ui/list/List';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './InviteEmployee.module.scss';

interface IInviteEmployeeProps {
    className?: string;
}

export const InviteEmployee: FC<IInviteEmployeeProps> = (props) => {
    const { className } = props;

    const { openNotification } = useNotification();
    const { isTablet } = useScreenType();
    const isRoleAssignment = useSelector(getIsRoleAssignment);

    const [isOpen, setIsOpen] = useState(false);
    const [roleId, setRoleId] = useState('');

    const [generateLink, { data }] = useGenerateEmployeeLinkMutation();
    const { data: rolesData } = useGetRolesQuery({});

    const options = useMemo(() => rolesData?.map((role) => ({ label: role.name, value: role.id })) || [], [rolesData]);

    const toggleModal = useCallback(() => {
        setIsOpen((prev) => {
            if (!prev) {
                setRoleId('');
            }

            return !prev;
        });
    }, []);

    const changeRole = useCallback((role: string) => {
        setRoleId(role);
    }, []);

    const handleGenerateLink = useCallback(() => {
        generateLink(roleId);
    }, [roleId]);

    useEffect(() => {
        const copyInviteEmployeeLink = (link: string) => {
            try {
                const messageToCopy = link;

                if (window.isSecureContext && navigator.clipboard) {
                    navigator.clipboard.writeText(messageToCopy);
                } else {
                    unsecuredCopyToClipboard(messageToCopy);
                }

                const roleName = rolesData?.find((item) => item.id === roleId)?.name ?? '';

                const message = (
                    <>
                        Ссылка на приглашение сотрудника с  ролью
                        {' '}
                        <b>
                            “
                            {roleName}
                            ”
                        </b>
                        {' '}
                        скопирована
                    </>
                );

                openNotification({
                    description: message,
                });
            } catch (err) {
                openNotification({
                    description: 'Ссылка не была скопирована',
                    type: 'warning',
                });
            }

            toggleModal();
            setRoleId('');
        };

        if (data) {
            copyInviteEmployeeLink(data.inviteLink);
        }
    }, [data]);

    if (!isRoleAssignment) {
        return null;
    }

    const component = (
        <>
            <Heading
                className={cls.inviteEmployeeTitle}
                mB="12"
                align="center"
                max
                text="Приглашение сотрудников"
                as="h5"
            />
            <List
                onChange={changeRole}
                options={options}
                currentValue={roleId}
                classNameItem={cls.employeeItem}
                currentValueKey="value"
                className={cls.employeeList}
            />
            <div className={cls.btnWrapper}>
                <Button
                    type="primary"
                    block
                    icon={(
                        <WrappedIcon
                            Icon={LinkOutlined}
                            size="20"
                            theme="white"
                            disabled={!roleId}
                        />
                    )}
                    size="large"
                    onClick={handleGenerateLink}
                    disabled={!roleId}
                >
                    Скопировать ссылку
                </Button>
            </div>
        </>
    );

    return (
        <>
            <Button
                className={className}
                type="primary"
                icon={<PlusOutlined />}
                onClick={toggleModal}
            >
                Пригласить
            </Button>

            <Modal
                centered
                open={isOpen ? !isTablet : false}
                onCancel={toggleModal}
                footer={null}
                width={EModalWidth.width568}
                wrapClassName={cls.modal}
            >
                {component}
            </Modal>

            <Drawer
                placement="bottom"
                height="initial"
                closeIcon={null}
                rootClassName={cls.drawer}
                open={isOpen && isTablet}
                className={className}
                onClose={toggleModal}
                destroyOnClose
            >
                {component}
            </Drawer>
        </>

    );
};
