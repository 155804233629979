import {
    FC,
    MouseEvent,
    ReactNode,
    useState,
} from 'react';

import {
    Button,
    Modal,
    Popover,
} from 'antd';

import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { IFile } from 'entities/message';
import { EModalWidth } from 'shared/constants/modal-sizes';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './HolderFileLoader.module.scss';

interface IHolderFileLoaderProps {
    className?: string;
    children: ReactNode;
    file: IFile | undefined;
}

const content = (
    <Flex onClick={(e) => e.stopPropagation()} align="start" gaps="8" className={cls.textBlock}>
        <WrappedIcon Icon={InfoCircleOutlined} className={cls.icon} theme="blue" />

        <Text text="Файл грузится, попробуйте обновить страницу позже" />
    </Flex>
);

export const HolderFileLoader: FC<IHolderFileLoaderProps> = (props) => {
    const { children, className, file } = props;

    const [isOpen, setIsOpen] = useState(false);

    const { isTablet } = useScreenType();

    const openModal = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const closeModal = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const reloadWindow = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        window.location.reload();
    };

    if (file?.file) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }

    if (isTablet) {
        return (
            <div className={cls.wrapper} role="button" tabIndex={0} onClick={(e) => e.stopPropagation()}>
                <button className={cls.holderBtnModal} type="button" onClick={openModal}>{children}</button>

                <Modal
                    open={isOpen}
                    onCancel={closeModal}
                    className={className}
                    centered
                    footer={null}
                    closable={false}
                    width={EModalWidth.width400}
                >
                    <Flex gaps="20" direction="column" align="center">
                        <Flex direction="column" gaps="12">
                            <Text align="center" isTitle size="lg" theme="black" weight="600" text="Пост грузится" />
                            <Text
                                max
                                align="center"
                                text={
                                    `Публикация находится в процессе загрузки.
                                 Пожалуйста, попробуйте обновить страницу через некоторое время.`
                                }
                            />
                        </Flex>

                        <Flex gaps="12" max>
                            <Button block onClick={closeModal} type="default">Отменить</Button>
                            <Button block onClick={reloadWindow} type="primary">Обновить</Button>
                        </Flex>
                    </Flex>
                </Modal>
            </div>
        );
    }

    return (
        <Popover
            content={content}
            placement="right"
            trigger="hover"
            className={classNames(cls.holderFileLoader, {}, [className])}
            overlayClassName={cls.holderFileLoaderOverlay}
        >
            {children}
        </Popover>
    );
};
