import {
    FC,
    Suspense,
    useMemo,
    useState,
} from 'react';
import {
    useParams,
} from 'react-router-dom';

import {
    useGetChannelByIdQuery,
} from 'entities/channels';
import {
    useGetMessagesQuery,
} from 'entities/message';
import { ContentViewer } from 'features/content-viewer';
import { GlobalAudioChannel } from 'features/global-audio-channel';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { ChannelPageHeaderWrapper } from 'widgets/channel-header';
import { CreateMessage } from 'widgets/create-message';
import { EditMessage } from 'widgets/edit-message';
import { MessageList } from 'widgets/message-list';
import { NotificationSidebar } from 'widgets/notification-sidebar';
import { PinnedMessageList } from 'widgets/pinned-message-list';
import { Sidebar } from 'widgets/sidebar';

import { useChannelHandler } from '../hooks/useChannelHandler';

import { HintUpdatePost } from './hint/hint-update-post/HintUpdatePost';

import cls from './ChannelPage.module.scss';

export const ChannelPage: FC = () => {
    const { id: channelId } = useParams();
    if (!channelId) return null;

    const sessionId = useMemo(() => Date.now(), [channelId]);
    const { isTablet } = useScreenType();

    const [prevChannelId, setPrevChannelId] = useState('');
    const [queryParams, setQueryParams] = useState('1');
    const [isShowFooterMobile, setIsShowFooterMobile] = useState(false);

    const {
        data: channel,
        isLoading: isLoadingChannel,
        refetch: reloadChannels,
        status: statusQueryChannel,
    } = useGetChannelByIdQuery(channelId);

    const {
        data: messagesFromServer,
        isFetching: isFetchingMessages,
        isLoading: isLoadingMessages,
        refetch,
        status: statusQueryMessages,
    } = useGetMessagesQuery({
        channelId,
        query: channelId !== prevChannelId ? '' : queryParams,
        sessionId,
    });

    const {
        channelName,
        handleClearLastPublishedLoad,
        handleEditMessage,
        handleOpenNotification,
        handleReloadChannels,
        handleRemoveMessage,
        isLoadingChangeChannel,
        isNotifications,
        isOpenNotifications,
        isUpdateNotifications,
        onScrollMessage,
        open,
        reloadMessages,
        setIsOpenNotifications,
        setIsUpdateChannels,
        setShouldAddMessages,
        setUpdateNotifications,
        shouldAddMessages,
        statusUpdateChannel,
        toggleModal,
    } = useChannelHandler({
        channel,
        channelId,
        isFetchingMessages,
        isLoadingChannel,
        prevChannelId,
        refetch,
        reloadChannels,
        statusQueryChannel,
        statusQueryMessages,
    });

    return (
        <MainLayouts Sidebar={isTablet ? null : <Sidebar onScrollMessage={onScrollMessage} />}>
            <div className={cls.container}>
                <div
                    className={classNames(cls.wrapperContent, {
                        [cls.wrapperContentWithNotifications]: isNotifications && !isLoadingChangeChannel,
                    })}
                >
                    <ChannelPageHeaderWrapper
                        channelId={channelId}
                        channel={channel}
                        isLoading={isLoadingChangeChannel}
                        channelName={channelName}
                        handleOpenNotification={handleOpenNotification}
                        setIsShowFooterMobile={setIsShowFooterMobile}
                    />
                    <div className={cls.holderContent}>
                        {channel && channel.lastPinnedPost && channel.lastPinnedPostPageUrl && (
                            <PinnedMessageList
                                pinMessage={channel.lastPinnedPost}
                                pinMessagePage={channel.lastPinnedPostPageUrl}
                                messagesFromServer={messagesFromServer}
                                variables={channel?.variables || []}
                                channelId={channel.id}
                            />
                        )}

                        <GlobalAudioChannel isPinnedPost={!!channel?.lastPinnedPost && !!channel?.lastPinnedPostPageUrl} />

                        {isLoadingChangeChannel && !statusUpdateChannel && (
                            <>
                                <PageLoader isLoading withoutPortal isAbsolute />
                                <div className={cls.hidingBlock} />
                            </>
                        )}
                        <HintUpdatePost status={statusUpdateChannel} />

                        <MessageList
                            onEditMessage={handleEditMessage}
                            channelId={channelId}
                            channel={channel}
                            isFetchingMessages={isFetchingMessages}
                            variables={channel?.variables || []}
                            isLoadingMessages={isLoadingMessages}
                            prevChannelId={prevChannelId}
                            reloadMessages={reloadMessages}
                            reloadChannels={reloadChannels}
                            setPrevChannelId={setPrevChannelId}
                            setQueryParams={setQueryParams}
                            messagesFromServer={messagesFromServer}
                            onRemoveMessage={handleRemoveMessage}
                            isPinnedMessage={!!channel?.lastPinnedPost}
                            shouldAddMessages={shouldAddMessages}
                            setShouldAddMessages={setShouldAddMessages}
                            handleClearLastPublishedLoad={handleClearLastPublishedLoad}
                            isShowFooterMobile={isShowFooterMobile}
                        />
                    </div>
                    <Suspense fallback={<PageLoader isLoading withoutPortal isAbsolute />}>
                        <EditMessage
                            open={open}
                            toggleModal={toggleModal}
                            handleReloadChannels={handleReloadChannels}
                            setIsUpdateChannels={setIsUpdateChannels}
                        />
                    </Suspense>

                    <ContentViewer />
                </div>
                {channel?.id && (
                    <NotificationSidebar
                        channelId={channel?.id}
                        isLoadingChangeChannel={isLoadingChangeChannel}
                        channelName={channelName}
                        isOpenNotifications={isOpenNotifications}
                        setIsOpenNotifications={setIsOpenNotifications}
                        isUpdateNotifications={isUpdateNotifications}
                        setUpdateNotifications={setUpdateNotifications}
                    />
                )}

                <CreateMessage
                    channel={channel}
                    handleReloadChannels={handleReloadChannels}
                    setIsUpdateChannels={setIsUpdateChannels}
                />
            </div>
        </MainLayouts>
    );
};
