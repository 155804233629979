import {
    FC,
    ReactNode,
} from 'react';

import { Row } from 'antd';

import { classNames } from 'shared/lib/utils/classNames/classNames';

interface IMainLayoutsProps {
    Sidebar?: ReactNode;
    children: ReactNode;
    isAuthPage?: boolean;
    className?: string;
}

export const MainLayouts: FC<IMainLayoutsProps> = (props) => {
    const {
        Sidebar = null, children, className, isAuthPage,
    } = props;

    return (
        <Row wrap={false} className={className}>
            {Sidebar}
            <main className={classNames('page-wrapper', { 'page-wrapper-auth': isAuthPage })}>
                {children}
            </main>
        </Row>
    );
};
