import {
    FC,
    useCallback,
    useState,
} from 'react';

import {
    FormInstance,
} from 'antd';

import {
    IChannel,
    IVariable,
} from 'entities/channels';
import { Flex } from 'shared/ui/flex/Flex';

import { CHANNEL_FORM_FIELDS } from '../../../constants/form-fields.constant';

import { VariablesBlock } from './VariablesBlock';

import cls from './VariablesBlock.module.scss';

interface IVariablesWrapperProps {
    form: FormInstance<IChannel>;
    handleDirtyForm: () => void;
}

const MAX_COUNT_VARIABLES = 25;

export const VariablesWrapper: FC<IVariablesWrapperProps> = (props) => {
    const { form, handleDirtyForm } = props;

    const [, setIsUpdated] = useState(false);

    const triggerUpdateVariables = useCallback(() => {
        setIsUpdated((prev) => !prev);
        handleDirtyForm();
    }, [setIsUpdated, handleDirtyForm]);

    const variables: IVariable[] = form.getFieldValue(CHANNEL_FORM_FIELDS.VARIABLES) || [];

    return (
        <Flex gaps="4" align="start" direction="column" className={cls.variablesWrapper} max>
            {variables.map((variable) => (
                <VariablesBlock
                    key={variable.name}
                    form={form}
                    triggerUpdateVariables={triggerUpdateVariables}
                    variable={variable}
                />
            ))}
            {variables.length < MAX_COUNT_VARIABLES && (
                <VariablesBlock
                    isNewVar
                    form={form}
                    triggerUpdateVariables={triggerUpdateVariables}
                />
            )}
        </Flex>
    );
};
