// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oJRYF684{border-radius:50%;width:32px;height:32px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA,UACI,iBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".Avatar {\n    border-radius: 50%;\n    width: 32px;\n    height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Avatar": `oJRYF684`
};
export default ___CSS_LOADER_EXPORT___;
