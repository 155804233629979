import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { Text } from 'shared/ui/text/Text';

import cls from './NotificationPublicationBlockInfo.module.scss';

export const NotificationPublicationBlockInfo = () => {
    const { isTablet } = useScreenType();

    return (
        <div className={cls.container}>
            <div className={cls.holderAlert}>
                {!isTablet && <CloseCircleFilled className={cls.iconClose} />}

                <div className={cls.holderContent}>
                    <Text
                        text={(
                            <>
                                Один или несколько
                                {isTablet ? ' ' : <br />}
                                постов не опубликовались
                            </>
                        )}
                        weight="600"
                        theme="error"
                        size="sm"
                    />
                    <Text
                        text="Все последующие публикации не будут опубликованы"
                        size="xs"
                        className={cls.text}
                    />
                </div>
            </div>
        </div>
    );
};
