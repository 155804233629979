// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bqr8_TWC{position:relative;width:100%;min-width:100px;min-height:100px;height:100%;transform-origin:bottom}.Bqr8_TWC video{width:100%;height:100%;border-radius:var(--radius-1)}.NnRkiA7c{position:absolute;top:0;left:0;width:100%;height:100%;background-color:var(--white-1);animation:mw9G7E8S 1s infinite alternate}.fX64gyAB{object-fit:contain !important}@keyframes mw9G7E8S{0%{opacity:.8}100%{opacity:1}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/video-loader/VideoWithLoader.module.scss"],"names":[],"mappings":"AAAA,UACI,iBAAA,CACA,UAAA,CACA,eAAA,CACA,gBAAA,CACA,WAAA,CACA,uBAAA,CAEA,gBACI,UAAA,CACA,WAAA,CACA,6BAAA,CAIR,UACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,wCAAA,CAGJ,UACI,6BAAA,CAKJ,oBACI,GACI,UAAA,CAGJ,KACI,SAAA,CAAA","sourcesContent":[".videoContainer {\n    position: relative;\n    width: 100%;\n    min-width: 100px;\n    min-height: 100px;\n    height: 100%;\n    transform-origin: bottom;\n    \n    video {\n        width: 100%;\n        height: 100%;\n        border-radius: var(--radius-1);\n    }\n}\n\n.skeletonLoader {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: var(--white-1);\n    animation: skeleton-loading 1s infinite alternate;\n}\n\n.androidVideo {\n    object-fit: contain !important;\n}\n\n\n\n@keyframes skeleton-loading {\n    0% {\n        opacity: 0.8;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoContainer": `Bqr8_TWC`,
	"skeletonLoader": `NnRkiA7c`,
	"skeleton-loading": `mw9G7E8S`,
	"androidVideo": `fX64gyAB`
};
export default ___CSS_LOADER_EXPORT___;
