import {
    FC,
    useCallback,
    useRef,
} from 'react';

import { IArgsContentViewer } from 'features/content-viewer';
import { useThrottle } from 'shared/lib/hooks/useThrottle/useThrottle';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { ImageWithLoader } from 'shared/ui/image-loader/ImageWithLoader';

import { IFile } from '../../../model/types/message.types';

import cls from './ImageMessage.module.scss';

interface IImageMessageProps {
    file: IFile;
    webPreviewClass?: string;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
}

const THROTTLE_DELAY = 750;

export const ImageMessage: FC<IImageMessageProps> = (props) => {
    const { file, openPreviewVideoOrPhoto, webPreviewClass } = props;

    const imageRef = useRef<HTMLImageElement>(null);

    const openImage = useThrottle(
        useCallback(
            (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                e.stopPropagation();

                if (imageRef.current) {
                    const rect = imageRef.current.getBoundingClientRect();
                    openPreviewVideoOrPhoto({
                        contentType: 'photo',
                        coordinates: {
                            height: rect.height,
                            width: rect.width,
                            x: rect.left,
                            y: rect.top,
                        },
                        fileId: file.id,
                        fileSrc: file.file,
                    });
                }
            },
            [imageRef],
        ),
        THROTTLE_DELAY,
    );

    return (
        <ImageWithLoader
            ref={imageRef}
            data-id={file.id}
            className={classNames(cls.imageWrapper, {}, [webPreviewClass])}
            classNameImage={cls.imageItem}
            onContextMenu={(e) => e.preventDefault()}
            onClick={openImage}
            classNameSkeleton={cls.skeletonImage}
            src={file?.file || ''}
            alt={file?.name || 'image'}
        />
    );
};
