import {
    CSSProperties,
    FC,
    useMemo,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './Avatar.module.scss';

interface IAvatarProps {
    className?: string;
    src?: string | null;
    size?: number;
    alt?: string;
    Icon?: any;
}

export const Avatar: FC<IAvatarProps> = (props) => {
    const {
        Icon, alt, className, size, src,
    } = props;

    const styles = useMemo<CSSProperties>(() => ({
        height: size || 32,
        width: size || 32,
    }), [size]);

    if (src && alt) {
        return (
            <img
                src={src}
                className={classNames(cls.Avatar, {}, [className])}
                style={styles}
                alt={alt}
            />
        );
    }

    return (
        <Icon style={styles} className={classNames(cls.Avatar, {}, [className])} />
    );
};
