import { Select } from 'antd';

import {
    ITimezone,
    getUtc,
} from 'shared/lib/utils/timezones/timezones';

import cls from './MainSettingsBlock.module.scss';

export const renderTimezones = (timezone: ITimezone) => {
    const utc = getUtc(timezone.code);
    const optionKey = `${timezone.name} (${utc})`;
    const timezoneLabelText = (
        <>
            <span className={cls.utc}>
                (
                {utc}
                )
            </span>
            <span className={cls.timezoneName}>{timezone.name}</span>
        </>
    );
    return (
        <Select.Option
            className={cls.optionTimezone}
            key={optionKey}
            value={timezone.code}
            label={timezoneLabelText}
        >
            <span className={cls.utc}>{`(${utc})`}</span>
            <span className={cls.timezoneName}>{timezone.name}</span>
        </Select.Option>
    );
};
