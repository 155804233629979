import { FC } from 'react';

import { FormInstance } from 'antd';

import {
    IChannel,
    IStatusValidationChannelFields,
} from 'entities/channels';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { CardBlock } from 'shared/ui/card-block/CardBlock';
import { Flex } from 'shared/ui/flex/Flex';
import { Heading } from 'shared/ui/heading/Heading';

import { HINT_VARIABLES } from '../constants/text.constant';

import { ConnectionBlock } from './blocks/connection/ConnectionBlock';
import { MainSettingsBlock } from './blocks/main-settings/MainSettingsBlock';
import { VariablesWrapper } from './blocks/variables/VariablesWrapper';
import { TooltipInfo } from './common/tooltip-info/TooltipInfo';

import cls from './ChannelEditor.module.scss';

interface ICreateChannelProps {
    className?: string;
    channelStatuses: IStatusValidationChannelFields;
    isEditType?: boolean;
    form: FormInstance<IChannel>;
    handleDirtyForm?: () => void;
    isDisabledFirstPublicationAt?: boolean;
}

export const ChannelEditor: FC<ICreateChannelProps> = (props) => {
    const {
        channelStatuses,
        className,
        form,
        handleDirtyForm = () => {},
        isDisabledFirstPublicationAt,
        isEditType = false,
    } = props;

    return (
        <section className={classNames(cls.container, {}, [className])}>
            <div className={cls.channelEditorWrapper}>
                <Flex className={cls.channelEditorContent} direction="column" gaps="12" align="start" max>
                    <CardBlock>
                        <Heading className={cls.blockTitle} mB="16" text="Основная информация" />
                        <MainSettingsBlock
                            handleDirtyForm={handleDirtyForm}
                            isDisabledFirstPublicationAt={isDisabledFirstPublicationAt}
                        />
                    </CardBlock>

                    <CardBlock>
                        <Heading
                            mB="16"
                            className={cls.blockTitle}
                            text="Подключение"
                        />
                        <ConnectionBlock
                            channelStatuses={channelStatuses}
                            isEditType={isEditType}
                            handleDirtyForm={handleDirtyForm}
                        />
                    </CardBlock>

                    <Flex className={cls.variablesAndTags} gaps="12" align="initial" max>
                        <CardBlock className={cls.variableBlock}>
                            <Heading
                                mB="16"
                                className={cls.blockTitle}
                                text={(
                                    <>
                                        Переменные
                                        <TooltipInfo text={HINT_VARIABLES} />
                                    </>
                                )}
                            />
                            <Flex>
                                <VariablesWrapper
                                    form={form}
                                    handleDirtyForm={handleDirtyForm}
                                />
                            </Flex>
                        </CardBlock>
                        {/* Временно выпиливаем теги (до лучших времен) */}
                        {/*
                        <CardBlock className={cls.tagBlock}>
                            <Heading
                                mB="16"
                                className={cls.blockTitle}
                                text={(
                                    <>
                                        Теги
                                        <TooltipInfo />
                                    </>
                                )}
                            />
                            <TagsBlock />
                        </CardBlock> */}
                    </Flex>
                </Flex>
            </div>
        </section>
    );
};
