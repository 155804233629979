import {
    FC,
} from 'react';

import {
    Button,
    Modal,
} from 'antd';

import { channelActions } from 'entities/channels';
import {
    IEmployee,
    employeesActions,
    useUpdateEmployeeRoleMutation,
} from 'entities/employee';
import { useGetRolesQuery } from 'entities/role';
import { EModalWidth } from 'shared/constants/modal-sizes';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

interface IDeleteEmployeeModalProps {
    className?: string;
    isOpen: boolean;
    toggleModal: () => void;
    employee?: IEmployee;
}

export const DeleteEmployeeModal: FC<IDeleteEmployeeModalProps> = (props) => {
    const {
        className, employee, isOpen, toggleModal,
    } = props;

    const dispatch = useAppDispatch();
    const { openNotification } = useNotification();

    const [updateRole] = useUpdateEmployeeRoleMutation();
    const { data } = useGetRolesQuery({});

    const isLoading = false;

    const handleDeleteEmployee = async () => {
        if (employee) {
            try {
                const newRole = data?.find((el) => el.noRightsRole);
                await updateRole({ id: employee.id, role: newRole });
                dispatch(channelActions.setChannelsList([]));
                dispatch(employeesActions.updateDataEmployees({
                    changes:
                    { role: newRole },
                    id: employee.id,
                }));
                openNotification({ description: `Сотрудник “${employee.firstName}” успешно удален` });
            } catch (err) {
                openNotification({ description: `Сотрудник “${employee.firstName}” не был удален`, type: 'warning' });
            }
        }
        toggleModal();
    };

    return (
        <Modal
            open={isOpen}
            onCancel={toggleModal}
            className={className}
            centered
            footer={null}
            closable={false}
            width={EModalWidth.width400}
        >
            <Flex gaps="20" direction="column" align="center">
                <Flex direction="column" gaps="12">
                    <Text isTitle size="lg" theme="black" weight="600" text="Удалить сотрудника?" />
                    <Text text={(
                        <>
                            Сотрудник
                            {` "${employee?.firstName}" `}
                            будет удален
                        </>
                    )}
                    />
                </Flex>

                <Flex gaps="12" max>
                    <Button block onClick={toggleModal} type="default" size="large">Отменить</Button>
                    <Button
                        loading={isLoading}
                        block
                        onClick={handleDeleteEmployee}
                        type="primary"
                        danger
                        size="large"
                    >
                        Удалить
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};
