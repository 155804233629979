import {
    ICreateMessage,
    IMessageFromServer,
    TMessage,
    mapMessage,
    transformBodyCreateMessage,
} from 'entities/message';
import { api } from 'shared/config/rtk-query-api/api.services';

const importApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createMessage: builder.mutation<TMessage, ICreateMessage>({
            query: (data) => ({
                body: transformBodyCreateMessage(data),
                headers: {},
                method: 'POST',
                url: '/api/v1/posts/',
            }),
            transformResponse: (response:
            IMessageFromServer) => mapMessage(response),
        }),
        uploadFile: builder.mutation<any, FormData>({
            query: (formData: FormData) => ({
                body: formData,
                headers: {},
                method: 'POST',
                url: '/api/v1/uploads/',
            }),
        }),
    }),
});

export const {
    useCreateMessageMutation,
    useUploadFileMutation,
} = importApi;
