import {
    FC,
    useState,
} from 'react';

import {
    Drawer,
    Select,
} from 'antd';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { Heading } from 'shared/ui/heading/Heading';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import { FormItem } from '../../../common/form-item/FormItem';

import cls from './TypeWhoWillPost.module.scss';

interface ITypeWhoWillPostProps {
}

const options = [{ label: 'Бот' }];

export const TypeWhoWillPost: FC<ITypeWhoWillPostProps> = () => {
    const { isTablet } = useScreenType();
    const [isOpen, setIsOpen] = useState(false);

    const onTypeWhoPostClick = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <FormItem className={cls.selectTypeWhoWillPost}>
            <Select
                onDropdownVisibleChange={onTypeWhoPostClick}
                open={isTablet ? false : isOpen}
                defaultValue={options[0]}
                size="large"
                placeholder="Тип"
                options={options}
                className={cls.selectType}
                popupClassName={cls.selectOption}
            />
            <Drawer
                placement="bottom"
                onClose={onTypeWhoPostClick}
                open={isTablet ? isOpen : false}
                height="initial"
                closeIcon={null}
                rootClassName={cls.drawer}
            >
                <Heading mB="8" align="center" max text="Кто будет постить в канал" as="h3" />
                <ul>
                    {options.map((el) => (
                        <li key={el.label} onClick={onTypeWhoPostClick} className={cls.itemWrapper}>
                            <span className={cls.item}>
                                <span>{el.label}</span>
                                <WrappedIcon Icon={CheckOutlined} theme="blue" size="24" />
                            </span>
                        </li>
                    ))}
                </ul>
            </Drawer>
        </FormItem>
    );
};
