import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IFile } from 'entities/message';

import {
    IAudioFileSchema,
} from '../types/global-audio-channel.types';

export const initialState: IAudioFileSchema = {
    changedTime: null,
    date: '',
    file: null,
    isChangingWidthWave: false,
    isClearIntervalWidth: false,
    isEnded: true,
    isPlaying: false,
    messageId: null,
    name: '',
    timeRemaining: '',
    totalTime: 0,
    volume: 100,
    widthWave: 0,
};

export const audioFileSlice = createSlice({
    initialState,
    name: 'audioFile',
    reducers: {
        clearData(state) {
            state.changedTime = null;
            state.file = null;
            state.isChangingWidthWave = false;
            state.isEnded = true;
            state.isPlaying = false;
            state.messageId = null;
            state.timeRemaining = '';
            state.totalTime = 0;
            state.widthWave = 0;
            state.name = '';
            state.date = '';
        },
        setAudioFile(state, { payload: file }: PayloadAction<IFile | null>) {
            state.file = file;
        },
        setChangedTime(state, { payload: time }: PayloadAction<number | null>) {
            state.changedTime = time;
        },
        setDate(state, { payload: value }: PayloadAction<string>) {
            state.date = value;
        },
        setIsChangingWidthWave(state, { payload: value }: PayloadAction<boolean>) {
            state.isChangingWidthWave = value;
        },
        setIsClearIntervalWidth(state, { payload: value }: PayloadAction<boolean>) {
            state.isClearIntervalWidth = value;
        },
        setIsEnded(state, { payload: value }: PayloadAction<boolean>) {
            state.isEnded = value;
        },
        setMessageId(state, { payload: messageId }: PayloadAction<string | null>) {
            state.messageId = messageId;
        },
        setTimeRemaining(state, { payload: value }: PayloadAction<string>) {
            state.timeRemaining = value;
        },
        setVolume(state, { payload: value }: PayloadAction<number>) {
            state.volume = value;
        },
        setWidthWave(state, { payload: value }: PayloadAction<number>) {
            state.widthWave = value;
        },
        togglePlayingAudio(state, { payload: isPlaying }: PayloadAction<boolean | undefined>) {
            state.isPlaying = !!isPlaying;
        },
        updateName(state, { payload: name }: PayloadAction<string>) {
            state.name = name;
        },
        updateTotalTime(state, { payload: totalTime }: PayloadAction<number>) {
            state.totalTime = totalTime;
        },
    },
});

export const { reducer: audioFileReducer } = audioFileSlice;
export const { actions: audioFileActions } = audioFileSlice;
