import {
    FC,
    memo,
} from 'react';

import {
    Badge,
    Input,
} from 'antd';

import {
    IStatusValidationChannelFields,
    TStatusesChannel,
} from 'entities/channels';
import {
    rulesValidationBotToken,
    rulesValidationChannelPublicationId,
    rulesValidationDonorChannel,
} from 'shared/lib/utils/channels-validations/validate';
import { Flex } from 'shared/ui/flex/Flex';

import { CHANNEL_FORM_FIELDS } from '../../../constants/form-fields.constant';
import {
    HINT_DONOR_CHANNEL,
    HINT_ID_CHANNEL,
    HINT_WHO_WILL_POST_CHANNEL,
} from '../../../constants/text.constant';
import { FormItem } from '../../common/form-item/FormItem';
import { TooltipInfo } from '../../common/tooltip-info/TooltipInfo';

import { TypeWhoWillPost } from './type-who-will-post/TypeWhoWillPost';

import cls from './ConnectionBlock.module.scss';

interface IConnectionBlockProps {
    channelStatuses: IStatusValidationChannelFields;
    isEditType: boolean;
    handleDirtyForm: () => void;
}
interface IDisplayStatus {
    status: 'success' | 'processing' | 'error' | 'default' | 'warning';
    text: string;
}

const statusDisplayContent: Record<TStatusesChannel, IDisplayStatus> = {
    failed: { status: 'error', text: 'Ошибка' },
    not_active: { status: 'default', text: 'Не настроен' },
    pending: { status: 'processing', text: 'Идёт проверка' },
    success: { status: 'success', text: 'Активен' },
};

export const ConnectionBlock: FC<IConnectionBlockProps> = memo((props) => {
    const { channelStatuses, handleDirtyForm, isEditType } = props;

    const statusFor = (statusKey: keyof IStatusValidationChannelFields) => statusDisplayContent[channelStatuses?.[statusKey]];

    const statusDonorInviteUrl = statusFor('donorInviteUrlValidationStatus');
    const statusChannelUrl = statusFor('channelUrlValidationStatus');
    const statusBotToken = statusFor('botTokenValidationStatus');

    return (
        <Flex gaps="16" className={cls.connectionWrapper} align="end" direction="column">
            <Flex className={cls.connectionItem} gaps="8" max align="start">
                <FormItem
                    rules={rulesValidationBotToken(isEditType)}
                    name={CHANNEL_FORM_FIELDS.BOT_TOKEN}
                    label={(
                        <>
                            Кто будет постить в канал
                            <TooltipInfo text={HINT_WHO_WILL_POST_CHANNEL} />
                        </>
                    )}
                    className={cls.whoWillPostForm}
                >
                    <Input size="large" placeholder="Токен бота" onChange={handleDirtyForm} />
                </FormItem>

                <TypeWhoWillPost />

                <div className={cls.status}>
                    <Badge status={statusBotToken.status} text={statusBotToken.text} className={cls.badge} />
                </div>
            </Flex>

            <Flex className={cls.connectionItem} gaps="16" max align="start">
                <FormItem
                    rules={rulesValidationDonorChannel}
                    name={CHANNEL_FORM_FIELDS.DONOR_INVITE_URL}
                    label={(
                        <>
                            Канал откуда постить
                            <TooltipInfo text={HINT_DONOR_CHANNEL} />
                        </>
                    )}
                >
                    <Input size="large" placeholder="Пригласительная ссылка" onChange={handleDirtyForm} />
                </FormItem>
                <div className={cls.status}>
                    <Badge
                        status={statusDonorInviteUrl.status}
                        text={statusDonorInviteUrl.text}
                        className={cls.badge}
                    />
                </div>
            </Flex>

            <Flex className={cls.connectionItem} gaps="16" max align="start">
                <FormItem
                    rules={rulesValidationChannelPublicationId}
                    name={CHANNEL_FORM_FIELDS.CHAT_ID}
                    label={(
                        <>
                            Канал куда постить
                            <TooltipInfo text={HINT_ID_CHANNEL} />
                        </>
                    )}
                >
                    <Input size="large" placeholder="ID канала" onChange={handleDirtyForm} />
                </FormItem>
                <div className={cls.status}>
                    <Badge status={statusChannelUrl.status} text={statusChannelUrl.text} className={cls.badge} />
                </div>
            </Flex>
        </Flex>
    );
});
