// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C9ON_uZy{padding:var(--p-2) var(--p-2-5);height:var(--add-channel-sidebar-height);background-color:var(--white-primary)}`, "",{"version":3,"sources":["webpack://./src/features/add-channel-btn/ui/AddChannelButton.module.scss"],"names":[],"mappings":"AAAA,UACI,+BAAA,CACA,wCAAA,CACA,qCAAA","sourcesContent":[".addChannelButton {\n    padding: var(--p-2) var(--p-2-5);\n    height: var(--add-channel-sidebar-height);\n    background-color: var(--white-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addChannelButton": `C9ON_uZy`
};
export default ___CSS_LOADER_EXPORT___;
