import {
    FC,
    memo,
    useMemo,
} from 'react';

import { Tooltip } from 'antd';

import { IVariable } from 'entities/channels';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    getValidUrl,
    isValidHttpUrl,
    validTGUrl,
} from 'shared/lib/utils/validUrl/validUrl';
import { VariableTheme } from 'shared/ui/text/constants/variables';

import {
    EValueBtnType,
    IMessageButton,
} from '../../model/types/message.types';

import cls from './MessageButtonList.module.scss';

interface IMessageButtonItemProps {
    buttonItem: IMessageButton;
    variables: IVariable[];
}

export const MessageButtonItem: FC<IMessageButtonItemProps> = memo(({ buttonItem, variables }) => {
    const { id, name, value } = buttonItem;

    if (value.type === EValueBtnType.URL) {
        return (
            <a key={id} href={value.data} target="_blank" className={cls.button} rel="noreferrer">
                {name}
            </a>
        );
    }

    const dataTooltip = useMemo(() => {
        const currentVariable = variables?.find(({ name: varName }) => varName === value.data);

        if (currentVariable) {
            const isValidUrl = isValidHttpUrl(currentVariable.value) || validTGUrl(currentVariable.value);

            if (!isValidUrl) {
                return {
                    color: VariableTheme.ERROR,
                    href: '',
                    isError: true,
                    title: 'Некорректная ссылка',
                };
            }

            return {
                color: VariableTheme.PRIMARY,
                href: currentVariable.value,
                isError: false,
                title: currentVariable.value,
            };
        }

        return {
            color: VariableTheme.ERROR,
            href: '',
            isError: true,
            title: 'Несуществующая переменная',
        };
    }, [variables, value]);

    return (
        <Tooltip title={dataTooltip.title} color={dataTooltip.color} key={id}>
            <a
                href={getValidUrl(dataTooltip.href)}
                onClick={(e) => dataTooltip.isError && e.preventDefault()}
                target="_blank"
                className={classNames(
                    cls.button,
                    { [cls.errorText]: dataTooltip.isError },
                    [cls.buttonVariable, 'fontWithStickers'],
                )}
                rel="noreferrer"
            >
                {name}
            </a>
        </Tooltip>
    );
});
