/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    Popover,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';

import { Calendar } from './component/calendar/Calendar';

import cls from './MiniCalendar.module.scss';

interface IMiniCalendarProps {
    date?: string;
    onChange: (value?: string) => void;
}

export const MiniCalendar: FC<IMiniCalendarProps> = (props) => {
    const {
        date,
        onChange,
    } = props;

    const { isMobile } = useScreenType();

    const [visible, setVisible] = useState(false);
    const [currentDate, setCurrentDate] = useState<Dayjs>();

    const onToggleCalendar = useCallback(() => {
        setVisible((prev) => !prev);
    }, []);

    const onCloseCalendar = useCallback(() => {
        setVisible(false);
    }, []);

    const calendar = (
        <Calendar
            currentDate={currentDate}
            onChange={onChange}
            setCurrentDate={setCurrentDate}
            setVisible={setVisible}
        />
    );

    useEffect(() => {
        if (visible) {
            const dateValue = dayjs(date);
            setCurrentDate(dateValue);
        }
    }, [visible]);

    if (isMobile) {
        return (
            <>
                <CalendarOutlined
                    className={cls.calendarIcon}
                    onClick={onToggleCalendar}
                />
                {visible && (
                    <button
                        type="button"
                        className={cls.holderMobileCalendar}
                        onClick={onCloseCalendar}
                    >
                        {calendar}
                    </button>
                )}
            </>
        );
    }

    return (
        <>
            <Popover
                content={calendar}
                trigger="click"
                open={visible}
                rootClassName={cls.popover}
                arrow={false}
                placement="bottomRight"
            >
                <CalendarOutlined
                    className={cls.calendarIcon}
                    onClick={onToggleCalendar}
                />
            </Popover>
            {visible && (
                <div
                    className={cls.hiddenBlock}
                    onClick={onCloseCalendar}
                />
            )}
        </>
    );
};
