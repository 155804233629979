import { FC } from 'react';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import { WrappedIcon } from '../wrapped-icon/WrappedIcon';

import cls from './List.module.scss';

interface IOption {
    value: string;
    label: string;
}

interface IListProps {
    className?: string;
    classNameItem?: string;
    options: IOption[];
    onChange: (value: string) => void;
    currentValue: string;
    currentValueKey?: 'value' | 'label';
}

export const List: FC<IListProps> = (props) => {
    const {
        className,
        classNameItem,
        currentValue,
        currentValueKey = 'label',
        onChange,
        options,
    } = props;

    const onChangeElement = (value: string) => () => {
        onChange(value);
    };

    return (
        <ul className={classNames('', {}, [className])}>
            {options.map((el) => (
                <li
                    key={el.label}
                    onClick={onChangeElement(el.value)}
                    className={classNames(cls.itemWrapper, {}, [classNameItem])}
                >
                    <span className={cls.item}>
                        <span className={classNames('', { [cls.active]: currentValue === el.label })}>{el.label}</span>
                        {currentValue === el[currentValueKey] && <WrappedIcon Icon={CheckOutlined} theme="blue" size="24" />}
                    </span>
                </li>
            ))}
        </ul>
    );
};
