import {
    FC,
    ReactNode,
} from 'react';

import {
    Form,
    FormItemProps,
} from 'antd';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './FormItem.module.scss';

interface IFormItemProps extends FormItemProps {
    className?: string;
    children: ReactNode;
}

export const FormItem: FC<IFormItemProps> = (props) => {
    const { children, className, ...otherProps } = props;

    return (
        <Form.Item className={classNames(cls.formItem, {}, [className])} {...otherProps}>
            {children}
        </Form.Item>

    );
};
