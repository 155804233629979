import {
    FC,
    useCallback,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { IEmployee } from 'entities/employee';
import { getIsRoleAssignment } from 'entities/user';
import { DeleteEmployeeModal } from 'features/delete-employee';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

interface IEmployeeDeleteProps {
    employee: IEmployee;
}

export const EmployeeDelete: FC<IEmployeeDeleteProps> = (props) => {
    const { employee } = props;

    const isRoleAssignment = useSelector(getIsRoleAssignment);

    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    if (!isRoleAssignment) {
        return null;
    }

    return (
        <>
            <WrappedIcon onClick={toggleOpen} Icon={DeleteOutlined} size="20" cursorPointer theme="black-45" />
            <DeleteEmployeeModal isOpen={isOpen} toggleModal={toggleOpen} employee={employee} />
        </>
    );
};
