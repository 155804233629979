import { ReactNode } from 'react';

import { nanoid } from 'nanoid';

import { IVariable } from 'entities/channels';
import { HTML_TYPES } from 'features/rich-text-editor';

import {
    IDeserializeHandlers,
    IDeserializeSettings,
} from './deserialize.types';

interface IBelongsTags {
    [key: string]: boolean;
}

// This function checks if the given HTML tag name matches predefined tag groups
// and returns an object indicating which groups the tag belongs to.
const checkTag = (tagName: string): IBelongsTags => {
    const tagMap: { [key: string]: string[] } = {
        bold: ['B', 'STRONG'],
        code: ['CODE'],
        del: ['DEL', 'STRIKE', 'S'],
        italic: ['I', 'EM'],
        pre: ['PRE'],
        u: ['U', 'INS'],
    };

    const defaultObj: IBelongsTags = {};

    // Go through each tag group and check if the given tag is in it.
    return Object.entries(tagMap).reduce((acc, [key, tagNames]) => {
        const isInclude = tagNames.includes(tagName);
        if (isInclude) {
            acc[key] = isInclude;
        }
        return acc;
    }, defaultObj);
};

// This function is used to traverse the DOM and apply handlers based on the node type.
export const traverseDom = (
    element: Node,
    handlers: IDeserializeHandlers,
    withSettings: IDeserializeSettings = {},
): any[] => Array.from(element.childNodes).flatMap((node: ChildNode, i: number) => {
    // Generate a unique key for each node
    const key = `${i}${nanoid()}`;

    // If node is text node, apply handleTextNode
    if (node.nodeType === Node.TEXT_NODE) {
        return handlers.handleTextNode(node, withSettings, key);
    }

    // If node is an element node, we check if it's an HTMLElement and process its tags.
    if (node.nodeType === Node.ELEMENT_NODE && node instanceof HTMLElement) {
        const validTags = checkTag(node.tagName);

        // If node's tag belongs to particular group, continue traversing to children elements with updated settings.
        if (Object.values(validTags).includes(true)) {
            const updatedSettings = { ...withSettings, ...validTags, key };
            return traverseDom(node, handlers, updatedSettings);
        }

        // If node's tag doesn`t belong to particular group, apply handleElementNode
        return handlers.handleElementNode(node, withSettings, key);
    }

    return {};
});

// This function replaces custom HTML tags with corresponding span elements.
const replaceCustomTags = (html: string, customType: string): string => {
    const regex = new RegExp(`<${customType}\\s+name="([^"]+)"\\s*\\/?>`, 'g');
    return html.replace(regex, `<span data-type=${customType} data-text="$1"></span>`);
};

// This function converts an HTML string to DOM and replaces any custom tags with corresponding span elements.
export const parseHtmlStringToDomWithReplace = (html: string): Document => {
    // List of custom tag types to be replaced
    const customTypes = [HTML_TYPES.SPOILER, HTML_TYPES.ANIEMOJI, HTML_TYPES.VARIABLE];

    // Replace all custom tags in the HTML string and parse it to a DOM object
    const slateHtml = customTypes.reduce(
        (acc, customType) => replaceCustomTags(acc, customType),
        html,
    );
    return new DOMParser().parseFromString(slateHtml, 'text/html');
};

const replaceUndiscoveredVariable = (text: string) => text.replace(
    /<x-variable name="([^"]*)" \/>/g,
    '<span style="color:#FF7875">$1</span>',
);

// This function removes all tags and replaces tags with variables with their values

export const replaceTagsWithVariables = (text?: string, variables: IVariable[] = []): string | ReactNode => {
    if (!text) {
        return '';
    }

    let str = text;
    for (let i = 0; i < variables.length; i++) {
        const variable = variables[i];
        str = str.replace(new RegExp(`<x-variable name="${variable.name}"\\s*\\/?>`, 'g'), variable.value);
    }

    str = str.replace(/<!--[\s\S]*?--!?>/g, '').replace(/<(?!x-variable)\/?[a-z][^>]*(>|$)/gi, '');
    str = replaceUndiscoveredVariable(str);

    const result = { __html: str };
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={result} />;
};
