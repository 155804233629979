import {
    FC,
    memo,
} from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { getAudioFileData } from 'features/global-audio-channel';
import { INVALID_DATE } from 'shared/constants/date';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { getDayjsWithTimezonePlugin } from 'shared/lib/utils/date/date';

import { formatMessageDate } from '../../utils/date';

import cls from './DateRender.module.scss';

interface IDateRenderProps {
    date: string;
    className?: string;
    index: number;
}

export const DateRender: FC<IDateRenderProps> = memo((props) => {
    const {
        className, date, index,
    } = props;

    const fileGlobalAudio = useSelector(getAudioFileData);

    getDayjsWithTimezonePlugin();
    const publishAt = dayjs(date);
    const formattedDate = formatMessageDate(publishAt);

    const formattedOrInvalidDate = formattedDate === INVALID_DATE ? 'Некорректная дата' : formattedDate;

    return (
        <h4
            className={classNames(
                cls.date,
                {
                    [cls.firstDate]: index === 0,
                    [cls.dateWithAudio]: !!fileGlobalAudio,
                    [cls.firstDateWithAudio]: index === 0 && !!fileGlobalAudio,
                },
                [className],
            )}
        >
            <span>{formattedOrInvalidDate}</span>
        </h4>
    );
});
