import { api } from 'shared/config/rtk-query-api/api.services';

import {
    transformBodyUpdateUserSettings,
    transformResponseGetUserSettings,
} from '../../adapters/serverData';
import {
    IUpdateUserSettings,
    IUserInfo,
} from '../types/user.types';

const authTelegramApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserSettings: builder.query<IUserInfo, void>({
            providesTags: ['UserData'],
            query: () => ({ method: 'GET', url: '/api/v1/users/me/' }),
            transformResponse: transformResponseGetUserSettings,
        }),
        logout: builder.mutation<void, void>({
            invalidatesTags: ['UserData'],
            query: () => ({ method: 'POST', url: '/api/v1/auth/logout/' }),
        }),
        updateUserSettings: builder.mutation<void, IUpdateUserSettings>({
            invalidatesTags: ['UserData'],
            query: (data) => ({
                body: transformBodyUpdateUserSettings(data),
                headers: {},
                method: 'PATCH',
                url: '/api/v1/users/me/',
            }),
        }),
    }),
});

export const {
    useGetUserSettingsQuery,
    useLazyGetUserSettingsQuery,
    useLogoutMutation,
    useUpdateUserSettingsMutation,
} = authTelegramApi;
