export function useDebounce(func: Function, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout> | null;

    // eslint-disable-next-line func-names
    return function (this: any, ...args: any[]) {
        const context = this;

        clearTimeout(timeoutId!);

        timeoutId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}
