import { FC } from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './CardBlock.module.scss';

interface ICardBlockProps {
    className?: string;
    children: React.ReactNode;
    id?: string;
}

export const CardBlock: FC<ICardBlockProps> = (props) => {
    const { children, className, ...otherProps } = props;

    return (
        <section className={classNames(cls.cardBlock, {}, [className])} {...otherProps}>
            {children}
        </section>
    );
};
