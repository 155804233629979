import {
    Suspense,
    useCallback,
} from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';

import { TRoutePropsWithAdditionalSettings } from 'shared/types/router';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';
import { HeaderWrapper } from 'widgets/header';

import { routerConfig } from '../config/routerConfig';

import { RequireAuth } from './RequireAuth';

export const AppRouter = () => {
    const renderRoutes = useCallback((route: TRoutePropsWithAdditionalSettings) => {
        const element = (
            <>
                <HeaderWrapper isTransparent={route.isTransparentHeader} />

                <div id="content" className={route.withAuth ? '' : 'content-auth'}>
                    <Suspense fallback={<PageLoader isLoading withoutPortal />}>
                        {route.element}
                    </Suspense>
                </div>
            </>
        );

        return (
            <Route
                key={route.path}
                path={route.path}
                element={route.withAuth || route.accessRight
                    ? (
                        <RequireAuth accessRight={route.accessRight}>
                            {element}
                        </RequireAuth>
                    )
                    : element}
            />
        );
    }, []);

    return (
        <Routes>
            {Object.values(routerConfig).map(renderRoutes)}
        </Routes>
    );
};
