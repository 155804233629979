import { IStateSchema } from 'app/providers/store';

export const getChannels = (state: IStateSchema) => state.channels.data;
export const getChannelWhichShouldUpdate = (state: IStateSchema) => state
    .channels
    .shouldUpdateChannelId;
export const getSelectChannelName = (state: IStateSchema) => state.channels.selectChannelName;
export const getNextUrlChannel = (state: IStateSchema) => state.channels.nextUrlChannel;
export const getPreviousUrlChannel = (state: IStateSchema) => state.channels.previousUrlChannel;
export const getSearchName = (state: IStateSchema) => state.channels.searchName;
export const getOpenCreateMessageModal = (state: IStateSchema) => state.channels.openCreateMessageModal;
