import {
    FC,
    ReactNode,
} from 'react';
import { Provider } from 'react-redux';

import { DeepPartial } from '@reduxjs/toolkit';

import { IStateSchema } from '../config/state.schema';
import { createReduxStore } from '../config/store';

interface StoreProviderProps {
    children?: ReactNode;
    initialState?: DeepPartial<IStateSchema>;
}

export const StoreProvider: FC<StoreProviderProps> = (props) => {
    const { children, initialState } = props;

    const store = createReduxStore(initialState as IStateSchema);

    return <Provider store={store}>{children}</Provider>;
};
