import React, {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
    ReactNode,
} from 'react';

import { notification } from 'antd';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import ExclamationOutlined from '@ant-design/icons/ExclamationOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { TNotificationType } from 'shared/types/types';
import { Text } from 'shared/ui/text/Text';

import cls from './CustomNotification.module.scss';

interface ICustomNotificationProps {
    closeIcon: boolean;
    description: string | ReactNode;
    duration: number | null;
    type: TNotificationType;
    id: string;
    icon?: ReactNode;
    classNameHolderIcon?: string;
}

export const CustomNotification = (props: ICustomNotificationProps) => {
    const {
        classNameHolderIcon,
        closeIcon = true,
        description,
        duration,
        icon,
        id,
        type = 'warning',
    } = props;

    const [hovered, setHovered] = useState(false);
    const [progress, setProgress] = useState(0);

    const interval = useRef<ReturnType<typeof setInterval> | null>(null);

    const classes = useMemo(
        () => ({
            circle: `${type}Circle`,
            icon: `${type}Icon`,
            line: `${type}Line`,
            wrapperIcon: `${type}WrapperIcon`,
        }),
        [type],
    );

    const iconComponent = useMemo(() => {
        if (icon) {
            return icon;
        }

        if (type === 'warning') {
            return <ExclamationOutlined />;
        }

        if (type === 'error') {
            return <CloseOutlined />;
        }

        return <CheckOutlined />;
    }, [type, icon]);

    const onMouseEnter = useCallback(() => {
        setHovered(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setHovered(false);
    }, []);

    const closeNotification = useCallback(() => {
        notification.destroy(id);
    }, [id]);

    const startInterval = () => {
        if (duration) {
            interval.current = setInterval(() => {
                setProgress((prevProgress) => {
                    // Увеличиваем прогресс полосы прокрутки на 1% каждую секунду
                    if (prevProgress <= 101) {
                        return prevProgress + 1;
                    }

                    if (interval.current) {
                        clearInterval(interval.current);
                    }
                    // При достижении конца закрываем подсказку
                    closeNotification();
                    return prevProgress;
                });
            }, duration / 100);
        }
    };

    useEffect(() => {
        if (duration) {
            startInterval();
        }

        return () => {
            if (interval.current && duration) {
                clearInterval(interval.current);
            }
        };
    }, [duration]);

    useEffect(() => {
        if (duration && hovered && interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }

        if (duration && !hovered && !interval.current && progress <= 101) {
            startInterval();
        }
    }, [hovered]);

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cls.container}
        >
            <div className={cls.content}>
                <div className={classNames(cls.wrapperIcon, {}, [cls.circle, cls[classes.wrapperIcon]])}>
                    <div className={classNames(cls.circle1, {}, [cls.circle, cls[classes.circle]])} />
                    <div className={classNames(cls.circle2, {}, [cls.circle])} />
                    <div className={classNames(cls.holderIcon, { }, [cls[classes.icon], classNameHolderIcon])}>
                        {iconComponent}
                    </div>
                </div>
                <div className={cls.holderText}>
                    <Text text={description} theme="black" size="md" />
                </div>
            </div>
            {duration && (
                <div className={cls.holderline}>
                    <div className={classNames(cls.line, {}, [cls[classes.line]])} style={{ width: `${progress}%` }} />
                </div>
            )}
            {(closeIcon || !duration) && <CloseCircleFilled className={cls.closeIcon} onClick={closeNotification} />}
        </div>
    );
};
