import { transformRole } from 'entities/role';
import { transformApiListResponse } from 'shared/lib/utils/api/api';
import { IBackendListResponse } from 'shared/types/types';

import {
    IEmployee,
    IEmployeeFromServer,
    IGenerateEmployeeLinkFromServer,
} from '../model/types/employee.types';

export const mapEmployee = (rawEmployee: IEmployeeFromServer): IEmployee => ({
    channelCount: rawEmployee.channel_count,
    disableSyncAlert: rawEmployee.disable_sync_alert,
    firstName: rawEmployee.first_name,
    hasRole: rawEmployee.has_role,
    id: rawEmployee.id,
    lastName: rawEmployee.last_name,
    photo: rawEmployee.photo,
    role: transformRole(rawEmployee.role),
    username: rawEmployee.username,
});

export const transformGetEmployees = async (rawResult:
IEmployeeFromServer) => mapEmployee(rawResult);

export const transformGetEmployeesList = async (rawResult:
IBackendListResponse<IEmployeeFromServer>) => transformApiListResponse<IEmployeeFromServer, IEmployee>(rawResult, mapEmployee);

export const transformBodyGenerateEmployeeLink = (id: string) => ({ role_id: id });

export const transformGenerateEmployeeLink = async (rawResult:
IGenerateEmployeeLinkFromServer) => ({ inviteLink: rawResult.invite_link });
