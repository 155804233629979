import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
} from 'react';

import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';

interface OpenVideoIPhoneProps {
    videoRef: RefObject<HTMLVideoElement & { webkitEnterFullscreen(): Promise<void> }>;
    setIsFullscreen: Dispatch<SetStateAction<boolean>>;
}

interface OpenVideoIPhoneReturn {
    handleOpenVideoIPhone: () => void;
}

const useOpenVideoIPhone = ({ setIsFullscreen, videoRef }: OpenVideoIPhoneProps): OpenVideoIPhoneReturn => {
    const { isIPhone } = useDeviceDetect();

    const handleOpenVideoIPhone = useCallback(() => {
        const videoPlayer = videoRef.current;
        if (isIPhone && videoPlayer?.webkitEnterFullscreen) {
            videoPlayer.webkitEnterFullscreen();
            videoPlayer.play();
        }
    }, [isIPhone]);

    const handleClose = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsFullscreen(false);
            videoRef.current.load();
        }
    }, [videoRef]);

    useEffect(() => {
        const videoPlayer = videoRef.current;

        if (videoPlayer && isIPhone) {
            videoPlayer.addEventListener('webkitendfullscreen', handleClose);
        }

        return () => {
            if (videoPlayer && isIPhone) {
                videoPlayer.removeEventListener('webkitendfullscreen', handleClose);
            }
        };
    }, [isIPhone]);

    return {
        handleOpenVideoIPhone,
    };
};
export default useOpenVideoIPhone;
