import { IChannel } from 'entities/channels';

type TKeysChannelFormFields =
    'BOT_TOKEN' |
    'CHANNEL_NAME' |
    'CHAT_ID' |
    'DONOR_INVITE_URL' |
    'FIRST_PUBLICATION' |
    'TIMEZONE' |
    'VARIABLES' |
    'WEB_PREVIEW';

export const CHANNEL_FORM_FIELDS: { [key in TKeysChannelFormFields]: keyof Partial<IChannel> } = {
    BOT_TOKEN: 'botToken',
    CHANNEL_NAME: 'name',
    CHAT_ID: 'chatId',
    DONOR_INVITE_URL: 'donorInviteUrl',
    FIRST_PUBLICATION: 'firstPublicationAt',
    TIMEZONE: 'timezone',
    VARIABLES: 'variables',
    WEB_PREVIEW: 'webPreviewLimitsBypassEnabledDefault',
};
