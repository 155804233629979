// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pg2Ma7h4 div[class=ant-drawer-body]{padding:var(--p-1-5) 0 0 0}.hAvgyB5E{min-width:none;max-width:100%}.hAvgyB5E div[class=ant-select-selector]{width:calc(100% - var(--p-2)) !important}.hAvgyB5E span[class=ant-select-arrow] svg{fill:var(--black-percent-88)}.hAvgyB5E span[class=ant-select-selection-item]{color:var(--black-percent-88) !important}.aPSQUICV{min-width:142px !important}.aPSQUICV div[class=ant-select-item-option-content]{line-height:22px}.aPSQUICV div[class~=ant-select-item-option-selected]{color:var(--blue-primary) !important;font-weight:var(--font-normal) !important}.HYVubmK1{color:var(--black-percent-88)}`, "",{"version":3,"sources":["webpack://./src/features/change-employee-role/ui/ChangeEmployeeRole.module.scss"],"names":[],"mappings":"AACI,qCACI,0BAAA,CAIR,UACI,cAAA,CACA,cAAA,CAEA,yCACI,wCAAA,CAIA,2CACI,4BAAA,CAGR,gDACI,wCAAA,CAIR,UACI,0BAAA,CAEA,oDACI,gBAAA,CAEJ,sDACI,oCAAA,CACA,yCAAA,CAIR,UACI,6BAAA","sourcesContent":[".drawer {\n    & div[class='ant-drawer-body'] {\n        padding: var(--p-1-5) 0 0 0;\n    }\n}\n\n.roleSelect {\n    min-width: none;\n    max-width: 100%;\n\n    & div[class='ant-select-selector'] {\n        width: calc(100% - var(--p-2)) !important;\n    }\n\n    & span[class='ant-select-arrow'] {\n        svg {\n            fill: var(--black-percent-88);\n        }\n    }\n    & span[class='ant-select-selection-item'] {\n        color: var(--black-percent-88) !important;\n    }\n}\n\n.popup {\n    min-width: 142px !important;\n\n    & div[class='ant-select-item-option-content'] {\n        line-height: 22px;\n    }\n    & div[class~='ant-select-item-option-selected'] {\n        color: var(--blue-primary) !important;\n        font-weight: var(--font-normal) !important;\n    }\n}\n\n.employeeName {\n    color: var(--black-percent-88);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer": `pg2Ma7h4`,
	"roleSelect": `hAvgyB5E`,
	"popup": `aPSQUICV`,
	"employeeName": `HYVubmK1`
};
export default ___CSS_LOADER_EXPORT___;
