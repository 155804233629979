import { DeepPartial } from '@reduxjs/toolkit';
import { mapMessage } from 'entities/message';
import { transformApiListResponse } from 'shared/lib/utils/api/api';
import { removeUndefinedKeys } from 'shared/lib/utils/object/object';
import { IBackendListResponse } from 'shared/types/types';

import {
    IChannel,
    IChannelFromServer,
    IStatusValidationChannelFields,
    IStatusValidationChannelFieldsFromServer,
    IVariable,
    IVariableFromServer,
} from '../model/types/channels.types';

export const transformValidationStatus = (validationStatus: IStatusValidationChannelFieldsFromServer):
IStatusValidationChannelFields => ({
    botTokenValidationStatus: validationStatus.bot_token_validation_status,
    channelUrlValidationStatus: validationStatus.channel_url_validation_status,
    donorInviteUrlValidationStatus: validationStatus.donor_invite_url_validation_status,
});

export const transformVariable = (variable: IVariableFromServer):
IVariable => ({
    isUsed: variable?.is_used,
    name: variable.name,
    value: variable.value,
});

export const transformVariableList = (variableList: IVariableFromServer[]):
IVariable[] => variableList.map((variable) => transformVariable(variable));

export function mapChannel(rawResult: IChannelFromServer): IChannel {
    const lastPinnedPost = rawResult.last_pinned_post ? mapMessage(rawResult.last_pinned_post) : null;

    return {
        botToken: rawResult.bot_token,
        channelPhoto: rawResult.channel_photo,
        channelUrl: rawResult.channel_url,
        chatId: rawResult.chat_id,
        createdAt: rawResult.created_at,
        donorInviteUrl: rawResult.donor_invite_url,
        donorLastRefreshedAt: rawResult.donor_last_refreshed_at,
        donorLastSentPostId: rawResult.donor_last_sent_post_id,
        errorsCount: rawResult.errors_count,
        firstPublicationAt: rawResult.first_publication_at,
        hasSentPosts: rawResult.has_sent_posts,
        id: rawResult.id,
        lastPinnedPost,
        lastPinnedPostPageUrl: rawResult.last_pinned_post_page_url,
        lastPublishedPostAt: rawResult.last_published_post_at,
        lastPublishedPostId: rawResult.last_published_post_id,
        lastPublishedPostPageUrl: rawResult.last_published_post_page_url,
        name: rawResult.name,
        timezone: rawResult.timezone,
        updatedAt: rawResult.updated_at,
        validationStatus: transformValidationStatus(rawResult.validation_status),
        variables: transformVariableList(rawResult.variables),
        webPreviewLimitsBypassEnabledDefault: rawResult.web_preview_limits_bypass_enabled_default,
    };
}

export const transformResponseGetChannel = async (rawResult:
IChannelFromServer) => mapChannel(rawResult);

export const transformResponseGetChannelList = async (rawResult:
IBackendListResponse<IChannelFromServer>) => transformApiListResponse<IChannelFromServer, IChannel>(rawResult, mapChannel);

export const transformBodyUpdateChannel = (rawResult:
DeepPartial<IChannel>) => (removeUndefinedKeys({
    bot_token: rawResult.botToken,
    channel_photo: rawResult.channelPhoto,
    channel_url: rawResult.channelUrl,
    chat_id: rawResult.chatId,
    created_at: rawResult.createdAt,
    donor_invite_url: rawResult.donorInviteUrl,
    donor_last_refreshed_at: rawResult.donorLastRefreshedAt,
    donor_last_sent_post_id: rawResult.donorLastSentPostId,
    first_publication_at: rawResult.firstPublicationAt,
    id: rawResult.id,
    last_pinned_post: rawResult.lastPinnedPost,
    last_pinned_post_page_url: rawResult.lastPinnedPostPageUrl,
    last_published_post_at: rawResult.lastPublishedPostAt,
    last_published_post_id: rawResult.lastPublishedPostId,
    last_published_post_page_url: rawResult.lastPublishedPostPageUrl,
    name: rawResult.name,
    timezone: rawResult.timezone,
    updated_at: rawResult.updatedAt,
    validation_status: rawResult.validationStatus,
    variables: rawResult.variables,
    web_preview_limits_bypass_enabled_default: rawResult.webPreviewLimitsBypassEnabledDefault,
}));

export const transformBodyCreateChannel = (rawResult:
DeepPartial<IChannel>) => (removeUndefinedKeys({
    bot_token: rawResult.botToken,
    chat_id: rawResult.chatId,
    timezone: rawResult.timezone,
}));
