// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cLtI7zna{display:flex;column-gap:var(--p-1);padding:var(--p-0-75) var(--p-1-5);align-items:center;outline:none;border:0;background:none}.msI2h5uv{position:absolute;left:calc(100% + 8px);bottom:10px}.msI2h5uv svg{fill:var(--red-primary)}.ZK_gsFP2 svg{fill:var(--black-percent-88);width:14px;height:14px}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/control-error/ControlError.module.scss"],"names":[],"mappings":"AAAA,UACI,YAAA,CACA,qBAAA,CACA,kCAAA,CACA,kBAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA,CAGJ,UACI,iBAAA,CACA,qBAAA,CACA,WAAA,CAEA,cACI,uBAAA,CAMJ,cACI,4BAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".menuItem {\n    display: flex;\n    column-gap: var(--p-1);\n    padding: var(--p-0-75) var(--p-1-5);\n    align-items: center;\n    outline: none;\n    border: 0;\n    background: none;\n}\n\n.errorIcon {\n    position: absolute;\n    left: calc(100% + 8px);\n    bottom: 10px;\n\n    svg {\n        fill: var(--red-primary);\n    }\n}\n\n\n.menuItemIcon {\n    svg {\n        fill: var(--black-percent-88);\n        width: 14px;\n        height: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `cLtI7zna`,
	"errorIcon": `msI2h5uv`,
	"menuItemIcon": `ZK_gsFP2`
};
export default ___CSS_LOADER_EXPORT___;
