import { api } from 'shared/config/rtk-query-api/api.services';

import {
    transformBodyUpdateChannel,
    transformResponseGetChannel,
    transformResponseGetChannelList,
} from '../../adapters/serverData';
import {
    IChannel,
    IChannelListFromServer,
} from '../types/channels.types';

const channelsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getChannel: builder.query<IChannel, string>({
            query: (id) => `/api/v1/channels/${id}/`,
            transformResponse: transformResponseGetChannel,
        }),
        getChannelById: builder.query<IChannel, string>({
            providesTags: () => [{ type: 'Channel' }],
            query: (id) => `/api/v1/channels/${id}/`,
            transformResponse: transformResponseGetChannel,
        }),
        getChannels: builder.query<IChannelListFromServer, string>({
            providesTags: () => [{ type: 'Channels' }],
            query: (queryParam) => ({ method: 'GET', url: `/api/v1/channels/${queryParam}` }),
            transformResponse: transformResponseGetChannelList,
        }),
        updateChannel: builder.mutation({
            query: ({ id, ...channel }) => ({
                body: transformBodyUpdateChannel(channel),
                method: 'PUT',
                url: `/api/v1/channels/${id}`,
            }),
        }),
    }),
});

export const {
    useGetChannelByIdQuery,
    useGetChannelsQuery,
    useLazyGetChannelQuery,
    useUpdateChannelMutation,
} = channelsApi;
