import {
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { IStateSchema } from 'app/providers/store';

import {
    IEmployee,
    IEmployeeSchema,
} from '../types/employee.types';

const employeesAdapter = createEntityAdapter<IEmployee>({
    selectId: (employee) => employee.id,
    sortComparer:
    (employeeOne, employeeTwo) => (employeeTwo?.username || '') // TODO: разобраться по какому параметру сортировать
        .localeCompare(employeeOne?.username ?? ''),
});

export const getEmployeesFromSlice = employeesAdapter.getSelectors<IStateSchema>(
    (state) => state?.employees || employeesAdapter.getInitialState(),
);

export const employeeSlice = createSlice({
    initialState: employeesAdapter.getInitialState<IEmployeeSchema>({
        data: [],
        entities: {},
        ids: [],
    }),
    name: 'employees',
    reducers: {
        addEmployees(state, { payload: employees }: PayloadAction<IEmployee[]>) {
            employeesAdapter.addMany(state, employees);
        },
        clearDataEmployees(state) {
            employeesAdapter.removeAll(state);
        },
        deleteEmployee(state, { payload: employee }: PayloadAction<IEmployee>) {
            employeesAdapter.removeOne(state, employee.id);
        },
        setEmployees(state, { payload: employees }: PayloadAction<IEmployee[]>) {
            employeesAdapter.setAll(state, employees);
        },
        updateDataEmployees: employeesAdapter.updateOne,
    },
});

export const { reducer: employeesReducer } = employeeSlice;
export const { actions: employeesActions } = employeeSlice;
