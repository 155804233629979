// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._UquR9Wv{width:220px;object-fit:contain}._UquR9Wv img{width:100%}.legKQoKW{max-height:220px;object-fit:scale-down}.to6IYD8L{margin-left:0}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/variants/static-sticker/StaticSticker.module.scss"],"names":[],"mappings":"AAAA,UACI,WAAA,CACA,kBAAA,CAEA,cACI,UAAA,CAIR,UACI,gBAAA,CACA,qBAAA,CAGJ,UACI,aAAA","sourcesContent":[".staticSticker {\n    width: 220px;\n    object-fit: contain;\n\n    img {\n        width: 100%;\n    }\n}\n\n.imageStaticSticker {\n    max-height: 220px;\n    object-fit: scale-down;\n}\n\n.skeletonStaticSticker {\n    margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"staticSticker": `_UquR9Wv`,
	"imageStaticSticker": `legKQoKW`,
	"skeletonStaticSticker": `to6IYD8L`
};
export default ___CSS_LOADER_EXPORT___;
