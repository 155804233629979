import { transformRole } from 'entities/role';
import { removeUndefinedKeys } from 'shared/lib/utils/object/object';

import {
    IUpdateUserSettings,
    IUserFromServer,
    IUserInfoFromServer,
} from '../model/types/user.types';

export const transformResponseGetUserSettings = async (rawResult:
IUserInfoFromServer) => ({
    channelCount: rawResult.channel_count,
    disableSyncAlert: rawResult?.settings?.disable_sync_alert || false,
    firstName: rawResult.first_name,
    hasRole: rawResult.has_role,
    id: rawResult.id,
    lastName: rawResult.last_name,
    photo: rawResult.photo,
    role: transformRole(rawResult.role),
    settings: { disableSyncAlert: rawResult?.settings?.disable_sync_alert || false },
    userName: rawResult.username,
});

export const transformBodyUpdateUserSettings = (rawResult:
IUpdateUserSettings) => {
    const disableSyncAlert = rawResult?.settings?.disableSyncAlert;

    const settings = typeof disableSyncAlert !== 'undefined'
        ? { disable_sync_alert: rawResult?.settings?.disableSyncAlert || false }
        : undefined;

    return removeUndefinedKeys({
        first_name: rawResult.firstName,
        last_name: rawResult.lastName,
        settings,
        username: rawResult.userName,
    });
};

export const transformResponseAuthData = async (rawResult:
IUserFromServer) => ({
    email: rawResult.email,
    firstName: rawResult.first_name,
    lastName: rawResult.last_name,
    photo: rawResult.photo,
    pk: rawResult.pk,
    username: rawResult.username,
});
