import {
    FC,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import Avatar from 'antd/es/avatar/avatar';
import { IEmployee } from 'entities/employee';
import { getIsRoleAssignment } from 'entities/user';
import { ChangeEmployeeRole } from 'features/change-employee-role';
import TelegramIcon from 'shared/assets/icons/telegramWithBg.svg';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './EmployeeCard.module.scss';

interface IEmployeeCardProps {
    className?: string;
    openModalOnDelete: (name: IEmployee) => void;
    employee: IEmployee;
}

export const EmployeeCard: FC<IEmployeeCardProps> = memo((props) => {
    const {
        className, employee, openModalOnDelete,
    } = props;

    const isRoleAssignment = useSelector(getIsRoleAssignment);

    const [roleName, setRoleName] = useState('Менеджер');

    const [isOpenModalOnChangeRole, setIsOpenModalOnChangeRole] = useState(false);

    const toggleChangeRoleModal = useCallback(() => {
        setIsOpenModalOnChangeRole((prev) => !prev);
    }, []);

    const handleOpenModalOnChangeRole = useCallback(() => {
        toggleChangeRoleModal();
    }, []);

    const handleDelete = useCallback(() => {
        openModalOnDelete(employee);
    }, []);
    const handleChangeRole = useCallback(() => {
        handleOpenModalOnChangeRole();
    }, []);

    useEffect(() => {
        setRoleName(employee?.role?.name ?? '');
    }, [employee]);

    return (
        <div className={classNames(cls.employeeCard, {}, [className])}>
            <Flex className={cls.topBlock} justify="space-between">
                <Flex className={cls.imageTextBlock} gaps="12">
                    <Avatar
                        className={cls.avatar}
                        icon={<UserOutlined className={cls.iconUser} />}
                        alt="avatar"
                        shape="square"
                        src={employee.photo}
                    />

                    <p className={cls.infoBlock}>
                        <Text text={employee.firstName} className={cls.name} withEllipsis />
                        <Text
                            href={`https://t.me/${employee?.username.substring(1)}`}
                            as="a"
                            text={employee?.username}
                            className={cls.username}
                            withEllipsis
                            isAnotherTag
                        />
                    </p>
                </Flex>

                {isRoleAssignment && (
                    <WrappedIcon
                        onClick={handleDelete}
                        Icon={DeleteOutlined}
                        theme="gray"
                        size="20"
                        cursorPointer
                    />
                )}
            </Flex>

            <Flex className={cls.bottomBlock} justify="space-between">
                <Flex gaps="8" className={cls.role}>
                    <Text text={roleName} size="sm" />
                    {isRoleAssignment && (
                        <WrappedIcon
                            onClick={handleChangeRole}
                            Icon={FormOutlined}
                            theme="blue"
                            size="20"
                            cursorPointer
                        />
                    )}
                </Flex>

                <Flex gaps="4" className={cls.channelsCount}>
                    <WrappedIcon Icon={TelegramIcon} width={18} />
                    <Text text={employee.channelCount} size="sm" />
                </Flex>
            </Flex>

            <ChangeEmployeeRole
                employee={employee}
                isOpen={isOpenModalOnChangeRole}
                toggleOpen={toggleChangeRoleModal}
                roleName={roleName}
                setRoleName={setRoleName}
            />
        </div>
    );
});
