// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ydPIMyZf{padding-top:30vh;width:100%;text-align:center;font:var(--font-xl)}`, "",{"version":3,"sources":["webpack://./src/pages/not-found/ui/NotFoundPage.module.scss"],"names":[],"mappings":"AAAA,UACI,gBAAA,CACA,UAAA,CACA,iBAAA,CACA,mBAAA","sourcesContent":[".notFoundPage {\n    padding-top: 30vh;\n    width: 100%;\n    text-align: center;\n    font: var(--font-xl);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFoundPage": `ydPIMyZf`
};
export default ___CSS_LOADER_EXPORT___;
