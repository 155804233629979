import {
    FC,
    memo,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './GoogleLoader.module.scss';

interface IGoogleLoaderProps {
    className?: string;
}

export const GoogleLoader: FC<IGoogleLoaderProps> = memo((props) => {
    const { className } = props;

    return (

        <div className={classNames(cls.loader, {}, [className])}>
            <svg className={cls.circular} viewBox="25 25 50 50">
                <circle className={cls.path} cx="50" cy="50" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10" />
            </svg>
        </div>

    );
});
