import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'antd';

import {
    getUserAuth,
    getAuthError,
} from 'entities/user';
import TelegramIcon from 'shared/assets/icons/telegram.svg';
import {
    IS_AUTH_LOCAL_KEY,
    IS_AUTH_LOCAL_VALUE,
} from 'shared/constants/auth';
import { LocalStore } from 'shared/lib/utils/localstore/localstore';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';

import {
    useGetTelegramConfigQuery,
} from '../model/services/auth.service';

import cls from './AuthPage.module.scss';

export const AuthPage = () => {
    const { data: configTelegram } = useGetTelegramConfigQuery();
    const userAuth = useSelector(getUserAuth);
    const authError = useSelector(getAuthError);
    const telegramWindow = useRef<Window | null>(null);
    const [user, setUser] = useState();
    const [user2, setUser2] = useState();
    const [user3, setUser3] = useState();
    const [user4, setUser4] = useState();
    const [user5, setUser5] = useState();
    const [user6, setUser6] = useState();

    const handleClick = useCallback(() => {
        const url = `https://t.me/${configTelegram?.tgAuthBotUsername}?start=auth`;
        telegramWindow.current = window.open(url, '_blank', 'top=100, left=100, width=400, height=500');
    }, [configTelegram]);

    if (userAuth) {
        return null;
    }

    useEffect(() => {
        console.log('USING TELEGRAM SERVICE');
        // @ts-ignore
        const tg = window.Telegram.WebApp;
        tg.ready();
        setUser(tg);

        setUser2(tg?.username);
        setUser4(tg?.user);
        setUser3(tg?.WebAppUser?.username);

        // tg?.showAlert(`Добро пожаловать, @${tg?.WebAppUser?.username}. ${tg?.username}.`);

        console.log('USING TELEGRAM USER', user);
    }, []);

    useEffect(() => {
        const handleAuthorization = (event: StorageEvent) => {
            if (event.key === IS_AUTH_LOCAL_KEY) {
                const isAuthorizedValue = LocalStore.get(IS_AUTH_LOCAL_KEY) === IS_AUTH_LOCAL_VALUE;

                if (isAuthorizedValue) {
                    LocalStore.remove(IS_AUTH_LOCAL_KEY);
                    telegramWindow?.current?.close();
                    telegramWindow.current = null;
                    window.location.reload();
                }
            }
        };

        window.addEventListener('storage', handleAuthorization);

        () => {
            window.removeEventListener('storage', handleAuthorization);
        };
    }, []);

    return (
        <MainLayouts isAuthPage>
            <div className={cls.authPage}>
                <div className={cls.content}>
                    <h3 className={cls.title}>Войти</h3>
                    <p className={cls.text}>
                        Организуйте все ваши каналы на одной платформе.
                        Это упростит и ускорит управление множеством
                        Telegram каналов!
                    </p>

                    <p style={{ wordBreak: 'break-all' }}>
                        Инфа:
                        {JSON.stringify(user)}
                    </p>
                    <p style={{ wordBreak: 'break-all' }}>
                        юзер:
                        {JSON.stringify(user2)}
                        {JSON.stringify(user3)}
                        {JSON.stringify(user4)}
                    </p>

                    {configTelegram?.tgAuthBotUsername && (
                        <Button
                            type="primary"
                            size="large"
                            block
                            className={cls.button}
                            onClick={handleClick}
                        >
                            <TelegramIcon />
                            Войти с помощью Telegram
                        </Button>
                    )}

                    {authError && (
                        <p className={cls.error}>
                            Пользователь не авторизован,
                            <br />
                            обратитесь к администратору
                        </p>
                    )}
                </div>
            </div>
        </MainLayouts>
    );
};
