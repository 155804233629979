export enum EAppRoutes {
    MAIN = 'main',
    CHANNEL = 'channel',
    AUTH = 'auth',
    CREATE_CHANNEL = 'create_channel',
    EDIT_CHANNEL = 'edit_channel',
    TELEGRAM_AUTH = 'telegram_auth',
    EMPLOYEE_MANAGEMENT = 'employee_management',
    CREATE_ROLE = 'create_role',
    EDIT_ROLE = 'edit_role',
    ROLES_MANAGEMENT = 'roles_management',
    DOCUMENTATION = 'documentation',

    NOT_FOUND = 'not_found',
}

export const HOME_URL = '/';
export const CHANNEL_URL = '/channels';
export const AUTH_URL = '/auth';
export const CREATE_CHANNEL_URL = '/create-channel';
export const EDIT_CHANNEL_URL = '/edit-channel';
export const CREATE_ROLE_URL = '/create-role';
export const EDIT_ROLE_URL = '/edit-role';
export const TELEGRAM_AUTH_URL = '/telegram-auth';
export const EMPLOYEE_MANAGEMENT_URL = '/employee-management';
export const ROLES_MANAGEMENT_URL = '/roles-management';
export const DOCUMENTATION_URL = '/documentation';

export const RoutePath: Record<EAppRoutes, string> = {
    [EAppRoutes.MAIN]: HOME_URL,
    [EAppRoutes.CHANNEL]: `${CHANNEL_URL}/:id`,
    [EAppRoutes.AUTH]: AUTH_URL,
    [EAppRoutes.CREATE_CHANNEL]: CREATE_CHANNEL_URL,
    [EAppRoutes.EDIT_CHANNEL]: `${EDIT_CHANNEL_URL}/:id`,
    [EAppRoutes.TELEGRAM_AUTH]: `${TELEGRAM_AUTH_URL}/:token`,
    [EAppRoutes.EMPLOYEE_MANAGEMENT]: EMPLOYEE_MANAGEMENT_URL,
    [EAppRoutes.CREATE_ROLE]: CREATE_ROLE_URL,
    [EAppRoutes.EDIT_ROLE]: `${EDIT_ROLE_URL}/:id`,
    [EAppRoutes.ROLES_MANAGEMENT]: ROLES_MANAGEMENT_URL,
    [EAppRoutes.DOCUMENTATION]: DOCUMENTATION_URL,
    [EAppRoutes.NOT_FOUND]: '*',
};
