// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WX1UoJEk{margin-top:var(--p-3)}`, "",{"version":3,"sources":["webpack://./src/widgets/edit-message/ui/save-message/sync-messages-modal/SyncMessagesModal.module.scss"],"names":[],"mappings":"AAEA,UACI,qBAAA","sourcesContent":["@use \"app/styles/mixins/mixins\";\n\n.footer {\n    margin-top: var(--p-3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `WX1UoJEk`
};
export default ___CSS_LOADER_EXPORT___;
