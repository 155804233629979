import {
    FC,
    memo,
    useMemo,
} from 'react';

import { Input } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import { EBehaviorScroll } from 'shared/constants/scroll';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { MiniCalendar } from 'shared/ui/mini-сalendar/MiniCalendar';

import { useSearchMessage } from '../hooks/useSearchMessage/useSearchMessage';

import cls from './SearchMessages.module.scss';

interface ISearchMessagesProps {
    show: boolean;
    setShow: (value: boolean) => void;
    channelId: string;
    handleShowMessage: (id: string, page?: string, behaviorScroll?: EBehaviorScroll) => void;
}

export const SearchMessages: FC<ISearchMessagesProps> = memo((props) => {
    const {
        channelId, handleShowMessage, setShow, show,
    } = props;

    const { isMobile } = useScreenType();

    const {
        dateSearch,
        disabledDownArrow,
        disabledUpArrow,
        handleChangeDate,
        handleChangeSearch,
        handleClearSearch,
        handleCloseSearchContent,
        handleDownSeachMessage,
        handleUpSeachMessage,
        isLoading,
        search,
    } = useSearchMessage({
        channelId,
        handleShowMessage,
        setShow,
        show,
    });

    const suffixInput = useMemo(() => {
        if (isLoading && search) {
            return <LoadingOutlined spin className={cls.loader} />;
        }

        if (search) {
            return <CloseCircleFilled className={cls.closeInputIcon} onClick={handleClearSearch} />;
        }

        return null;
    }, [search, isLoading]);

    const moveMessages = (
        <div className={cls.moveMessages}>
            <UpOutlined
                className={classNames(cls.moveMessagesArrow, {
                    [cls.disabledMoveMessagesArrow]: disabledUpArrow,
                })}
                onClick={handleUpSeachMessage}
            />
            <DownOutlined
                className={classNames(cls.moveMessagesArrow, {
                    [cls.disabledMoveMessagesArrow]: disabledDownArrow,
                })}
                onClick={handleDownSeachMessage}
            />
        </div>
    );

    const componentInput = (
        <Input
            placeholder="Поиск по истории сообщений"
            prefix={<SearchOutlined className={cls.searchInputIcon} />}
            suffix={suffixInput}
            className={cls.search}
            value={search}
            onChange={handleChangeSearch}
        />
    );

    if (!show) {
        return null;
    }

    if (isMobile) {
        return (
            <>
                <div className={cls.container}>
                    {componentInput}
                    <CloseOutlined className={cls.closeIcon} onClick={handleCloseSearchContent} />
                </div>
                <div className={cls.mobileFooter}>
                    <MiniCalendar date={dateSearch} onChange={handleChangeDate} />
                    {moveMessages}
                </div>
            </>
        );
    }

    return (
        <div className={cls.container}>
            {moveMessages}
            {componentInput}
            <MiniCalendar date={dateSearch} onChange={handleChangeDate} />
            <CloseOutlined className={cls.closeIcon} onClick={handleCloseSearchContent} />
        </div>
    );
});
