import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import App from 'app/App';
import { StoreProvider } from 'app/providers/store/ui/StoreProvider';
import { ErrorPage } from 'pages/error-page';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <StoreProvider>
            <BrowserRouter>
                <ErrorBoundary fallback={<ErrorPage />}>
                    <App />
                </ErrorBoundary>
            </BrowserRouter>
        </StoreProvider>
    </React.StrictMode>,

);
