import {
    FC,
    memo,
    ReactNode,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './Heading.module.scss';

type THeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type THeadingAlgin = 'left' | 'center' | 'right';
type TMargins = '4' | '8' | '12' | '16' | '24';

interface IHeadingProps {
    className?: string;
    text: string | ReactNode;
    align?: THeadingAlgin;
    as?: THeadingVariants;
    mB?: TMargins;
    max?: boolean;
}

export const Heading: FC<IHeadingProps> = memo((props) => {
    const {
        align = 'left', as = 'h3', className, mB,
        max, text,
    } = props;

    const Tag = as;

    const classes = [
        className,
        cls[as],
        cls[align],
        mB && cls[`mB-${mB}`],
    ];

    return (
        <Tag className={classNames('', { [cls.max]: max }, classes)}>
            {text}
        </Tag>
    );
});
