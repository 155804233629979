import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useCallback,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Modal,
} from 'antd';

import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { EModalWidth } from 'shared/constants/modal-sizes';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { DynamicHeader } from 'shared/ui/dynamic-header/DynamicHeader';
import { Flex } from 'shared/ui/flex/Flex';
import { FullscreenContainer } from 'shared/ui/fullscreen-container/FullscreenContainer';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import { CustomUploadFile } from '../model/types/editMessage.types';

import { EditMessageContentAsync } from './EditMessageContent.async';
import { SaveMessage } from './save-message/SaveMessage';

import cls from './EditMessage.module.scss';
import './EditMessage.scss';

interface IEditMessageProps {
    open: boolean;
    toggleModal: () => void;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
}

export const EditMessage: FC<IEditMessageProps> = memo((props) => {
    const {
        handleReloadChannels, open, setIsUpdateChannels, toggleModal,
    } = props;

    const { isTablet } = useScreenType();

    const [localFileList, setLocalFileList] = useState<CustomUploadFile[]>([]);

    const handleCancelModal = useCallback(() => {
        toggleModal();
        setLocalFileList([]);
    }, []);

    const handleAfterAnimation = useCallback((isNotEnded: boolean) => {
        if (!isNotEnded) {
            setLocalFileList([]);
        }
    }, [setLocalFileList]);

    const footer = useMemo(
        () => [
            <Button key="back" onClick={toggleModal}>
                Отменить
            </Button>,
            <SaveMessage
                key="save"
                cancelModal={toggleModal}
                handleReloadChannels={handleReloadChannels}
                setIsUpdateChannels={setIsUpdateChannels}
            />,
        ],
        [],
    );

    if (isTablet && open) {
        return (
            <FullscreenContainer>
                <DynamicHeader
                    className={cls.header}
                    leftBlock={(
                        <LeftOutlined
                            className={cls.backIcon}
                            onClick={handleCancelModal}
                        />
                    )}
                    title="Редактирование сообщения"
                />
                <Flex
                    className={cls.contentWrapper}
                    gaps="16"
                    max
                    direction="column"
                    align="start"
                >
                    <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                        <EditMessageContentAsync setLocalFileList={setLocalFileList} localFileList={localFileList} />

                        <Flex className={cls.btnsModalControl} max gaps="8">
                            <Button size="large" block key="back" onClick={toggleModal}>
                                Отменить
                            </Button>

                            <SaveMessage
                                key="save"
                                cancelModal={toggleModal}
                                handleReloadChannels={handleReloadChannels}
                                setIsUpdateChannels={setIsUpdateChannels}
                                isTablet={isTablet}
                            />

                        </Flex>
                    </Suspense>
                </Flex>
            </FullscreenContainer>
        );
    }

    return (
        <Modal
            open={open}
            centered
            title="Редактирование сообщения"
            onCancel={toggleModal}
            className={cls.editMessageModal}
            wrapClassName={cls.editMessageModalWrapper}
            footer={footer}
            destroyOnClose
            getContainer={false}
            width={EModalWidth.width700}
            afterOpenChange={handleAfterAnimation}
        >
            <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                <EditMessageContentAsync localFileList={localFileList} setLocalFileList={setLocalFileList} />
            </Suspense>
        </Modal>
    );
});
