import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Pagination } from 'antd';

import {
    IEmployee,
    getEmployeesFromSlice,
} from 'entities/employee';
import { DeleteEmployeeModal } from 'features/delete-employee';
import { LIMIT_PAGINATION } from 'pages/employee-management';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { EmptyTable } from 'shared/ui/empty-table/EmptyTable';
import { Flex } from 'shared/ui/flex/Flex';

import { EmployeeCard } from './card/EmployeeCard';

import cls from './EmployeeManagementContentMobile.module.scss';

interface IManagementContentMobileProps {
    className?: string;
    isLoading: boolean;
    setOffset: Dispatch<SetStateAction<number>>;
    count: number;
    search: string;
}

export const EmployeeManagementContentMobile: FC<IManagementContentMobileProps> = (props) => {
    const {
        className, count, isLoading, search, setOffset,
    } = props;

    const [isOpenModalOnDelete, setIsOpenModalOnDelete] = useState(false);
    const [chosenEmployee, setEmployee] = useState<IEmployee>();

    const employees = useSelector(getEmployeesFromSlice.selectAll);

    const toggleDeleteModal = useCallback(() => {
        setIsOpenModalOnDelete((prev) => !prev);
    }, []);

    const handleOpenModalOnDelete = useCallback((employee: IEmployee) => {
        setEmployee(employee);
        toggleDeleteModal();
    }, []);

    const changePage = useCallback((page: number) => {
        setOffset((page - 1) * LIMIT_PAGINATION);
    }, [setOffset]);

    return (
        <div className={cls.container}>
            <div className={classNames(cls.managementContentMobile, {}, [className])}>
                <Flex direction="column" gaps="8">
                    {employees.map((el) => (
                        <EmployeeCard
                            employee={el}
                            openModalOnDelete={handleOpenModalOnDelete}
                            key={el.id}
                        />
                    ))}
                </Flex>
            </div>

            {employees.length === 0 && !isLoading
                    && (
                        <EmptyTable search={search} className={cls.emptyContent} />
                    )}

            <DeleteEmployeeModal
                employee={chosenEmployee}
                isOpen={isOpenModalOnDelete}
                toggleModal={toggleDeleteModal}
            />

            {employees.length !== 0 && (
                <Pagination
                    total={count}
                    defaultCurrent={1}
                    pageSize={LIMIT_PAGINATION}
                    onChange={changePage}
                    className={cls.pagination}
                />
            )}

        </div>
    );
};
