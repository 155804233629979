import {
    Dispatch,
    FC,
    SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';

import Table from 'antd/es/table';
import { getEmployeesFromSlice } from 'entities/employee';
import { LIMIT_PAGINATION } from 'pages/employee-management';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { EmptyTable } from 'shared/ui/empty-table/EmptyTable';

import { employeeDisplayColumns } from '../constants/employee-display-columns.constant';

import cls from './EmployeeManagementContent.module.scss';

interface IEmployeeManagementContentProps {
    isLoading: boolean;
    count: number;
    setOffset: Dispatch<SetStateAction<number>>;
    search: string;
}

export const EmployeeManagementContent: FC<IEmployeeManagementContentProps> = (props) => {
    const {
        count, isLoading, search, setOffset,
    } = props;

    const employees = useSelector(getEmployeesFromSlice.selectAll);

    const changePage = (page: number) => {
        setOffset((page - 1) * LIMIT_PAGINATION);
    };

    return (
        <Table
            loading={isLoading}
            bordered={false}
            columns={employeeDisplayColumns()}
            dataSource={employees}
            className={classNames(cls.table, { [cls.tableEmpty]: employees?.length === 0 })}
            rowKey="id"
            rowClassName={cls.rowsWrapper}
            pagination={{
                className: cls.pagination,
                defaultCurrent: 1,
                onChange: changePage,
                pageSize: LIMIT_PAGINATION,
                position: ['bottomLeft'],
                total: count,
            }}
            locale={{
                emptyText: (
                    <EmptyTable search={search} />
                ),
            }}
        />
    );
};
