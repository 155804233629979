import React, {
    FC,
} from 'react';

import {
    Button,
} from 'antd';

import cls from './FooterCalendar.module.scss';

interface IFooterCalendarProps {
    onToday: () => void;
}

export const FooterCalendar: FC<IFooterCalendarProps> = (props) => {
    const {
        onToday,
    } = props;

    return (
        <div className={cls.footer}>
            <Button
                onClick={onToday}
                type="link"
                block
            >
                Today
            </Button>
        </div>
    );
};
