import { FC } from 'react';

import { Tooltip } from 'antd';

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { WrappedIcon } from 'shared/ui/wrapped-icon/WrappedIcon';

import { FAKE_TITLE_TEXT } from '../../../constants/text.constant';

import cls from './TooltipInfo.module.scss';

interface ITooltipInfoProps {
    className?: string;
    text?: string;
}

const BG_COLOR = '#E6F4FF';

export const TooltipInfo: FC<ITooltipInfoProps> = (props) => {
    const { className, text = FAKE_TITLE_TEXT } = props;

    return (
        <Tooltip
            overlayClassName={cls.overlayTooltip}
            color={BG_COLOR}
            className={classNames(cls.tooltipWrapper, {}, [className])}
            title={text}
        >
            <WrappedIcon Icon={QuestionCircleOutlined} theme="black-45" />
        </Tooltip>
    );
};
