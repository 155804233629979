/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    Dispatch,
    MouseEvent,
    SetStateAction,
    VideoHTMLAttributes,
    forwardRef,
} from 'react';

import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './VideoWithLoader.module.scss';

interface IVideoWithLoaderProps extends VideoHTMLAttributes<HTMLVideoElement> {
    className?: string;
    src: string;
    classNameSkeleton?: string;
    onClick?: (e: MouseEvent<HTMLVideoElement>) => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isLoading: boolean;
    isFullscreen?: boolean;
}

export const VideoWithLoader = forwardRef<HTMLVideoElement, IVideoWithLoaderProps>((props, ref) => {
    const {
        className, classNameSkeleton, isFullscreen, isLoading, onClick,
        setIsLoading,
        src, ...otherProps
    } = props;

    const { isAndroid } = useDeviceDetect();

    return (
        <div className={classNames(cls.videoContainer, {}, [className])}>
            {isLoading && <Skeleton className={classNameSkeleton} border="16px" height="100%" width="100%" /> }

            <video
                style={{ display: isLoading ? 'none' : 'block' }}
                src={src}
                ref={ref}
                muted
                onLoadedData={() => setIsLoading(false)}
                onClick={onClick}
                preload="metadata"
                playsInline
                className={classNames('', { [cls.androidVideo]: isAndroid && isFullscreen })}
                {...otherProps}
            />
        </div>
    );
});
