import {
    FC,
    memo,
} from 'react';

import {
    Button,
} from 'antd';

import PlusOutlined from '@ant-design/icons/PlusOutlined';

interface ICreateVariableProps {
    createVariable: () => void;
    newVariableName: string;
    newVariableValue : string;
    errorName: string;
}

export const CreateVariable: FC<ICreateVariableProps> = memo((props) => {
    const {
        createVariable, errorName,
        newVariableName, newVariableValue,
    } = props;

    return (
        <Button
            onClick={createVariable}
            type="primary"
            icon={<PlusOutlined />}
            disabled={!newVariableName || !newVariableValue || !!errorName}
            size="large"
        />
    );
});
