import {
    FC,
    useEffect,
    useState,
} from 'react';
import { Options } from 'react-lottie';
import { useSelector } from 'react-redux';

import axios from 'axios';

import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { cloneObject } from 'shared/lib/utils/object/object';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import { getCacheAnimationSticker } from '../../../model/selectors/messages.selectors';
import { messagesActions } from '../../../model/slice/messages.slice';
import { IFile } from '../../../model/types/message.types';

interface IStickersMessageProps {
    file: IFile;
}

export const AnimationStickerMessage: FC<IStickersMessageProps> = (props) => {
    const { file } = props;

    const fileName = file.file;

    const dispatch = useAppDispatch();

    const [Lottie, setLottie] = useState<any>(null);

    const cacheAnimationSticker = useSelector(getCacheAnimationSticker);
    const [animationData, setAnimationData] = useState<Options | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const loadAnimationData = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(String(fileName));
            setAnimationData(data);
            const dataAnimation = cloneObject(data) as Options;
            dispatch(messagesActions.updateCacheAnimationSticker({ file: dataAnimation, name: fileName }));
            setIsLoading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error');
        }
    };

    useEffect(() => {
        if (!cacheAnimationSticker?.[fileName]) {
            loadAnimationData();
        } else {
            const dataAnimation = cloneObject(cacheAnimationSticker[fileName]) as Options;
            setAnimationData(dataAnimation);
        }
    }, [fileName]);

    useEffect(() => {
        import('react-lottie').then((LottieModule: any) => {
            setLottie(() => LottieModule.default);
        });
    }, []);

    if (isLoading || !animationData || !Lottie) {
        return <Skeleton width={250} height={250} border="16px" />;
    }

    return (
        <Lottie
            options={{
                animationData,
                autoplay: true,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            }}
            isClickToPauseDisabled
            width={250}
            height={250}
        />
    );
};
