import React, {
    FC,
} from 'react';

import { EHintUpdatePost } from 'features/import-messages';
import { useGetInfoHint } from 'pages/channel/hooks/useGetInfoHint';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import cls from './HintUpdatePost.module.scss';

interface IHintUpdatePostProps {
    status: EHintUpdatePost | null;
}

export const HintUpdatePost: FC<IHintUpdatePostProps> = (props) => {
    const { status = EHintUpdatePost.UPDATED } = props;

    const {
        button,
        icon,
        text,
        title,
    } = useGetInfoHint({ status });

    if (!status) {
        return null;
    }

    return (
        <>
            <div className={cls.hidingBlock} />
            <div className={cls.holderHint}>
                <div className={classNames(cls.container, { [cls.containerError]: status === EHintUpdatePost.ERROR })}>
                    {icon}
                    <Text text={title} size="lg" weight="600" theme="black" className={cls.title} />
                    <Text text={text} theme="black" align="center" />
                    {button}
                </div>
            </div>
        </>
    );
};
