// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jX87KXA0{width:100%;height:var(--mini-header-height);background:var(--white-primary);padding:var(--p-1) var(--p-3);display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid var(--black-percent-06);position:relative;z-index:1}`, "",{"version":3,"sources":["webpack://./src/shared/ui/mini-header/MiniHeader.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,gCAAA,CACA,+BAAA,CACA,6BAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,+CAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".miniHeader {\n    width: 100%;\n    height: var(--mini-header-height);\n    background: var(--white-primary);\n    padding: var(--p-1) var(--p-3);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid var(--black-percent-06);\n    position: relative;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniHeader": `jX87KXA0`
};
export default ___CSS_LOADER_EXPORT___;
