import {
    Dispatch,
    FC,
    SetStateAction,
    memo,
    useCallback,
    useMemo,
} from 'react';

import {
    Button,
    Dropdown,
    FormInstance,
    MenuProps,
} from 'antd';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';
import {
    IChannel,
    IVariable,
} from 'entities/channels';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';
import {
    WrappedIcon,
} from 'shared/ui/wrapped-icon/WrappedIcon';

import { CHANNEL_FORM_FIELDS } from '../../../../../constants/form-fields.constant';
import cls from '../../VariablesBlock.module.scss';

interface IDisplayItemProps {
    variable: IVariable;
    getAllVariables: () => IVariable[];
    form: FormInstance<IChannel>;
    setIsChanging: Dispatch<SetStateAction<boolean>>;
    triggerUpdateVariables: () => void;
    isTablet: boolean;
}

export const DisplayItem: FC<IDisplayItemProps> = memo((props) => {
    const {
        form, getAllVariables,
        isTablet, setIsChanging, triggerUpdateVariables,
        variable,
    } = props;

    const toggleIsEditable = useCallback(() => {
        setIsChanging((prev) => !prev);
    }, [setIsChanging]);

    const removeVariable = useCallback(() => {
        const variablesWithoutOne = getAllVariables()
            .filter((variableItem: IVariable) => variableItem.name !== variable.name);

        form.setFieldValue(CHANNEL_FORM_FIELDS.VARIABLES, variablesWithoutOne);
        triggerUpdateVariables();
    }, [getAllVariables, variable, triggerUpdateVariables, form]);

    const items: MenuProps['items'] = useMemo(() => [
        {
            key: 'edit',
            label: (
                <Flex className={cls.btnControl} justify="space-between" onClick={toggleIsEditable}>
                    Изменить

                    <WrappedIcon size="20" Icon={FormOutlined} />
                </Flex>
            ),
        },
        {
            type: 'divider',
        },
        {
            key: 'delete',
            label: (
                <Flex className={classNames(cls.btnControl, {}, [cls.delete])} justify="space-between" onClick={removeVariable}>
                    Удалить

                    <WrappedIcon size="20" Icon={DeleteOutlined} theme="error" />
                </Flex>
            ),
        },

    ], []);

    return (
        <Flex gaps="12" justify="space-between" className={cls.variableItem} max>
            <Text className={`${cls.variable} ${cls.left}`} text={variable.name} />
            <Flex className={cls.right} max>
                <Text className={cls.variableValue} text={variable.value} />
                { isTablet
                    ? (
                        <Dropdown menu={{ items }}>
                            <div>
                                <WrappedIcon size="20" Icon={MoreOutlined} />
                            </div>
                        </Dropdown>
                    )
                    : (
                        <Flex justify="space-between" className={cls.buttons}>
                            <Button
                                className="withIcon"
                                onClick={toggleIsEditable}
                                type="link"
                                icon={(
                                    <WrappedIcon
                                        size="20"
                                        Icon={FormOutlined}
                                        theme="gray"
                                    />
                                )}
                            />
                            <Button
                                className="withIcon error"
                                onClick={removeVariable}
                                disabled={variable?.isUsed}
                                type="link"
                                icon={(
                                    <WrappedIcon
                                        Icon={DeleteOutlined}
                                        size="20"
                                        theme="error"
                                        disabled={variable?.isUsed}
                                    />
                                )}
                            />
                        </Flex>
                    )}
            </Flex>
        </Flex>
    );
});
