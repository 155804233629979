import { FC } from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './FullscreenContainer.module.scss';

interface IFullscreenContainerProps {
    className?: string;
    children: React.ReactNode;
}

export const FullscreenContainer: FC<IFullscreenContainerProps> = (props) => {
    const { children, className } = props;

    return (
        <div className={classNames(cls.fullscreenContainer, {}, [className])}>
            {children}
        </div>
    );
};
