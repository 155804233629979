// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aVkENY0g,.ySj4ObwL,.mW9mvuCX{font:var(--font-lg);font-weight:var(--font-semibold)}.u7U5bSuK,.hTjCo4Ym,.mTQPGB_s{font:var(--font-md);font-weight:var(--font-semibold)}.hIQ3zmCy{font:var(--font-xl)}.fvtykI8M{margin-bottom:var(--p-0-5)}.tdoR6lo2{margin-bottom:var(--p-1)}.phXBpNyr{margin-bottom:var(--p-1-5)}.MDJ3Py9S{margin-bottom:var(--p-2)}.E4DRuf6L{margin-bottom:var(--p-3)}.omIIQiYG{text-align:left}.nfbzpnPA{text-align:center}.RvPk0xdQ{text-align:right}.FiKqd3jD{width:100%}`, "",{"version":3,"sources":["webpack://./src/shared/ui/heading/Heading.module.scss"],"names":[],"mappings":"AAAA,8BACI,mBAAA,CACA,gCAAA,CAGJ,8BACI,mBAAA,CACA,gCAAA,CAGJ,UACI,mBAAA,CAGJ,UACI,0BAAA,CAGJ,UACI,wBAAA,CAGJ,UACI,0BAAA,CAGJ,UACI,wBAAA,CAGJ,UACI,wBAAA,CAGJ,UACI,eAAA,CAGJ,UACI,iBAAA,CAGJ,UACI,gBAAA,CAGJ,UACI,UAAA","sourcesContent":[".h2, .h3, .h4 {\n    font: var(--font-lg);\n    font-weight: var(--font-semibold);\n}\n\n.h5, .h6, .h7 {\n    font: var(--font-md);\n    font-weight: var(--font-semibold);\n}\n\n.h1 {\n    font: var(--font-xl);\n}\n\n.mB-4 {\n    margin-bottom: var(--p-0-5);\n}\n\n.mB-8 {\n    margin-bottom: var(--p-1);\n}\n\n.mB-12 {\n    margin-bottom: var(--p-1-5);\n}\n\n.mB-16 {\n    margin-bottom: var(--p-2);\n}\n\n.mB-24 {\n    margin-bottom: var(--p-3);\n}\n\n.left {\n    text-align: left;\n}\n\n.center {\n    text-align: center;\n}\n\n.right {\n    text-align: right;\n}\n\n.max {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2": `aVkENY0g`,
	"h3": `ySj4ObwL`,
	"h4": `mW9mvuCX`,
	"h5": `u7U5bSuK`,
	"h6": `hTjCo4Ym`,
	"h7": `mTQPGB_s`,
	"h1": `hIQ3zmCy`,
	"mB-4": `fvtykI8M`,
	"mB-8": `tdoR6lo2`,
	"mB-12": `phXBpNyr`,
	"mB-16": `MDJ3Py9S`,
	"mB-24": `E4DRuf6L`,
	"left": `omIIQiYG`,
	"center": `nfbzpnPA`,
	"right": `RvPk0xdQ`,
	"max": `FiKqd3jD`
};
export default ___CSS_LOADER_EXPORT___;
