import React, {
    FC,
    useEffect,
} from 'react';

import { Slider } from 'antd';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import PauseOutlined from '@ant-design/icons/PauseOutlined';
import MutedIcon from 'shared/assets/icons/mute.svg';
import VolumeIcon from 'shared/assets/icons/volume.svg';
import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import { useAudioControls } from '../hooks/useAudioControls';

import cls from './GlobalAudioChannel.module.scss';

interface IGlobalAudioChannelProps {
    isPinnedPost?: boolean;
}

export const GlobalAudioChannel: FC<IGlobalAudioChannelProps> = (props) => {
    const { isPinnedPost } = props;
    const { isAndroid, isIOS } = useDeviceDetect();

    const {
        audioRef,
        closeAudioFile,
        date,
        fileData,
        handleAudioEnded,
        handleMouseLeave,
        handleMouseMove,
        handleOnAudioClick,
        handleToggleVolume,
        holderContentRef,
        initAudioSettings,
        isPlaying,
        name,
        onChangeVolume,
        openVolume,
        setOpenVolume,
        volume,
    } = useAudioControls();

    const { isTablet } = useScreenType();

    useEffect(() => {
        function handleDocumentClick(event: MouseEvent) {
            const target = event.target as Node;
            if (holderContentRef.current && !holderContentRef.current.contains(target)) {
                setOpenVolume(false);
            }
        }

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    if (!fileData) {
        return null;
    }

    const elementName = (
        <div className={cls.holderName}>
            <Text text={name} className={cls.name} />
            {date && <Text text={date} className={cls.date} />}
        </div>
    );

    return (
        <div className={classNames(cls.container, { [cls.containerWithPinnedPost]: isPinnedPost })}>
            <audio
                controls={false}
                ref={audioRef}
                src={fileData.file}
                onLoadedData={initAudioSettings}
                onLoadedMetadata={initAudioSettings}
                onEnded={handleAudioEnded}
                preload="metadata"
            />

            <div className={cls.wrapperPlayBtn}>
                <button onClick={handleOnAudioClick} type="button" className={cls.playBtn}>
                    {isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                </button>
                {!isTablet && elementName}
            </div>

            {isTablet && elementName}

            <div className={cls.holderActionButton} ref={holderContentRef}>
                {!isIOS && !isAndroid && (
                    <div className={cls.holderVolume} onMouseLeave={handleMouseLeave}>
                        {volume === 0 ? (
                            <MutedIcon onClick={handleToggleVolume} onMouseMove={handleMouseMove} className={cls.mutedIcon} />
                        ) : (
                            <VolumeIcon onClick={handleToggleVolume} onMouseMove={handleMouseMove} className={cls.mutedIcon} />
                        )}

                        <div
                            className={
                                classNames(cls.holderVolumeSlider, {
                                    [cls.holderVolumeSliderVisible]: openVolume,
                                    [cls.holderVolumeSliderHide]: !openVolume,
                                })
                            }
                            onMouseMove={handleMouseMove}
                        >
                            <Slider tooltip={{ formatter: null }} vertical reverse onChange={onChangeVolume} value={volume} />
                        </div>
                    </div>
                )}

                <CloseOutlined onClick={closeAudioFile} className={cls.closeIcon} />
            </div>
        </div>
    );
};
