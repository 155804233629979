// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vl6pYTLm{padding-top:var(--p-1-5)}.b7o65wCC>div[class=ant-modal-content]{padding:var(--p-2) !important}`, "",{"version":3,"sources":["webpack://./src/features/delete-message/ui/DeleteMessageModal.module.scss"],"names":[],"mappings":"AAAA,UACI,wBAAA,CAIA,uCACI,6BAAA","sourcesContent":[".buttonGroup {\n    padding-top: var(--p-1-5);\n}\n\n.modalDelete {\n    & > div[class=\"ant-modal-content\"] {\n        padding: var(--p-2) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": `Vl6pYTLm`,
	"modalDelete": `b7o65wCC`
};
export default ___CSS_LOADER_EXPORT___;
