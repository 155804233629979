// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UYlZKE2g{padding:var(--p-1-5);height:20px;display:flex;justify-content:space-between;align-items:center;box-sizing:content-box;border-bottom:1px solid var(--black-percent-06);border-radius:0}.UYlZKE2g:first-child{border-top-left-radius:var(--radius-1);border-top-right-radius:var(--radius-1)}.UYlZKE2g:last-child{border-bottom-left-radius:var(--radius-1);border-bottom-right-radius:var(--radius-1);border-bottom:none}.Lv0VQGAS svg{width:20px;height:20px}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/resend-button/ResendButton.module.scss"],"names":[],"mappings":"AAAA,UACI,oBAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,sBAAA,CACA,+CAAA,CACA,eAAA,CAEA,sBACI,sCAAA,CACA,uCAAA,CAGJ,qBACI,yCAAA,CACA,0CAAA,CACA,kBAAA,CAKJ,cACI,UAAA,CACA,WAAA","sourcesContent":[".button {\n    padding: var(--p-1-5);\n    height: 20px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    box-sizing: content-box;\n    border-bottom: 1px solid var(--black-percent-06);\n    border-radius: 0;\n\n    &:first-child {\n        border-top-left-radius: var(--radius-1);\n        border-top-right-radius: var(--radius-1);\n    }\n\n    &:last-child {\n        border-bottom-left-radius: var(--radius-1);\n        border-bottom-right-radius: var(--radius-1);\n        border-bottom: none;\n    }\n}\n\n.icon {\n    svg {\n        width: 20px;\n        height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `UYlZKE2g`,
	"icon": `Lv0VQGAS`
};
export default ___CSS_LOADER_EXPORT___;
