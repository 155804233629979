// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YoqBqZxf{width:100%;height:50px;position:relative;box-shadow:0 2px 10px 0 var(--black-percent-20);background:var(--gray-1);overflow:hidden}.W0TucL33::before{content:"";display:block;position:absolute;left:-150px;top:0;height:100%;width:80%;background:linear-gradient(to right, transparent 0%, var(--white-primary) 50%, transparent 100%);animation:qDF8Vpi4 1s cubic-bezier(0.4, 0, 0.2, 1) infinite}@keyframes qDF8Vpi4{from{left:-150px}to{left:100%}}`, "",{"version":3,"sources":["webpack://./src/shared/ui/skeleton/Skeleton.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CACA,WAAA,CACA,iBAAA,CACA,+CAAA,CACA,wBAAA,CACA,eAAA,CAIA,kBACI,UAAA,CACA,aAAA,CACA,iBAAA,CACA,WAAA,CACA,KAAA,CACA,WAAA,CACA,SAAA,CACA,gGAAA,CACA,2DAAA,CAIR,oBACI,KACI,WAAA,CAGJ,GACI,SAAA,CAAA","sourcesContent":[".skeleton {\n    width: 100%;\n    height: 50px;\n    position: relative;\n    box-shadow: 0 2px 10px 0 var(--black-percent-20);\n    background: var(--gray-1);\n    overflow: hidden;\n}\n\n.animated {\n    &::before {\n        content: \"\";\n        display: block;\n        position: absolute;\n        left: -150px;\n        top: 0;\n        height: 100%;\n        width: 80%;\n        background: linear-gradient(to right, transparent 0%, var(--white-primary) 50%, transparent 100%);\n        animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;\n    }\n}\n\n@keyframes load {\n    from {\n        left: -150px;\n    }\n\n    to {\n        left: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": `YoqBqZxf`,
	"animated": `W0TucL33`,
	"load": `qDF8Vpi4`
};
export default ___CSS_LOADER_EXPORT___;
