import { transformApiListResponse } from 'shared/lib/utils/api/api';
import { IBackendListResponse } from 'shared/types/types';

import {
    INotification,
    INotificationFromServer,
} from '../model/types/notification.types';

export function mapNotification(rawPost: INotificationFromServer): INotification {
    return {
        channelId: rawPost.channel_id,
        createdAt: rawPost.created_at,
        description: rawPost.description,
        errorCode: rawPost.error_code,
        id: rawPost.id,
        postId: rawPost.post_id,
        postPageUrl: rawPost.post_page_url,
        publicationBlock: rawPost.publication_block,
        settingsRequired: rawPost.settings_required,
        title: rawPost.title,
        updatedAt: rawPost.updated_at,
    };
}

export const transformGetNotificationList = async (rawResult:
IBackendListResponse<INotificationFromServer>) => transformApiListResponse<INotificationFromServer, INotification>(
    rawResult,
    mapNotification,
);
