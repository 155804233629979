import {
    useState,
    useEffect,
} from 'react';

interface DeviceInfo {
    isIOS: boolean;
    isIPad: boolean;
    isIPhone: boolean;
    isMacintosh: boolean;
    isAppleDevice: boolean;
    isAndroid: boolean;
}

const useDeviceDetect = (): DeviceInfo => {
    const [isIOS, setIsIOS] = useState(false);
    const [isIPad, setIsIPad] = useState(false);
    const [isIPhone, setIsIPhone] = useState(false);
    const [isMacintosh, setIsMacintosh] = useState(false);
    const [isAppleDevice, setIsAppleDevice] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    useEffect(() => {
        const { userAgent } = navigator;

        const iOSDevice = /iPad|iPhone|iPod/.test(userAgent);
        const isApple = /iPad|iPhone|iPod|Macintosh/.test(userAgent);

        setIsIOS(iOSDevice);
        setIsIPad(/iPad/.test(userAgent));
        setIsIPhone(/iPhone/.test(userAgent));
        setIsMacintosh(/Macintosh/.test(userAgent));
        setIsAppleDevice(isApple);
        setIsAndroid(/Android/.test(userAgent));
    }, []);

    return {
        isAndroid, isAppleDevice, isIOS, isIPad, isIPhone, isMacintosh,
    };
};
export default useDeviceDetect;
