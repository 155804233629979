import {
    useEffect,
    useState,
} from 'react';

interface BrowserInfo {
    isChrome: boolean;
    isFirefox: boolean;
    isSafari: boolean;
    isEdge: boolean;
    isIE: boolean;
    isOther: boolean;
}

const useBrowserInfo = (): BrowserInfo => {
    const [browserInfo, setBrowserInfo] = useState<BrowserInfo>({
        isChrome: false,
        isEdge: false,
        isFirefox: false,
        isIE: false,
        isOther: false,
        isSafari: false,
    });

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const browsers: Record<string, boolean> = {
            chrome: /chrome/.test(userAgent),
            edge: /edge/.test(userAgent),
            firefox: /firefox/.test(userAgent),
            ie: /msie|trident/.test(userAgent),
            safari: /safari/.test(userAgent),
        };

        const detectedBrowser = Object.keys(browsers).find((key) => browsers[key]);

        if (detectedBrowser) {
            const keyBrowser = `is${detectedBrowser.charAt(0).toUpperCase() + detectedBrowser.slice(1)}`;
            setBrowserInfo({ ...browserInfo, [keyBrowser]: true });
        } else {
            setBrowserInfo({ ...browserInfo, isOther: true });
        }
    }, []);

    return browserInfo;
};

export default useBrowserInfo;
