import { Options } from 'react-lottie';

import { Dayjs } from 'dayjs';

import { EntityState } from '@reduxjs/toolkit';
import { INotification } from 'entities/notification';
import { EBehaviorScroll } from 'shared/constants/scroll';

export enum EPostTypeMessage {
    TEXT = 'text',
    UNKNOWN = 'unknown',
    ALBUM = 'album',
    ANIMATED_STICKER = 'animated_sticker',
    AUDIO_MESSAGE = 'audio_message',
    VOICE_MESSAGE = 'voice_message',
    STATIC_STICKER = 'static_sticker',
    VIDEO_STICKER = 'video_sticker',
    ROUND_VIDEO = 'round_video',
    CUSTOM_STICKER = 'custom_sticker',
    POLL = 'poll',
}

export enum EMediaMessageTypes {
    VIDEO = 'video',
    PHOTO = 'photo',
    DOCUMENT = 'document',
}

export enum EScrollTypes {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export enum EPublishStatus {
    ERROR = 'error',
    IN_PROGRESS = 'in_progress',
    SEND_SUCCEEDED = 'send_succeeded',
    NOT_STARTED = 'not_started',
}

export interface IFile {
    id: string;
    name: string;
    fileSize: number;
    thumbnail: string;
    createdAt: string;
    updatedAt: string;
    file: string;
    type?: string;
}

export interface IFileFromServer {
    id: string;
    name: string;
    file_size: number;
    created_at: string;
    updated_at: string;
    file: string;
    thumbnail: string;
    type?: string;
}

export interface IFileWithTypeAndUID extends IFile {
    uid: string;
    type: string;
}

export interface IPostMedia {
    type: string;
    file: IFile;
}

export interface IPostMediaFromServer {
    type: string;
    file: IFileFromServer;
}

export interface IDefaultMessage {
    id: string;
    publishAt: string | Dayjs;
    postType: EPostTypeMessage;
    text?: string;
    post: {
        media?: IPostMedia[];
        file?: IFile;
        poll?: IPostPollData;
    };
    createdAt: string;
    updatedAt: string;
    channel: string;
    buttons?: IMessageButton[];
    webPreviewLimitsBypassEnabled: boolean;
    isNotifyPin: boolean;
    isPinIndefinitely: boolean;
    pinDuration: number | null;
    donorMessageId?: number;
    isEdited: boolean;
    isSent: boolean;
    errors: INotification[];
    postPublishStatus: EPublishStatus;
    importedFrom: string | null;
}

export interface ISearchMessage {
    cursorLink: string;
    id: string;
}

export interface ISearchMessageFromServer {
    cursor_link: string;
    id: string;
}

export type TValueBtnType = 'url' | 'variable';

export enum EValueBtnType {
    URL = 'url',
    VARIABLE = 'variable',
}

type TValueBtn = {
    type: TValueBtnType;
    data: string;
};

export interface IMessageButton {
    id?: string; // Custom value which we define ourself
    name: string;
    value: TValueBtn;
}

export interface ITextMessage extends IDefaultMessage {
    text?: string;
    postType: EPostTypeMessage.TEXT;
}

export interface IUnknownMessage extends IDefaultMessage {
    postType: EPostTypeMessage.UNKNOWN;
}
export interface IAlbumMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ALBUM;
}
export interface IStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ANIMATED_STICKER;
    post: {
        media?: IPostMedia[];
        file?: IFile;
        sticker_height?: number | null;
        sticker_width?: number | null;
    };
}
export interface IAudioMessage extends IDefaultMessage {
    postType: EPostTypeMessage.AUDIO_MESSAGE;
}
export interface IVoiceMessage extends IDefaultMessage {
    postType: EPostTypeMessage.VOICE_MESSAGE;
}
export interface IVideoStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.VIDEO_STICKER;
    post: IMessagePost;
}
export interface IStaticStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.STATIC_STICKER;
    post: IMessagePost;
}
export interface ICustomStickerMessage extends IDefaultMessage {
    postType: EPostTypeMessage.CUSTOM_STICKER;
    post: IMessagePost;
}
export interface IRoundVideoMessage extends IDefaultMessage {
    postType: EPostTypeMessage.ROUND_VIDEO;
}

export interface IPollMessage extends IDefaultMessage {
    postType: EPostTypeMessage.POLL;
    post: IMessagePost;
}

export interface IMessageFromServer {
    id: string;
    post: IMessagePostFromServer;
    post_type: EPostTypeMessage;
    publish_at: string | Dayjs;
    created_at: string;
    updated_at: string;
    channel: string;
    type?: string;
    buttons: IMessageButton[];
    web_preview_limits_bypass_enabled: boolean;
    notify_on_pin: boolean;
    pin_duration: null | number;
    pin_indefinitely: boolean;
    donor_message_id?: number;
    is_edited: boolean;
    is_sent: boolean;
    errors: INotification[];
    post_publish_status: EPublishStatus;
    imported_from: string | null;
}

export interface IMessageFromServerWithMeta {
    next: string;
    prev: string;
    result: TMessage[];
}

export type TUpdatingMessageStatuses = 'shouldUpdate' | 'pending' | 'updating' | 'updated';

export type TMessage =
    | ITextMessage
    | IUnknownMessage
    | IAlbumMessage
    | IStickerMessage
    | IAudioMessage
    | IVoiceMessage
    | IVideoStickerMessage
    | IStaticStickerMessage
    | IRoundVideoMessage
    | ICustomStickerMessage
    | IPollMessage;

export interface IMessagesSchema extends EntityState<TMessage> {
    messageUpdatingStatus: TUpdatingMessageStatuses;
    scrollMessageId: string | null;
    nextUrlMessage: string | null;
    previousUrlMessage: string | null;
    cacheAnimationSticker: ICacheAnimationSticker;
    isAnimationScrollMessage: boolean;
    behaviorScrollMessage: EBehaviorScroll;
    openFromChannelList: boolean;
    dateSearch?: string;
    shouldUpdateMessage: boolean;
    errorUpdateMessage: boolean;
    isMessageListUpdate: boolean;
    playingFileHashId: string | null;
    isDisabledScroll: boolean;
    indexStartMes: number;
}

export interface ICacheAnimationSticker {
    [key: string]: Options;
}

export interface IMessageOptions {
    isAnimSticker: boolean;
    isAudioMessage: boolean;
    isRoundedVideo: boolean;
    isStaticSticker: boolean;
    isVideoSticker: boolean;
    isVoiceMessage: boolean;
    isWebPreview: boolean;
    withDocument: boolean;
    withMediaPhotoVideo: boolean;
    withMediaWithoutText: boolean;
    isOnlySticker: boolean;
    isAlbum: boolean;
    isPoll: boolean;
}

export interface IGroupedMessages {
    publishAt: string;
    items: TMessage[];
}
export interface IResponseResendPost {
    status: string;
}

export type CustomVideoElementRef = HTMLVideoElement & {
    webkitEnterFullscreen(): Promise<void>;
    requestFullscreen(): Promise<void>;
    mozRequestFullScreen(): Promise<void>;
    webkitRequestFullscreen(): Promise<void>;
    msRequestFullscreen(): Promise<void>;
};

export interface IGetMessagesSearchArgs {
    channelId: string;
    date?: string;
    search?: string;
    cursor?: string;
}
export interface IMessagePostFromServer {
    text?: string;
    media?: IPostMediaFromServer[];
    file?: IFileFromServer;
    sticker_height?: number | null;
    sticker_width?: number | null;
    poll?: IPostPollDataFromServer;
}

export interface IMessagePost {
    text?: string;
    media?: IPostMedia[];
    file?: IFile;
    stickerHeight?: number | null;
    stickerWidth?: number | null;
    poll?: IPostPollData;
}

export interface ICreateMessageFile {
    fileId?: string;
    type?: string;
}

export interface IPostPollDataFromServer {
    answers: string[];
    multiple_choice: boolean;
    question: string;
    quiz: boolean;
    solution_index: number | null;
    solution_message?: string;
}

export interface IPostPollAnswers {
    value: string;
    id?: string;
}

export interface IPostPollData {
    answers: IPostPollAnswers[];
    multipleChoice: boolean;
    question: string;
    quiz: boolean;
    solutionIndex: number | null;
    solutionMessage?: string;
}

export interface ICreateMessageFileFromServer {
    file_id?: string;
    type?: string;
}

export interface ICreateMessageMedia {
    media?: ICreateMessageFile[];
}

export interface ICreateMessageMediaFromServer {
    media?: ICreateMessageFileFromServer[];
}

export interface ICreateMessageText {
    text: string;
}

export interface ICreateMessagePost {
    media?: ICreateMessageFile[];
    fileId?: string;
    type?: string;
    text?: string;
    poll?: IPostPollData;
}

export interface ICreateMessagePostFromServer {
    media?: ICreateMessageFileFromServer[];
    file_id?: string;
    type?: string;
    text?: string;
    poll?: IPostPollDataFromServer;
}

export interface ICreateMessageFromServer {
    channel: string;
    notify_on_pin: boolean;
    pin_duration: null | number;
    pin_indefinitely: boolean;
    post_type: EPostTypeMessage;
    publish_at: string | Dayjs;
    web_preview_limits_bypass_enabled: boolean;
    buttons: IMessageButton[];
    post?: ICreateMessagePostFromServer;
    id?: string;
}
export interface ICreateMessage {
    channel: string;
    notifyOnPin: boolean;
    pinDuration: null | number;
    pinIndefinitely: boolean;
    postType: EPostTypeMessage;
    publishAt: string | Dayjs;
    webPreviewLimitsBypassEnabled: boolean;
    buttons: IMessageButton[];
    post?: ICreateMessagePost;
    id?: string;
}
