/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    parseHtmlStringToDomWithReplace,
    traverseDom,
} from 'shared/lib/utils/deserialize/deserialize';
import { IDeserializeSettings } from 'shared/lib/utils/deserialize/deserialize.types';

import { HTML_TYPES } from '../constants/constants';
import {
    DeserializedElementWithChildren,
    Node as MyNode,
} from '../types/editor';

const createChildElement = (
    text: string,
    type: string,
    withSettings: IDeserializeSettings,
): DeserializedElementWithChildren => ({
    children: [{ text, ...withSettings }],
    type,
});

// Function to wrap a node using link
const handleTagA = (node: HTMLElement, withSettings: IDeserializeSettings): DeserializedElementWithChildren => {
    const children = traverseDom(node, { handleElementNode, handleTextNode }, withSettings);
    return ({
        children,
        type: HTML_TYPES.LINK,
        url: node.getAttribute('href') || '',
    });
};

// Function to handle element nodes during traversal, replacing non-existent tags with proper components
function handleElementNode(node: HTMLElement, withSettings: IDeserializeSettings): DeserializedElementWithChildren {
    const { tagName } = node;

    if (tagName === 'A') {
        return handleTagA(node, withSettings);
    }

    const type = node.getAttribute('data-type') || '';
    const text = node.getAttribute('data-text') || '';

    let childElement = createChildElement(text, type, withSettings);

    if (type === HTML_TYPES.ANIEMOJI) {
        childElement = {
            ...childElement,
            name: text,
        };
    }

    return childElement;
}

// Function to handle text nodes during traversal
function handleTextNode(node: ChildNode, withSettings: IDeserializeSettings): MyNode {
    return ({ text: node.textContent || '', ...withSettings });
}

// - Function to traverse DOM tree, handling element and text nodes
export const deserializeElement = (element: Node) => traverseDom(element, {
    handleElementNode,
    handleTextNode,
});

export function sanitizeNodes(nodes: MyNode[]) {
    return nodes.map((node) => {
        if (node.type === 'a' && (!node.children || node.children.length === 0)) {
            return { text: '' };
        }
        return node;
    });
}

export function deserialize(html: string): MyNode[] {
    const parsed = parseHtmlStringToDomWithReplace(html);

    return sanitizeNodes([...deserializeElement(parsed.body), { text: '' }]);
}
