import {
    FC,
} from 'react';
import { useSelector } from 'react-redux';

import {
    getUserData,
} from 'entities/user';
import avatar from 'shared/assets/icons/avatar.svg';
import { Avatar } from 'shared/ui/avatar/Avatar';
import { Text } from 'shared/ui/text/Text';

import cls from './UserInfo.module.scss';

export const UserInfo: FC = () => {
    const userData = useSelector(getUserData);

    return (
        <div className={cls.holderUserInfo}>
            <Avatar Icon={avatar} alt="avatar" className={cls.avatar} />
            <div className={cls.userInfo}>
                <Text
                    text={`${userData?.firstName} ${userData?.lastName}`}
                    size="md"
                    weight="600"
                    className={cls.userName}
                />
                <Text text={userData?.role?.name || ''} className={cls.userRole} />
            </div>
        </div>

    );
};
