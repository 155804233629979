import {
    Dispatch,
    FC,
    SetStateAction,
    useMemo,
} from 'react';

import {
    Drawer,
    Select,
} from 'antd';

import { channelActions } from 'entities/channels';
import {
    IEmployee,
    employeesActions,
    useUpdateEmployeeRoleMutation,
} from 'entities/employee';
import { useGetRolesQuery } from 'entities/role';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { Heading } from 'shared/ui/heading/Heading';
import { List } from 'shared/ui/list/List';

import cls from './ChangeEmployeeRole.module.scss';

interface IChangeEmployeeRoleProps {
    className?: string;
    employee: IEmployee;
    isOpen: boolean;
    toggleOpen: () => void;
    roleName: string;
    setRoleName: Dispatch<SetStateAction<string>>;
}

export const ChangeEmployeeRole: FC<IChangeEmployeeRoleProps> = (props) => {
    const {
        className, employee, isOpen, roleName,
        setRoleName, toggleOpen,
    } = props;

    const dispatch = useAppDispatch();

    const { isMobile } = useScreenType();

    const { data: rolesData } = useGetRolesQuery({});

    const [updateRole] = useUpdateEmployeeRoleMutation();

    const handleChangeRole = async (role: string) => {
        if (rolesData) {
            setRoleName(role);
            toggleOpen();

            const foundRoleByName = rolesData?.find((el) => el.name === role);
            await updateRole({ id: employee?.id, role: foundRoleByName });
            dispatch(channelActions.setChannelsList([]));
            dispatch(employeesActions.updateDataEmployees(
                {
                    changes: { role: foundRoleByName },
                    id: employee.id,
                },
            ));
        }
    };

    const options = useMemo(() => rolesData?.map((role) => ({ label: role.name, value: role.name })) || [], [rolesData]);

    if (!isMobile) {
        return (
            <Select
                bordered={false}
                value={roleName || undefined}
                onDropdownVisibleChange={toggleOpen}
                placeholder="Роль"
                options={options}
                onChange={handleChangeRole}
                popupClassName={cls.popup}
                className={cls.roleSelect}
            />
        );
    }

    return (
        <Drawer
            placement="bottom"
            height="initial"
            closeIcon={null}
            rootClassName={cls.drawer}
            open={isOpen}
            className={className}
            onClose={toggleOpen}
            destroyOnClose
        >
            <Heading mB="12" align="center" max text={employee.firstName} as="h5" />
            <List
                onChange={handleChangeRole}
                options={options}
                currentValue={roleName}

            />
        </Drawer>
    );
};
