/* eslint-disable max-len */
import { SvgIcon } from 'shared/types/global';

export const LogoIcon: SvgIcon = (props) => {
    const {
        className, color = '#fff', height = 30, width = 127,
    } = props;

    return (

        <svg className={className} width={width} height={height} viewBox="0 0 105 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0843269 4.31417L19.8694 0.00252593C19.9481 -0.0146305 20.017 0.0583965 19.9963 0.137115L14.7986 19.9198C14.7815 19.9848 14.7092 20.0181 14.6488 19.99C10.7807 18.1893 10.1727 15.7575 11.301 9.68735C11.3194 9.58806 11.4504 9.56305 11.5001 9.65068L12.6879 11.7432C12.7361 11.8282 12.862 11.8108 12.8858 11.7158L14.41 5.65149C14.4492 5.49561 14.3137 5.35193 14.1579 5.38411L8.09612 6.63594C8.00123 6.65553 7.97803 6.78191 8.05964 6.83465L10.07 8.13387C10.1541 8.18827 10.1232 8.3194 10.0243 8.33333C3.97554 9.18496 1.60269 8.45524 0.00790012 4.46215C-0.0170108 4.39978 0.0193059 4.32834 0.0843269 4.31417Z" />
            <path d="M31.0864 17.2349H28V2.5H34.7813C37.9329 2.5 39.65 4.66495 39.65 7.24963C39.65 9.81222 37.9112 11.9993 34.7813 11.9993H31.0864V17.2349ZM34.3684 9.23785C35.5855 9.23785 36.4984 8.46465 36.4984 7.24963C36.4984 6.01252 35.5855 5.26141 34.3684 5.26141H31.0864V9.23785H34.3684Z" />
            <path d="M46.0058 17.5C42.5499 17.5 40.4634 14.9374 40.4634 11.8888C40.4634 8.8623 42.5499 6.29971 46.0058 6.29971C49.4834 6.29971 51.57 8.8623 51.57 11.8888C51.57 14.9374 49.4834 17.5 46.0058 17.5ZM46.0058 15.0037C47.7229 15.0037 48.7009 13.5677 48.7009 11.8888C48.7009 10.232 47.7229 8.79602 46.0058 8.79602C44.2887 8.79602 43.3324 10.232 43.3324 11.8888C43.3324 13.5677 44.2887 15.0037 46.0058 15.0037Z" />
            <path d="M57.3997 17.5C55.574 17.5 53.8134 16.8814 52.7049 15.8432L53.9004 13.877C54.6611 14.606 56.226 15.3351 57.5301 15.3351C58.7256 15.3351 59.2907 14.849 59.2907 14.1863C59.2907 12.419 53.0527 13.8549 53.0527 9.65758C53.0527 7.86819 54.5959 6.29971 57.3562 6.29971C59.1385 6.29971 60.5513 6.91826 61.5946 7.75773L60.4861 9.67968C59.8558 8.99485 58.6604 8.44256 57.378 8.44256C56.3782 8.44256 55.7261 8.90648 55.7261 9.50295C55.7261 11.0935 61.9423 9.76804 61.9423 14.0758C61.9423 16.0199 60.3122 17.5 57.3997 17.5Z" />
            <path d="M67.497 17.5C65.5626 17.5 64.5193 16.4838 64.5193 14.5839V9.01694H62.7805V6.5648H64.5193V3.64875H67.3014V6.5648H69.4314V9.01694H67.3014V13.8328C67.3014 14.4956 67.6492 15.0037 68.2577 15.0037C68.6707 15.0037 69.0619 14.849 69.2141 14.6944L69.8009 16.8152C69.388 17.1907 68.649 17.5 67.497 17.5Z" />
            <path d="M80.6009 17.2349H71.3852V2.5H74.4716V14.4735H80.6009V17.2349Z" />
            <path d="M91.6119 17.2349H88.8515V16.1303C88.1343 16.9919 86.8954 17.5 85.5261 17.5C83.8525 17.5 81.8746 16.3513 81.8746 13.9654C81.8746 11.4691 83.8525 10.5191 85.5261 10.5191C86.9171 10.5191 88.156 10.9831 88.8515 11.8225V10.3866C88.8515 9.30412 87.9387 8.5972 86.5476 8.5972C85.4174 8.5972 84.3741 9.01694 83.483 9.85641L82.4397 7.97865C83.7221 6.8299 85.3739 6.29971 87.0258 6.29971C89.4166 6.29971 91.6119 7.27172 91.6119 10.3424V17.2349ZM86.6346 15.6001C87.5257 15.6001 88.3951 15.2909 88.8515 14.6723V13.3468C88.3951 12.7283 87.5257 12.419 86.6346 12.419C85.5478 12.419 84.6567 12.9934 84.6567 14.0317C84.6567 15.0258 85.5478 15.6001 86.6346 15.6001Z" />
            <path d="M97.1102 13.8108C97.5666 14.4956 98.5882 15.0037 99.4793 15.0037C101.088 15.0037 102.153 13.7666 102.153 11.9109C102.153 10.0552 101.088 8.79602 99.4793 8.79602C98.5882 8.79602 97.5666 9.32622 97.1102 10.0331V13.8108ZM97.1102 17.2349H94.3498V2.5H97.1102V7.93446C97.9361 6.85199 99.1098 6.29971 100.349 6.29971C103.022 6.29971 105 8.42047 105 11.9109C105 15.4676 103 17.5 100.349 17.5C99.0881 17.5 97.9361 16.9256 97.1102 15.8652V17.2349Z" />
        </svg>

    );
};
