import {
    ReactNode,
    memo,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';

import {
    TextAlign,
    TextSize,
    TextTheme,
} from './constants/text';
import {
    TextTag,
    TextWeight,
} from './types/text.types';

import cls from './Text.module.scss';

interface ITextProps {
    className?: string;
    text: string | ReactNode;
    theme?: TextTheme;
    align?: TextAlign;
    size?: TextSize;
    /**
    * @deprecated use prop isAnotherTag
    */
    isAnotherTag?: boolean;
    isTitle?: boolean;
    as?: TextTag;
    weight?: TextWeight;
    max?: boolean;
    tooltip?: string;
    withEllipsis?: boolean;
    href?: string;
    onClick?: () => void;
}

const mapWeightToClass: Record<TextWeight, string> = {
    400: cls.weight400,
    500: cls.weight500,
    600: cls.weight600,
    700: cls.weight700,
};

export const Text = memo((props: ITextProps) => {
    const {
        align = 'left',
        as = 'h1',
        className,
        isAnotherTag,
        isTitle,
        max,
        onClick,
        size = 'sm',
        text,
        theme = 'primary',
        tooltip,
        weight = '400',
        withEllipsis,
        ...otherProps
    } = props;

    const Tag = isTitle || isAnotherTag ? as : 'p';

    const classes = [
        className,
        mapWeightToClass[weight],
        cls[theme],
        cls[align],
        cls[size],
    ];

    return (
        <Tag
            title={tooltip}
            className={classNames('', {
                [cls.max]: max,
                [cls.ellipsis]: withEllipsis,
            }, classes)}
            onClick={onClick}
            {...otherProps}
        >
            {text}
        </Tag>
    );
});
