import {
    ChangeEvent,
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Input } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { useDebounce } from 'shared/lib/hooks/useDebounce/useDebounce';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import cls from './Search.module.scss';

interface ISearchProps {
    className?: string;
    onChange: (value: string) => void;
    isLoading: boolean;
    initialSearchName?: string;
}

const DEBOUNCE_DELAY = 500;

export const Search: FC<ISearchProps> = memo((props) => {
    const {
        className, initialSearchName = '', isLoading, onChange,
    } = props;

    const {
        isTablet,
    } = useScreenType();

    const [formValue, setFormValue] = useState('');

    const handleClearForm = useCallback(() => {
        setFormValue('');
        onChange('');
    }, [onChange]);

    const suffix = useMemo(() => {
        if (isLoading) {
            return <LoadingOutlined spin className={cls.loader} />;
        }

        if (formValue) {
            return <CloseOutlined className={cls.closeIcon} onClick={handleClearForm} />;
        }
        return <span />;
    }, [isLoading, formValue]);

    const handleChangeSearch = useDebounce(
        useCallback(
            (value: string) => {
                onChange(value);
            },
            [onChange],
        ),
        DEBOUNCE_DELAY,
    );

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormValue(value);

        handleChangeSearch(value);
    }, []);

    useEffect(() => {
        setFormValue(initialSearchName);
    }, []);

    return (
        <div className={classNames(cls.searchWrapper, {}, [className])}>
            <Input
                placeholder="Поиск"
                value={formValue}
                onChange={handleChange}
                suffix={suffix}
                className={classNames('', { [cls.inputTablet]: isTablet })}
                size={isTablet ? 'large' : 'middle'}
            />
        </div>
    );
});
