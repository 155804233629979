import {
    useCallback,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    getUserAuth,
    userActions,
} from 'entities/user';
import {
    IS_AUTH_LOCAL_KEY,
    IS_AUTH_LOCAL_VALUE,
} from 'shared/constants/auth';
import {
    AUTH_URL,
    TELEGRAM_AUTH_URL,
} from 'shared/constants/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { LocalStore } from 'shared/lib/utils/localstore/localstore';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import {
    useCheckTokenTelegramMutation,
} from '../model/services/auth.service';

import cls from './TelegramAuthPage.module.scss';

export const TelegramAuthPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [authenticateUser, { isError: isErrorToken, isSuccess: isSuccessToken }] = useCheckTokenTelegramMutation();

    const userAuth = useSelector(getUserAuth);

    const getAuthToken = useCallback(() => {
        const { pathname } = location;
        return pathname.replace(`${TELEGRAM_AUTH_URL}/`, '');
    }, [location.pathname]);

    const onAuth = async (token: string) => {
        await authenticateUser({ authToken: token });
    };

    useEffect(() => {
        const token = getAuthToken();
        onAuth(token);
    }, [location.pathname]);

    useEffect(() => {
        if (isErrorToken) {
            dispatch(userActions.setAuthError(true));
            navigate(AUTH_URL);
        }
    }, [isErrorToken]);

    useEffect(() => {
        if (isSuccessToken) {
            LocalStore.remove(IS_AUTH_LOCAL_KEY);
            LocalStore.set(IS_AUTH_LOCAL_KEY, IS_AUTH_LOCAL_VALUE);
        }
    }, [isSuccessToken]);

    if (userAuth) {
        return null;
    }

    return (
        <MainLayouts isAuthPage>
            <div className={cls.container}>
                <PageLoader isLoading withoutPortal isAbsolute classNameWithoutPortal={cls.loader} />
            </div>
        </MainLayouts>
    );
};
