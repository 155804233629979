// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DbXcqKO_{position:relative;width:100%;min-width:100px;min-height:50px;height:100%;transform-origin:bottom}.DbXcqKO_ img{width:100%;height:100%}._GFhBBtE{position:absolute;top:0;left:0;width:100%;height:100%;background-color:var(--white-1);animation:OVI7Y8Co 1s infinite alternate}@keyframes OVI7Y8Co{0%{opacity:.8}100%{opacity:1}}.sBfTxN9w{height:150px}`, "",{"version":3,"sources":["webpack://./src/shared/ui/image-loader/ImageWithLoader.module.scss"],"names":[],"mappings":"AAAA,UACI,iBAAA,CACA,UAAA,CACA,eAAA,CACA,eAAA,CACA,WAAA,CACA,uBAAA,CAEA,cACI,UAAA,CACA,WAAA,CAIR,UACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,wCAAA,CAKJ,oBACI,GACI,UAAA,CAGJ,KACI,SAAA,CAAA,CAIR,UACI,YAAA","sourcesContent":[".imageContainer {\n    position: relative;\n    width: 100%;\n    min-width: 100px;\n    min-height: 50px;\n    height: 100%;\n    transform-origin: bottom;\n    \n    img {\n        width: 100%;\n        height: 100%;\n    }\n}\n\n.skeletonLoader {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: var(--white-1);\n    animation: skeleton-loading 1s infinite alternate;\n}\n\n\n\n@keyframes skeleton-loading {\n    0% {\n        opacity: 0.8;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n.skeleton {\n    height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `DbXcqKO_`,
	"skeletonLoader": `_GFhBBtE`,
	"skeleton-loading": `OVI7Y8Co`,
	"skeleton": `sBfTxN9w`
};
export default ___CSS_LOADER_EXPORT___;
