import {
    AUTH_URL,
    TELEGRAM_AUTH_URL,
} from 'shared/constants/router';

let urlReg: RegExp;

// Определение функции для получения регулярного выражения для проверки URL
const getUrlRegex = () => {
    if (urlReg) {
        return urlReg;
    }

    // Определение базового шаблона для символов, используемых в URL
    const word = '[a-fA-F\\d:]';

    // Функция для добавления границ слова в регулярное выражение, если требуется
    const b = (options: any) => (options && options.includeBoundaries ? `(?:(?<=\\s|^)(?=${word})|(?<=${word})(?=\\s|$))` : '');

    // Регулярное выражение для проверки IPv4 адресов
    const v4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';

    // Шаблон для сегментов IPv6 адреса
    const v6seg = '[a-fA-F\\d]{1,4}';

    // Регулярное выражение для проверки IPv6 адресов
    const v6 = `
(?:
(?:${v6seg}:){7}(?:${v6seg}|:)|                                    // 1:2:3:4:5:6:7::  1:2:3:4:5:6:7:8
(?:${v6seg}:){6}(?:${v4}|:${v6seg}|:)|                             // 1:2:3:4:5:6::    1:2:3:4:5:6::8   1:2:3:4:5:6::8  1:2:3:4:5:6::1.2.3.4
(?:${v6seg}:){5}(?::${v4}|(?::${v6seg}){1,2}|:)|                   // 1:2:3:4:5::      1:2:3:4:5::7:8   1:2:3:4:5::8    1:2:3:4:5::7:1.2.3.4
(?:${v6seg}:){4}(?:(?::${v6seg}){0,1}:${v4}|(?::${v6seg}){1,3}|:)| // 1:2:3:4::        1:2:3:4::6:7:8   1:2:3:4::8      1:2:3:4::6:7:1.2.3.4
(?:${v6seg}:){3}(?:(?::${v6seg}){0,2}:${v4}|(?::${v6seg}){1,4}|:)| // 1:2:3::          1:2:3::5:6:7:8   1:2:3::8        1:2:3::5:6:7:1.2.3.4
(?:${v6seg}:){2}(?:(?::${v6seg}){0,3}:${v4}|(?::${v6seg}){1,5}|:)| // 1:2::            1:2::4:5:6:7:8   1:2::8          1:2::4:5:6:7:1.2.3.4
(?:${v6seg}:){1}(?:(?::${v6seg}){0,4}:${v4}|(?::${v6seg}){1,6}|:)| // 1::              1::3:4:5:6:7:8   1::8            1::3:4:5:6:7:1.2.3.4
(?::(?:(?::${v6seg}){0,5}:${v4}|(?::${v6seg}){1,7}|:))             // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8  ::8             ::1.2.3.4
)(?:%[0-9a-zA-Z]{1,})?                                             // %eth0            %1
`
        .replace(/\s*\/\/.*$/gm, '') // Удаление комментариев из многострочной строки
        .replace(/\n/g, '') // Удаление переводов строк для формирования одной строки
        .trim();

    // Создание регулярных выражений для точного совпадения с IPv4 и IPv6 адресами
    const v46Exact = new RegExp(`(?:^${v4}$)|(?:^${v6}$)`);
    const v4exact = new RegExp(`^${v4}$`);
    const v6exact = new RegExp(`^${v6}$`);

    // Функция для создания регулярного выражения для IP адресов с учётом параметров
    const ip = (options: any) => (options && options.exact
        ? v46Exact
        : new RegExp(`(?:${b(options)}${v4}${b(options)})|(?:${b(options)}${v6}${b(options)})`, 'g'));

    ip.v4 = (options?: any) => (options && options.exact ? v4exact : new RegExp(`${b(options)}${v4}${b(options)}`, 'g'));
    ip.v6 = (options?: any) => (options && options.exact ? v6exact : new RegExp(`${b(options)}${v6}${b(options)}`, 'g'));

    // Определение шаблонов для других компонентов URL
    const protocol = '(?:(?:[a-z]+:)?//)';
    const auth = '(?:\\S+(?::\\S*)?@)?';
    const ipv4 = ip.v4().source;
    const ipv6 = ip.v6().source;
    const host = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
    const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
    const tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))';
    const port = '(?::\\d{2,5})?';
    const path = '(?:[/?#][^\\s"]*)?';

    // Финальное регулярное выражение для проверки URL
    const regex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ipv4}|${ipv6}|${host}${domain}${tld})${port}${path}`;
    urlReg = new RegExp(`(?:^${regex}$)`, 'i'); // Сохранение регулярного выражения для последующего использования
    return urlReg;
};

// Функция для проверки строки на соответствие формату HTTP URL
export const isValidHttpUrl = (str?: string): boolean => typeof str === 'string'
&& str.length <= 2048
&& !!str.match(getUrlRegex()); // Проверка длины строки и соответствия регулярному выражению

export const validTGUrl = (value?: string): boolean => {
    if (!value || !value.startsWith('@') || value.length === 1) {
        return false;
    }

    const forbiddenChars = /[^a-zA-Z0-9_]/;
    if (forbiddenChars.test(value.slice(1))) {
        return false;
    }

    return true;
};

export const getValidUrl = (url?: string): string => {
    if (!url) {
        return '';
    }

    if (!validTGUrl(url)) {
        return url;
    }

    return `https://t.me/${url.slice(1)}`;
};

export const getQueryVariable = (queryString: string): { [key: string]: string } => {
    if (!queryString) {
        return {};
    }

    const query: { [key: string]: string } = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export const getQueryParam = (data: { [key: string]: string | number | null | undefined } = {}): string => {
    const array = Object.entries(data);
    let res = '';
    if (array.length === 0) {
        return res;
    }

    array
        .filter((item) => {
            if (!item[1]) {
                return false;
            }

            if (typeof item[1] === 'number') {
                return true;
            }
            return !!item[1];
        })
        .forEach((item, index) => {
            if (!item[0]) {
                return;
            }
            const validValue = typeof item[1] === 'string' ? encodeURI(item[1]) : item[1];

            res += `${index === 0 ? '?' : '&'}${item[0]}=${validValue}`;
        });

    return res;
};

export const isAuthURL = (url: string): boolean => url.startsWith(AUTH_URL) || url.startsWith(TELEGRAM_AUTH_URL);
