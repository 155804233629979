import {
    FC,
    memo,
} from 'react';

import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';

import { Header } from './variants/header/Header';

interface IHeaderWrapperProps {
    className?: string;
    isTransparent: boolean;
}

export const HeaderWrapper: FC<IHeaderWrapperProps> = memo((props) => {
    const { className = '', isTransparent } = props;

    const { isTablet } = useScreenType();

    if (isTablet && !isTransparent) {
        return (
            null
        );
    }

    return (
        <Header className={className} isTransparent={isTransparent} />
    );
});
