import {
    FC,
} from 'react';

import SearchOutlined from '@ant-design/icons/SearchOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import cls from './EmptyTable.module.scss';

interface IEmptyTableProps {
    search?: string;
    className?: string;
}

export const EmptyTable: FC<IEmptyTableProps> = (props) => {
    const { className, search } = props;

    const icon = search ? <SearchOutlined className={cls.icon} /> : <TeamOutlined className={cls.icon} />;
    const title = search ? 'Ничего не найдено' : 'Нет сотрудников';
    const text = search ? 'По вашему запросу ничего не найдено' : 'У вас пока нет добавленных сотрудников';

    return (
        <div className={classNames(cls.container, {}, [className])}>
            {icon}
            <Text text={title} size="lg" weight="600" theme="black" className={cls.title} />
            <Text text={text} theme="black-45" />
        </div>
    );
};
