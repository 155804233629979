export const INITIAL_VALUE = [
    {
        children: [{ text: '' }],
        type: 'paragraph',
    },
];

export const HTML_TYPES = {
    ANIEMOJI: 'tg-aniemoji',
    CODE: 'code',
    LINK: 'a',
    PRE: 'pre',
    SPOILER: 'tg-spoiler',
    STRIKETHROUGH: 'del',
    UNDERLINE: 'u',
    VARIABLE: 'x-variable',
};

export enum ETextMaxSymbolsLength {
    SHORT_LENGTH = 1024,
    LONG_LENGTH = 4094,
}
