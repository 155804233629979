import {
    useEffect,
    useRef,
} from 'react';

interface IUseLongPressProps {
    ref: React.RefObject<HTMLElement | Element>;
    onLongPress: () => void;
    duration?: number;
}

const useLongPress = (
    {
        duration = 700,
        onLongPress,
        ref,
    }: IUseLongPressProps,
): void => {
    const touchStartTimestamp = useRef<number | null>(null);
    const longPressTimer = useRef<number | null>(null);

    useEffect(() => {
        const element = ref.current;

        const handleTouchStart = () => {
            touchStartTimestamp.current = new Date().getTime();
            longPressTimer.current = window.setTimeout(() => {
                onLongPress();
            }, duration);
        };

        const handleTouchMove = () => {
            if (longPressTimer.current) {
                clearTimeout(longPressTimer.current);
                longPressTimer.current = null;
            }
        };

        const handleTouchEnd = () => {
            if (touchStartTimestamp.current) {
                const touchEndTimestamp = new Date().getTime();
                if (touchEndTimestamp - touchStartTimestamp.current < duration) {
                    if (longPressTimer.current) {
                        clearTimeout(longPressTimer.current);
                        longPressTimer.current = null;
                    }
                }
            }
        };

        if (element) {
            element.addEventListener('touchstart', handleTouchStart);
            element.addEventListener('touchmove', handleTouchMove);
            element.addEventListener('touchend', handleTouchEnd);

            return () => {
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchmove', handleTouchMove);
                element.removeEventListener('touchend', handleTouchEnd);
            };
        }

        return () => {};
    }, [ref, onLongPress, duration]);
};

export default useLongPress;
