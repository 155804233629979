// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rE1vfluH{display:flex;justify-content:space-between;align-items:center;padding:var(--p-1) var(--p-0-75);height:36px}.D1WNfBe7,.hxYWO1Nr{display:flex}.OVafgp16>svg{width:16px;height:16px;padding:var(--p-0-5) var(--p-0-25);box-sizing:content-box}.OVafgp16>svg>path{fill:var(--black-percent-45)}`, "",{"version":3,"sources":["webpack://./src/shared/ui/mini-%D1%81alendar/component/header-calendar/HeaderCalendar.module.scss"],"names":[],"mappings":"AAEA,UACI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,gCAAA,CACA,WAAA,CAGJ,oBAEI,YAAA,CAIA,cACI,UAAA,CACA,WAAA,CACA,kCAAA,CACA,sBAAA,CAEA,mBACI,4BAAA","sourcesContent":["@use 'app/styles/mixins/mixins';\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: var(--p-1) var(--p-0-75);\n    height: 36px;\n}\n\n.headerLeftBlock,\n.headerRightBlock {\n    display: flex;\n}\n\n.headerControlIcon {\n    & > svg {\n        width: 16px;\n        height: 16px;\n        padding: var(--p-0-5) var(--p-0-25);\n        box-sizing: content-box;\n\n        & > path {\n            fill: var(--black-percent-45);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `rE1vfluH`,
	"headerLeftBlock": `D1WNfBe7`,
	"headerRightBlock": `hxYWO1Nr`,
	"headerControlIcon": `OVafgp16`
};
export default ___CSS_LOADER_EXPORT___;
