import {
    FC,
    ReactNode,
} from 'react';

import { IArgsContentViewer } from 'features/content-viewer';
import { classNames } from 'shared/lib/utils/classNames/classNames';

import {
    EMediaMessageTypes,
    IPostMedia,
    TMessage,
} from '../../model/types/message.types';
import { HolderFileLoader } from '../holder-file-loader/HolderFileLoader';
import { DocumentMessage } from '../variants/document-message/DocumentMessage';
import { ImageMessage } from '../variants/image-message/ImageMessage';
import { VideoMessage } from '../variants/video-message/VideoMessage';

import cls from './Album.module.scss';

interface IAlbumItemProps {
    media: IPostMedia;
    index: number;
    array: IPostMedia[];
    message: TMessage;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
}

export const AlbumItem: FC<IAlbumItemProps> = (props) => {
    const {
        array, index, media, message, openPreviewVideoOrPhoto,
    } = props;

    const { file, type } = media;

    const wrappedComponent = (jsx: ReactNode) => (
        <div className={classNames(cls.item, {}, [cls[`item-${index + 1}`]])} key={`${index} ${file.id}`}>
            {jsx}
        </div>
    );
    const isWebPreview = array.length === 1 && message.webPreviewLimitsBypassEnabled && message?.text;

    if (type === EMediaMessageTypes.VIDEO) {
        return wrappedComponent(
            <VideoMessage
                openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                isBigSizeVideo={array.length === 1 || array.length === 2}
                file={file}
                webPreviewClass={isWebPreview ? cls.webPreviewDisplay : ''}
                messageId={message.id}
            />,
        );
    }

    if (type === EMediaMessageTypes.PHOTO) {
        return wrappedComponent(
            <ImageMessage
                openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                file={file}
                webPreviewClass={isWebPreview ? cls.webPreviewDisplay : ''}
            />,
        );
    }

    if (type === EMediaMessageTypes.DOCUMENT) {
        return (
            <HolderFileLoader file={file}>
                {wrappedComponent(<DocumentMessage file={file} />)}
            </HolderFileLoader>
        );
    }
    return null;
};
