import {
    useEffect,
    useState,
} from 'react';

interface IScreenTypes {
    isDesktop: boolean;
    isLaptop: boolean;
    isMobile: boolean;
    isSmallMobile: boolean;
    isTablet: boolean;
}

const getScreenSize = (): IScreenTypes => ({
    isDesktop: window.matchMedia('only screen and (min-width: 1440px)').matches,
    isLaptop: window.matchMedia('only screen and (max-width: 1439px)').matches,
    isMobile: window.matchMedia('only screen and (max-width: 767px)').matches,
    isSmallMobile: window.matchMedia('only screen and (max-width: 479px)').matches,
    isTablet: window.matchMedia('only screen and (max-width: 1023px)').matches,
});

const useScreenType = (): IScreenTypes => {
    const [mediaQueries, setMediaQueries] = useState<IScreenTypes>(getScreenSize());

    useEffect(() => {
        const handleResize = () => {
            setMediaQueries(getScreenSize());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return mediaQueries;
};

export default useScreenType;
