import { FC } from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import { MainLayouts } from 'shared/ui/main-layout/MainLayouts';

import cls from './NotFoundPage.module.scss';

interface INotFoundPageProps {
    className?: string;
}

export const NotFoundPage: FC<INotFoundPageProps> = (props) => {
    const { className } = props;

    return (
        <MainLayouts>
            <div className={classNames(cls.notFoundPage, {}, [className])}>
                Страничка не найдена
            </div>
        </MainLayouts>

    );
};
