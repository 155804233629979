import React, { ReactNode } from 'react';

import { notification } from 'antd';

import { NotificationPlacement } from 'antd/es/notification/interface';
import { TNotificationType } from 'shared/types/types';
import { CustomNotification } from 'shared/ui/custom-notification/CustomNotification';

import cls from './useNotification.module.scss';

interface IOpenNotificationProps {
    closeIcon?: boolean;
    description: string | ReactNode;
    duration?: number | null;
    placement?: NotificationPlacement;
    type?: TNotificationType;
    icon?: ReactNode;
    classNameHolderIcon?: string;
}

interface IUseNotificationReturn {
    openNotification: (value: IOpenNotificationProps) => void;
}

export const useNotification = (): IUseNotificationReturn => {
    const openNotification = ({
        classNameHolderIcon,
        closeIcon = true,
        description = '',
        duration = 5000,
        icon,
        placement = 'topRight',
        type = 'success',
    }: IOpenNotificationProps) => {
        const key = `open${Date.now()}`;

        notification.config({
            maxCount: 3,
        });

        notification.open({
            className: cls.customNotification,
            closeIcon: false,
            description: (
                <CustomNotification
                    closeIcon={closeIcon}
                    description={description}
                    duration={duration}
                    id={key}
                    type={type}
                    icon={icon}
                    classNameHolderIcon={classNameHolderIcon}
                />
            ),
            duration,
            key,
            message: '',
            placement,
        });
    };

    return { openNotification };
};
