import {
    FC,
    memo,
} from 'react';
import {
    useSelector,
} from 'react-redux';

import {
    getSearchName,
} from 'entities/channels';
import { Search } from 'shared/ui/search/Search';

interface ISearchChannelsProps {
    className?: string;
    onChange: (value: string) => void;
    isLoading: boolean;
    initialSearchName?: string;
}

export const SearchChannels: FC<ISearchChannelsProps> = memo((props) => {
    const {
        className, isLoading, onChange,
    } = props;

    const searchName = useSelector(getSearchName);

    return (
        <Search
            className={className}
            isLoading={isLoading}
            onChange={onChange}
            initialSearchName={searchName}
        />
    );
});
