import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getStateUser } from 'entities/user';
import { Text } from 'shared/ui/text/Text';

import { NAVBAR_ARRAY } from '../../../constants/navbar';
import { INavbarInfo } from '../../../types/header-menu';

import cls from './NavigationList.module.scss';

interface INavigationListProps {
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export const NavigationList: FC<INavigationListProps> = (props) => {
    const { setSidebarOpen } = props;

    const stateUser = useSelector(getStateUser);

    const handleCloseSidebar = useCallback(() => {
        setSidebarOpen(false);
    }, []);

    const renderNavbar = ({
        accessRight, icon, title, url,
    }: INavbarInfo, index: number) => {
        const access = accessRight ? stateUser?.[accessRight] : true;

        if (!access) {
            return null;
        }

        return (
            <NavLink to={url} className={cls.navLink} onClick={handleCloseSidebar} key={index}>
                {icon}
                <Text text={title} className={cls.navLinkText} size="md" />
            </NavLink>
        );
    };

    return <div>{NAVBAR_ARRAY.map(renderNavbar)}</div>;
};
