// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._PUzpW2I{width:100%}.EqKo_g5e{width:250px}.EqKo_g5e div[class~=ant-popover-inner]{padding:0}.KP1vR7zk{margin-top:var(--p-0-5)}.SgtTuaoB{font:var(--font-sm);padding:var(--p-1) var(--p-1-5) var(--p-1) var(--p-2)}.uvod6mST{background:rgba(0,0,0,0);width:100%}.CQF6QXft{width:100%}`, "",{"version":3,"sources":["webpack://./src/entities/message/ui/holder-file-loader/HolderFileLoader.module.scss"],"names":[],"mappings":"AAAA,UACI,UAAA,CAGJ,UACI,WAAA,CAEA,wCACI,SAAA,CAIR,UACI,uBAAA,CAGJ,UACI,mBAAA,CACA,qDAAA,CAGJ,UACI,wBAAA,CACA,UAAA,CAGJ,UACI,UAAA","sourcesContent":[".holderFileLoader {\n    width: 100%;\n}\n\n.holderFileLoaderOverlay {\n    width: 250px;\n\n    & div[class~='ant-popover-inner'] {\n        padding: 0;\n    }\n}\n\n.icon {\n    margin-top: var(--p-0-5);\n}\n\n.textBlock {\n    font: var(--font-sm);\n    padding:  var(--p-1) var(--p-1-5) var(--p-1) var(--p-2);\n}\n\n.holderBtnModal {\n    background: transparent;\n    width: 100%;\n}\n\n.wrapper {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderFileLoader": `_PUzpW2I`,
	"holderFileLoaderOverlay": `EqKo_g5e`,
	"icon": `KP1vR7zk`,
	"textBlock": `SgtTuaoB`,
	"holderBtnModal": `uvod6mST`,
	"wrapper": `CQF6QXft`
};
export default ___CSS_LOADER_EXPORT___;
