import { api } from 'shared/config/rtk-query-api/api.services';
import { getQueryParam } from 'shared/lib/utils/validUrl/validUrl';

import { transformRoleList } from '../adapters/serverData';
import { IRole } from '../model/types/role.types';

interface IGetRolesArgs {
    limit?: string;
}

const roleApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query<IRole[], IGetRolesArgs>({
            providesTags: ['Roles'],
            query: ({ limit }) => {
                const query = getQueryParam({ limit });
                return { url: `/api/v1/roles/${query}` };
            },
            transformResponse: transformRoleList,
        }),
    }),
});

export const {
    useGetRolesQuery,
} = roleApi;
