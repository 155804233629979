// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JOcT6kKX{position:fixed;left:0px;top:0;bottom:0;right:0;width:100%;height:100%;background:#fff;overflow-x:hidden;z-index:4}`, "",{"version":3,"sources":["webpack://./src/shared/ui/fullscreen-container/FullscreenContainer.module.scss"],"names":[],"mappings":"AAAA,UACI,cAAA,CACA,QAAA,CACA,KAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".fullscreenContainer {\n    position: fixed;\n    left: 0px;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    width: 100%;\n    height: 100%;\n    background: #fff;\n    overflow-x: hidden;\n    z-index: 4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullscreenContainer": `JOcT6kKX`
};
export default ___CSS_LOADER_EXPORT___;
