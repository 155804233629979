import { Dispatch } from 'react';

import {
    ReducersMapObject,
    configureStore,
    Middleware,
    AnyAction,
    isRejectedWithValue,
} from '@reduxjs/toolkit';
import { channelReducer } from 'entities/channels';
import { employeesReducer } from 'entities/employee';
import { messagesReducer } from 'entities/message';
import { notificationsReducer } from 'entities/notification';
import { userReducer } from 'entities/user';
import { contentViewerReducer } from 'features/content-viewer';
import { audioFileReducer } from 'features/global-audio-channel';
import {
    api,
    apiMiddleware,
    apiReducer,
} from 'shared/config/rtk-query-api/api.services';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import { createMessageReducer } from 'widgets/create-message';
import { editMessageReducer } from 'widgets/edit-message';

import { IStateSchema } from './state.schema';

export const createRtkQueryErrorMiddleware = (errorHandler: (action: AnyAction) => void) => () => (
    next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
        errorHandler(action);
    }

    return next(action);
};

const checkIsUrlVerifiable = (url: string) => url !== `${process.env.REACT_APP_API_URL}/api/v1/users/me/` && url !== `${process.env.REACT_APP_API_URL}/api/v1/auth/login/`;

const handleRtkQueryError = (action: AnyAction) => {
    const { meta } = action;
    const { openNotification } = useNotification();

    const url = meta.baseQueryMeta?.request?.url;

    if (meta.requestStatus === 'rejected' && checkIsUrlVerifiable(url)) {
        const error = Object.values(action.payload?.data || {})?.[0] || 'Произошла непредвиденная ошибка';
        const errorText = Array.isArray(error) ? (error?.[0] as string) : error as string;
        openNotification({ description: errorText || 'Произошла непредвиденная ошибка', type: 'error' });
    }
};

const rtkQueryErrorMiddleware = createRtkQueryErrorMiddleware(handleRtkQueryError);

export function createReduxStore(initialState: IStateSchema) {
    const rootReducers: ReducersMapObject<IStateSchema> = {
        audioFile: audioFileReducer,
        channels: channelReducer,
        contentViewer: contentViewerReducer,
        createMessage: createMessageReducer,
        editMessage: editMessageReducer,
        employees: employeesReducer,
        messages: messagesReducer,
        notifications: notificationsReducer,
        user: userReducer,
        [api.reducerPath]: apiReducer,
    };

    return configureStore<IStateSchema, AnyAction, Middleware[]>({
        middleware: (getDefaultMiddleware) => (
            getDefaultMiddleware({ serializableCheck: false })
                .concat(apiMiddleware)
                .concat(rtkQueryErrorMiddleware)
        ),
        preloadedState: initialState,
        reducer: rootReducers,
    });
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
