import { CustomVideoElementRef } from 'entities/message';

type TFullscreenVariants =
'requestFullscreen' |
'mozRequestFullScreen' |
'webkitRequestFullscreen' |
'msRequestFullscreen' |
'webkitEnterFullscreen';

export const requestFullScreenAndroid = (video: CustomVideoElementRef, isAndroid: boolean) => {
    if (!isAndroid || !video) {
        return false;
    }

    const variantsFullscreen = ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen',
        'msRequestFullscreen', 'webkitEnterFullscreen'] as TFullscreenVariants[];

    const isFullScreen = variantsFullscreen.some((type) => {
        if (video[type]) {
            video[type]();
            return true;
        }
        return false;
    });

    return isFullScreen;
};
