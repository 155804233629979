import {
    FC,
    memo,
} from 'react';

import {
    Button,
} from 'antd';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import {
    WrappedIcon,
} from 'shared/ui/wrapped-icon/WrappedIcon';

interface IEditVariableProps {
    newVariableName: string;
    newVariableValue : string;
    updateVariable: () => void;
    revertChanges: () => void;
}

export const EditVariable: FC<IEditVariableProps> = memo((props) => {
    const {
        newVariableValue,
        revertChanges, updateVariable,
    } = props;

    return (
        <>
            <Button
                disabled={!newVariableValue}
                onClick={updateVariable}
                type="primary"
                size="large"
                icon={<CheckOutlined />}
            />
            <Button
                onClick={revertChanges}
                size="large"
                icon={(
                    <WrappedIcon
                        Icon={CloseOutlined}
                        theme="gray"
                    />
                )}
            />
        </>
    );
});
