import {
    FC,
    memo,
    useCallback,
} from 'react';

import 'dayjs/locale/ru';

import {
    IVariable,
    IChannel,
} from 'entities/channels';
import {
    IGroupedMessages,
    TMessage,
} from 'entities/message';
import { IArgsContentViewer } from 'features/content-viewer';

import { DateRender } from '../date/DateRender';
import { MessageRenderer } from '../item/MessageRenderer';

interface IGroupedMessageListProps {
    channel?: IChannel;
    onEditMessage: (mes: TMessage) => void;
    variables: IVariable[];
    messages: TMessage[];
    handleOpenModalOnDelete: (mesId: string, importedFrom: string | null) => void;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
    groupedArray: IGroupedMessages[];
    reloadMessages: Function;
    reloadChannels: Function;
}

export const GroupedMessageList: FC<IGroupedMessageListProps> = memo((props) => {
    const {
        channel,
        groupedArray,
        handleOpenModalOnDelete,
        messages,
        onEditMessage,
        openPreviewVideoOrPhoto,
        reloadChannels,
        reloadMessages,
        variables,
    } = props;

    const renderMessages = useCallback((message: TMessage, index: number) => {
        const lastIndex = messages.length - 1;
        const isLastPublishedMessage = channel?.lastPublishedPostId === message.id;
        const hasNotSentMessage = messages.some((item) => !item.isSent);
        const showLastPublishedMessage = isLastPublishedMessage && messages[lastIndex]?.id !== message?.id && hasNotSentMessage;

        return (
            <MessageRenderer
                key={message.id}
                channel={channel}
                index={index}
                message={message}
                messages={messages}
                onEditMessage={onEditMessage}
                openModalToRemoveMessage={handleOpenModalOnDelete}
                variables={variables}
                openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                showLastPublishedMessage={showLastPublishedMessage}
                reloadMessages={reloadMessages}
                reloadChannels={reloadChannels}
            />
        );
    }, [channel, variables, onEditMessage, messages]);

    const renderDate = (date: string, index: number) => (
        <DateRender
            date={date}
            index={index}
        />
    );

    const renderGroupedArray = ({ items, publishAt }: IGroupedMessages, index: number) => (
        <div key={publishAt}>
            {renderDate(publishAt, index)}
            {items.map((message, indexMessage) => renderMessages(message, indexMessage))}
        </div>
    );

    if (!groupedArray || groupedArray.length === 0) {
        return null;
    }

    return (
        <>
            {groupedArray.map(renderGroupedArray)}
        </>
    );
});
