import {
    Dispatch,
    FC,
    MouseEvent,
    SetStateAction,
    memo,
} from 'react';

import {
    Button,
} from 'antd';

import SendOutlined from '@ant-design/icons/SendOutlined';
import { Text } from 'shared/ui/text/Text';

import cls from './ResendButton.module.scss';

interface IResendButtonProps {
    handleCancelPopover: () => void;
    setIsResend: Dispatch<SetStateAction<boolean>>;
}

export const ResendButton: FC<IResendButtonProps> = memo((props) => {
    const {
        handleCancelPopover, setIsResend,
    } = props;

    const handleCheckPostPublishStatus = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsResend(true);
        handleCancelPopover();
    };

    return (
        <Button type="text" className={cls.button} onClick={handleCheckPostPublishStatus}>
            <Text text="Повторить отправку" theme="black" />
            <SendOutlined className={cls.icon} />
        </Button>

    );
});
