import { useSelector } from 'react-redux';

import { ColumnsType } from 'antd/es/table';
import { IEmployee } from 'entities/employee';
import { IRole } from 'entities/role';
import { getIsRoleAssignment } from 'entities/user';

import { EmployeeDelete } from '../ui/items/delete/EmployeeDelete';
import { EmployeeName } from '../ui/items/name/EmployeeName';
import { EmployeeRole } from '../ui/items/role/EmployeeRole';

export const employeeDisplayColumns = (): ColumnsType<IEmployee> => {
    const isRoleAssignment = useSelector(getIsRoleAssignment);

    const deleteColumnArray = [{
        dataIndex: 'delete',
        key: 'delete',
        render: (_: string, employee: IEmployee) => (
            <EmployeeDelete employee={employee} />
        ),
        title: '',
        width: 30,
    }];

    return [
        {
            dataIndex: 'firstName',
            key: 'firstName',
            render: (name: string, employee: IEmployee) => <EmployeeName name={name} src={employee.photo} />,
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            title: 'Имя',

        },
        {
            dataIndex: 'username',
            ellipsis: true,
            key: 'username',
            render: (name: string) => (
                <a style={{ color: 'rgba(0, 0, 0, 0.88)' }} href={`https://t.me/${name}`} rel="noreferrer">
                    {name}
                </a>
            ),
            title: 'Никнейм',
        },
        {
            dataIndex: 'role',
            key: 'role',
            render: (role: IRole, employee: IEmployee) => <EmployeeRole role={role} employee={employee} />,
            sorter: (a, b) => ((a?.role && b?.role) ? a.role?.name?.localeCompare(b?.role?.name) : 1),
            title: 'Роль',
        },
        {
            dataIndex: 'channelCount',
            ellipsis: true,
            key: 'channelCount',
            title: 'Кол-во каналов',
        },
        ...(isRoleAssignment ? deleteColumnArray : []),
    ];
};
