import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
    Button,
} from 'antd';
import dayjs from 'dayjs';

import { IChannel } from 'entities/channels';
import {
    EPostTypeMessage,
    IPostPollAnswers,
    messagesActions,
} from 'entities/message';
import {
    serialize,
    slateNodesToString,
} from 'features/rich-text-editor';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useNotification } from 'shared/lib/hooks/useNotification/useNotification';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { getValidFilesForSave } from 'shared/lib/utils/file/file';
import { getUtcOffset } from 'shared/lib/utils/timezones/timezones';
import {
    MaxLengthSymbolsErrorModal,
    useLoadFileData,
} from 'widgets/channel-editor';
import {
    DateValidVariant,
    validateDate,
} from 'widgets/edit-message';

import {
    getCreateMessageAnswersPoll,
    getCreateMessageAudio,
    getCreateMessageButtons,
    getCreateMessageDate,
    getCreateMessageDateValidType,
    getCreateMessageDocuments,
    getCreateMessageIsNotifyPin,
    getCreateMessageIsPinIndefinitely,
    getCreateMessageIsWebPreview,
    getCreateMessageMaxSymbolsLength,
    getCreateMessageMedia,
    getCreateMessageMultipleChoicePoll,
    getCreateMessagePinDuration,
    getCreateMessageQuestionPoll,
    getCreateMessageQuizPoll,
    getCreateMessageRoundVideo,
    getCreateMessageSolutionIndexPoll,
    getCreateMessageSolutionMessage,
    getCreateMessageText,
    getCreateMessageTime,
    getCreateMessageTimezone,
    getCreateMessageVariables,
    getCreateMessageVoice,
} from '../../model/selectors/createMessage.selectors';
import { useCreateMessageMutation } from '../../model/services/createMessage.service';
import { createMessageActions } from '../../model/slice/createMessageSlice';

interface ISaveMessageProps {
    cancelModal: () => void;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    channel: IChannel;
}

export const SaveMessage: FC<ISaveMessageProps> = (props) => {
    const {
        cancelModal,
        channel,
        handleReloadChannels,
        setIsUpdateChannels,
    } = props;
    const { isTablet } = useScreenType();

    const dispatch = useAppDispatch();
    const { openNotification } = useNotification();

    const [isErrorOpenModal, setIsErrorOpenModal] = useState(false);
    const [errorMaxLength, setErrorMaxLength] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);

    const loadFileData = useLoadFileData();
    const [createMessage, { data: newMessage, isSuccess }] = useCreateMessageMutation();

    const text = useSelector(getCreateMessageText);
    const media = useSelector(getCreateMessageMedia);
    const docs = useSelector(getCreateMessageDocuments);
    const timeMinutes = useSelector(getCreateMessageTime);
    const date = useSelector(getCreateMessageDate);
    const timezone = useSelector(getCreateMessageTimezone);
    const buttons = useSelector(getCreateMessageButtons);
    const maxSymbolsLength = useSelector(getCreateMessageMaxSymbolsLength);
    const isNotifyPin = useSelector(getCreateMessageIsNotifyPin);
    const pinDuration = useSelector(getCreateMessagePinDuration);
    const isPinIndefinitely = useSelector(getCreateMessageIsPinIndefinitely);
    const variables = useSelector(getCreateMessageVariables);
    const isWebPreview = useSelector(getCreateMessageIsWebPreview);
    const roundVideo = useSelector(getCreateMessageRoundVideo);
    const voice = useSelector(getCreateMessageVoice);
    const audio = useSelector(getCreateMessageAudio);
    const validDateType = useSelector(getCreateMessageDateValidType);
    const answersPoll = useSelector(getCreateMessageAnswersPoll);
    const multipleChoicePoll = useSelector(getCreateMessageMultipleChoicePoll);
    const questionPoll = useSelector(getCreateMessageQuestionPoll);
    const quizPoll = useSelector(getCreateMessageQuizPoll);
    const solutionIndexPoll = useSelector(getCreateMessageSolutionIndexPoll);
    const solutionMessage = useSelector(getCreateMessageSolutionMessage);

    const isTextareaEmpty = text ? !slateNodesToString(text).trim().length : false;
    const isMedia = media && media?.length > 0;
    const isDocs = docs && docs?.length > 0;
    const isPoll = useMemo(() => answersPoll.length > 0 || questionPoll, [answersPoll, questionPoll]);

    const isDisabled = useMemo(
        () => {
            if (!date || !timeMinutes || validDateType) {
                return true;
            }

            if (roundVideo || voice || audio) {
                return false;
            }
            const pollCheck = isPoll
            && (questionPoll
             && answersPoll.length > 1
             && answersPoll.every((item: IPostPollAnswers) => item.value.trim())
             // проверка опроса, что у нас кол-во ответов не менее 2
             && (!quizPoll || typeof solutionIndexPoll === 'number')); // если режим викторины, то обязательно должен быть ответ

            if (pollCheck) {
                return false;
            }

            if (isTextareaEmpty && isWebPreview && isMedia) {
                return true;
            }

            if (isTextareaEmpty && !isMedia && !isDocs) {
                return true;
            }

            return false;
        },
        [
            date,
            timeMinutes,
            roundVideo,
            voice,
            isTextareaEmpty,
            isMedia,
            isDocs,
            audio,
            validDateType,
            isPoll,
            questionPoll,
            answersPoll,
            quizPoll,
            solutionIndexPoll,
            isWebPreview,
        ],
    );

    const clearData = useCallback(() => {
        dispatch(createMessageActions.clearData());
    }, []);

    const getTypeMessage = useCallback(() => {
        if (isPoll) {
            return EPostTypeMessage.POLL;
        }

        if (roundVideo) {
            return EPostTypeMessage.ROUND_VIDEO;
        }

        if (voice) {
            return EPostTypeMessage.VOICE_MESSAGE;
        }

        if (audio) {
            return EPostTypeMessage.AUDIO_MESSAGE;
        }

        if ((media && media.length > 0) || (docs && docs.length > 0)) {
            return EPostTypeMessage.ALBUM;
        }

        if (text) {
            return EPostTypeMessage.TEXT;
        }

        return null;
    }, [roundVideo, voice, text, media, docs, audio, isPoll]);

    const getFileData = async (typePost: EPostTypeMessage) => {
        if (docs !== null && docs.length > 0) {
            return loadFileData(docs, true);
        }

        if (media !== null && media.length > 0) {
            return loadFileData(media);
        }

        if (typePost === EPostTypeMessage.ROUND_VIDEO && roundVideo) {
            return loadFileData([roundVideo]);
        }

        if (typePost === EPostTypeMessage.VOICE_MESSAGE && voice) {
            return loadFileData([voice]);
        }

        if (typePost === EPostTypeMessage.AUDIO_MESSAGE && audio) {
            return loadFileData([audio]);
        }

        return [];
    };

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        const typePost = getTypeMessage();

        if (!timeMinutes || !date || !typePost) {
            return;
        }

        let htmlConvertedString: string | undefined;

        if (text !== null && !isTextareaEmpty) {
            const textLength = slateNodesToString(text, variables)?.length;
            if (textLength > maxSymbolsLength) {
                setIsErrorOpenModal(true);
                setErrorMaxLength(textLength - maxSymbolsLength);
                setIsLoading(false);
                return;
            }

            htmlConvertedString = serialize(text).trim();
        }

        const loadedData = await getFileData(typePost);

        const textData = text
        && typePost !== EPostTypeMessage.ROUND_VIDEO
        && typePost !== EPostTypeMessage.VOICE_MESSAGE // TODO: add text to voice
        && typePost !== EPostTypeMessage.AUDIO_MESSAGE
        && typePost !== EPostTypeMessage.POLL
            ? { text: htmlConvertedString ?? '' }
            : {};

        const isHideButtons = (media && media.length > 1) || (docs && docs.length > 1);

        const buttonsData = buttons && !isHideButtons
            ? { buttons: buttons?.map((btn) => ({ name: btn.name, value: btn.value })) }
            : { buttons: [] };

        const fileDate = getValidFilesForSave(loadedData, typePost);

        const pollData = isPoll ? {
            poll: {
                answers: answersPoll,
                multipleChoice: multipleChoicePoll,
                question: questionPoll,
                quiz: quizPoll,
                solutionIndex: solutionIndexPoll,
                solutionMessage,
            },
        } : {};

        const post = {
            ...fileDate,
            ...textData,
            ...pollData,
        };

        const readyMinutes = getUtcOffset(timezone) === 0 ? timeMinutes.utcOffset(0) : timeMinutes;
        const time = readyMinutes.format('HH:mm:ss');
        const dateFinished = date.format('YYYY-MM-DD');

        const formattedDateByUTC = dayjs(`${dateFinished}T${time}Z`)
            .utc()
            .utcOffset(-dayjs().utc().tz(timezone).utcOffset())
            .format('YYYY-MM-DDTHH:mm:ss');

        setIsUpdateChannels(true);

        await createMessage({
            channel: channel.id,
            notifyOnPin: isNotifyPin,
            pinDuration,
            pinIndefinitely: isPinIndefinitely,
            postType: typePost,
            publishAt: formattedDateByUTC,
            webPreviewLimitsBypassEnabled: isWebPreview,
            ...buttonsData,
            ...(Object.keys(post).length > 0 ? { post } : {}),
        });
        clearData();
        handleReloadChannels();
        dispatch(messagesActions.setIsMessageListUpdate(true));

        setIsLoading(false);
    }, [
        docs,
        media,
        text,
        timeMinutes,
        date,
        buttons,
        maxSymbolsLength,
        isNotifyPin,
        pinDuration,
        isPinIndefinitely,
        variables,
        isWebPreview,
        timezone,
        getTypeMessage,
        isTextareaEmpty,
        answersPoll,
        multipleChoicePoll,
        questionPoll,
        quizPoll,
        solutionIndexPoll,
        isPoll,
        solutionMessage,
    ]);

    const onShowDateError = useCallback((value: DateValidVariant) => {
        dispatch(createMessageActions.setValidDateType(value));
    }, []);

    const saveOrCheckDate = useCallback(() => {
        if (timeMinutes && date) {
            const readyMinutes = getUtcOffset(timezone) === 0 ? timeMinutes.utcOffset(0) : timeMinutes;
            const error = validateDate(date, readyMinutes, timezone, onShowDateError);

            if (error) {
                return;
            }
        }

        handleSave();
    }, [handleSave, timeMinutes, date, timezone, onShowDateError]);

    useEffect(() => {
        if (isSuccess && newMessage) {
            dispatch(
                messagesActions.addMessage(newMessage),
            );
            cancelModal();

            openNotification({ description: 'Сообщение создано!' });
        }
    }, [isSuccess]);

    return (
        <>
            <Button
                disabled={isDisabled}
                loading={isLoading}
                key="submit"
                type="primary"
                onClick={saveOrCheckDate}
                block={!!isTablet}
                size={isTablet ? 'large' : 'middle'}
            >
                Сохранить
            </Button>
            <MaxLengthSymbolsErrorModal
                key="modal-max-length-error"
                isErrorOpenModal={isErrorOpenModal}
                errorMaxLength={errorMaxLength}
                setIsErrorOpenModal={setIsErrorOpenModal}
            />
        </>
    );
};
