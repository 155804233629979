// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UdXuzeJ4{color:var(--gray-4)}.Vse3HGmE{outline:none;border:0;background:none;padding:var(--p-1-5);width:100%;border-bottom:1px solid var(--black-percent-06)}.syryFsnC{display:flex;align-items:center;justify-content:space-between;width:100%;height:20px;column-gap:var(--p-2)}.InIDK4q9>svg{width:20px;height:20px}`, "",{"version":3,"sources":["webpack://./src/features/import-messages/ui/ImportMessages.module.scss"],"names":[],"mappings":"AAAA,UACI,mBAAA,CAGJ,UACI,YAAA,CACA,QAAA,CACA,eAAA,CACA,oBAAA,CACA,UAAA,CACA,+CAAA,CAGJ,UACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA,CAIA,cACI,UAAA,CACA,WAAA","sourcesContent":[".importMessagesTypeText {\n    color: var(--gray-4);\n}\n\n.holderButtonMobile {\n    outline: none;\n    border: 0;\n    background: none;\n    padding: var(--p-1-5);\n    width: 100%;\n    border-bottom: 1px solid var(--black-percent-06);\n}\n\n.contentMobile {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    height: 20px;\n    column-gap: var(--p-2);\n}\n\n.tabletIcon {\n    & > svg {\n        width: 20px;\n        height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importMessagesTypeText": `UdXuzeJ4`,
	"holderButtonMobile": `Vse3HGmE`,
	"contentMobile": `syryFsnC`,
	"tabletIcon": `InIDK4q9`
};
export default ___CSS_LOADER_EXPORT___;
